import { Suspense } from 'react';

import { GoogleMapApiLoader } from 'react-google-map-wrapper';
import styled from 'styled-components';

import RideMap from './RideMap';
import { GlobalStyle } from './parts/GlobalStyle';
import { LiveRideProvider } from './parts/LiveRideProvider';
import { LiveRideContextType } from './types';

const LiveRideContainer = styled.div`
  width: 100%;
  height: 100%;
  > * {
    box-sizing: border-box;
    font-family: 'Apple SD Gothic Neo';
  }
`;

export function LiveRide(props: Pick<LiveRideContextType, 'googleApiKey' | 'branchKey' | 'images' | 'serverHost' | 'urlId'>) {
  return (
    <>
      <GlobalStyle />
      <LiveRideContainer>
        <Suspense>
          <LiveRideProvider
            value={{
              ...props,
              googleMap: null,
              rideInfo: null,
              rideRouting: null,
              isNARide: false,
            }}
          >
            <GoogleMapApiLoader apiKey={props.googleApiKey} v='quarterly' libraries={['geometry']} suspense>
              <RideMap />
            </GoogleMapApiLoader>
          </LiveRideProvider>
        </Suspense>
      </LiveRideContainer>
    </>
  );
}
