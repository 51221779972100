import React from 'react'
import { withTranslation } from 'react-i18next'
import DeleteAccountRow from './DeleteAccountRow'
import DeleteAccountInput from './DeleteAccountInput'
import DeleteAccountButton from './DeleteAccountButton'
import DeleteAccountDropdown from './DeleteAccountDropdown'
import DeleteAccountRequestContainer from './DeleteAccountContainer'
import DeleteAccountCol from './DeleteAccountCol'
import { useCountryCode, useNumberForm, useOTPForm } from '../hooks'

function DeleteAccountRequestOTP({ t }) {
  const countryCodes = useCountryCode()

  const { code, number, isValidNumber, handleChangeCode, handleChangeNumber, handleClickSendButton } = useNumberForm()

  const { otp, second, continueButtonDisabled, isValidOTP, handleChangeOTP, handleClickContinue } = useOTPForm()

  return (
    <DeleteAccountRequestContainer title={t('title_otp_request')}>
      <DeleteAccountRow label={t('label_mobile_number')}>
        <DeleteAccountCol width="104px">
          <DeleteAccountDropdown
            value={code}
            onChange={handleChangeCode}
            options={
              countryCodes?.map(country => ({
                text: `+${country.number}`,
                value: `+${country.number}`
              })) ?? []
            }
          />
        </DeleteAccountCol>
        <DeleteAccountCol grow={1}>
          <DeleteAccountInput
            value={number}
            onChange={handleChangeNumber}
            placeholder={t('label_mobile_number')}
            type="tel"
          />
        </DeleteAccountCol>
      </DeleteAccountRow>
      <DeleteAccountRow label={t('label_code_number')}>
        <DeleteAccountCol grow={1} error={isValidOTP === false && t('error_verification_code')}>
          <DeleteAccountInput value={otp} onChange={handleChangeOTP} placeholder="6-Digit Number" />
        </DeleteAccountCol>
        <DeleteAccountCol width="104px">
          <DeleteAccountButton disabled={!isValidNumber || second} onClick={handleClickSendButton}>
            {second ? second : t('btn_send')}
          </DeleteAccountButton>
        </DeleteAccountCol>
      </DeleteAccountRow>
      <DeleteAccountRow>
        <DeleteAccountButton disabled={continueButtonDisabled} onClick={handleClickContinue}>
          {t('btn_continue')}
        </DeleteAccountButton>
      </DeleteAccountRow>
    </DeleteAccountRequestContainer>
  )
}

export default withTranslation()(DeleteAccountRequestOTP)
