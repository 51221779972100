import styled from 'styled-components';

export const Loading = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  &:after {
    content: '';
    display: flex;
    width: 48px;
    height: 48px;
    border: 5px solid #747678;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
    margin: auto;

    @keyframes rotation {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
`;
