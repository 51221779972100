import { GoogleMap, useImportLibrary } from 'react-google-map-wrapper';

import { MAP_STYLE } from './constant/constants';
import { Region } from './constant/types';
import { DriverMarkerGlobalStyle } from './parts/GlobalStyle';
import { useLiveRideContext } from './parts/LiveRideProvider';
import MapContent from './parts/MapContent';
import { getDefaultCenterOfRegion, transformToLatLngLiteral } from './utils';

function Map() {
  const { rideInfo, rideRouting, googleMap } = useLiveRideContext();
  const defaultCenter = getDefaultCenterOfRegion(rideInfo!.region as Region);
  const geometry = useImportLibrary('geometry');

  const rideRouteValue: google.maps.LatLngLiteral[] =
    rideRouting?.encodedPolyline && geometry
      ? geometry.encoding.decodePath(rideRouting.encodedPolyline).map((poly) => ({ lat: poly.lat(), lng: poly.lng() }))
      : [];

  const pickUpFromRoadValue =
    rideRouting?.distancesFromRoad && rideRouting.distancesFromRoad?.drawDottedLineAtPickUp
      ? [transformToLatLngLiteral(rideRouting.distancesFromRoad.pickUp), transformToLatLngLiteral(rideRouting.distancesFromRoad.routeStartPoint)]
      : [];

  const destinationFromRoadValue =
    rideRouting?.distancesFromRoad && rideRouting.distancesFromRoad?.drawDottedLineAtDestination
      ? [transformToLatLngLiteral(rideRouting.distancesFromRoad.destination), transformToLatLngLiteral(rideRouting.distancesFromRoad.routeEndPoint)]
      : [];

  return (
    <>
      <DriverMarkerGlobalStyle />
      <GoogleMap
        style={{ width: '100%', height: '100dvh' }}
        initialZoom={12}
        initialCenter={defaultCenter}
        mapOptions={{
          clickableIcons: false,
          fullscreenControl: false,
          disableDefaultUI: true,
          styles: MAP_STYLE,
        }}
        ref={googleMap}
      >
        <MapContent rideRouteValue={rideRouteValue} pickUpFromRoadValue={pickUpFromRoadValue} destinationFromRoadValue={destinationFromRoadValue} />
      </GoogleMap>
    </>
  );
}

export default Map;
