import { useState } from 'react';

import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { useTadaRiderAppLink } from './hooks/branch';
import { useLiveRideContext } from './parts/LiveRideProvider';

const AppLinkContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 57px;
  padding: 10px;
  background-color: #fff;
  box-sizing: border-box;
  font-family: 'Apple SD Gothic Neo';
  z-index: 100;

  > .cancel {
    width: 18px;
    height: 18px;
    margin: auto 0;
    cursor: pointer;
  }

  > .appLogo {
    width: 35px;
    height: 35px;
    margin: auto 10px;
  }

  > div {
    width: auto;
    height: 100%;
    font-size: 14px;
    font-weight: 700;

    > .star-wrap {
      > img {
        width: 10px;
        height: 10px;
      }

      > img + img {
        margin-left: 3px;
      }
    }
  }

  > button {
    display: flex;
    width: auto;
    height: auto;
    max-height: 32px;
    margin: auto 0 auto auto;
    padding: 10px;
    justify-content: center;
    align-items: center;
    background-color: #000;
    color: #fff;
    border: 0;
    border-radius: 4px;
  }
`;

function AppLink() {
  const { t } = useTranslation();
  const { branchKey, images } = useLiveRideContext();
  const { openLink } = useTadaRiderAppLink(branchKey);

  const [isOpen, setIsOpen] = useState(true);

  if (!isOpen) {
    return null;
  }

  return (
    <AppLinkContainer>
      <img src={images.cross} className='cancel' alt='cancel' onClick={() => setIsOpen(false)} />
      <img src={images.appLogo} className='appLogo' alt='tadaAppLogo' />
      <div>
        <div>{t('text_download_tada_ride')}</div>
      </div>
      <button onClick={openLink}>{t('label_install')}</button>
    </AppLinkContainer>
  );
}

export default AppLink;
