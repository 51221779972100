import { Polyline } from 'react-google-map-wrapper';

interface RoutePolyLineProps {
  value: google.maps.LatLngLiteral[];
}

function RoutePolyLine({ value }: RoutePolyLineProps) {
  return (
    <>
      {/* border line */}
      <Polyline path={value} strokeColor='#CB7D00' strokeOpacity={1} strokeWeight={5} />
      {/* line */}
      <Polyline path={value} strokeColor='#FFC400' strokeOpacity={1} strokeWeight={3} />
    </>
  );
}

export default RoutePolyLine;
