import { Component } from 'react';

import styled from 'styled-components';

import appIcon from '@/assets/images/tada/app-icon-driver@2x.png';
import car from '@/assets/images/tada/car@2x.png';
import { AppBannerContainer, AppIcon, AppTitle } from '@/components/AppBanner/index';

const DriverAppBannerContainer = styled(AppBannerContainer)`
  &:after,
  &::after {
    position: absolute;
    background-image: url(${car});
    background-size: contain;
    background-repeat: no-repeat;
    top: 13px;
    right: 0;
    width: 76px;
    height: 82px;
    content: ' ';
  }

  @media (max-width: 1000px) {
    height: 250px;

    &:after,
    &::after {
      left: calc(50% + 100px);
      bottom: calc(50% + 11px);
      // left: 100px;
      // bottom: 61px;
      top: auto;
      height: 60px;
    }
  }
`;

export default class DriverAppBanner extends Component {
  render() {
    return (
      <DriverAppBannerContainer>
        <AppIcon src={appIcon} />
        <AppTitle>
          TADA for
          <br />
          <span>DRIVER</span>
        </AppTitle>
      </DriverAppBannerContainer>
    );
  }
}
