import React, { Component } from 'react'

import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

import styled from 'styled-components'

import triangle from '../../../assets/images/tada/triangle.png'
import FaqTable from './FaqTable'
import qs from 'qs'
import { getLocaleContent } from './hooks'
import Spinner from 'react-spinkit'
import { mobileCheck } from '../../../utils/MyUtils'
import { getLocale } from '../../../selectors'
import FaqSearch from './parts/FaqSearch'
import { decodeDescription } from '../../../utils/MyUtils'

const DescriptionContainer = styled.div`
  white-space: pre-line;
  word-break: break-word;
`

const DropDown = styled.div`
  position: relative;
  display: inline-block;
  font-size: 14px;
  cursor: pointer;
  text-decoration: none;
  overflow: visible;
  @media (max-width: 1000px) {
    width: 111%;
  }
`

const DropDownImgWrapper = styled.div`
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 16px;
  background-color: #fff;
  min-width: 553px;
  width: max-content;
  height: 45px;
  box-sizing: border-box;
  border: solid 1px #979797;
  position: relative;
  cursor: pointer;
  color: #000;
  padding-left: 16px;
  padding-right: 50px;
  overflow: visible;
  &:after,
  &::after {
    position: absolute;
    width: 18px;
    height: 10px;
    top: 50%;
    margin-top: -5px;
    right: 20px;
    content: ' ';
    background-image: url(${triangle});
    background-repeat: no-repeat;
    background-position: ${props => (props.isDriverAppShow ? '-19px' : '0')} 0;
  }
  @media (max-width: 1000px) {
    width: 90%;
    height: 45px;
    min-height: 45px;
    line-height: 26px;
    min-width: unset;
    padding: 12px 50px 12px 16px;
    font-size: 16px;
    font-weight: 400;
  }
  @media (max-width: 330px) {
    font-size: 16px;
  }
`

const DropDownContent = styled.div`
  max-height: ${props => (props.isMobile ? `calc(100vh - 410px)` : `800px`)};
  min-height: 200px;
  position: absolute;
  background-color: #ffffff;
  box-sizing: border-box;
  z-index: 1;
  width: 100%;
  overflow: scroll;
  @media (max-width: 1000px) {
    width: 90%;
  }
`

const DropDownContentItem = styled.button`
  display: block;
  border: none;
  outline: 0;
  padding-left: 16px;
  box-sizing: border-box;
  cursor: pointer;
  width: 100%;
  min-height: 45px;
  font-size: 16px;
  font-weight: 400;
  color: #000;
  background-color: #fff;
  border: solid 1px #979797;
  border-top: none;
  text-align: left;
  overflow: visible;
  &:hover {
    background-color: #eaba28;
    color: #fff;
  }
  @media (max-width: 1000px) {
    font-size: 16px;
  }
  @media (max-width: 330px) {
    font-size: 16px;
  }
`

export const SubCategoryWrapper = styled.div`
  margin-top: 15px;

  > div + div {
    margin-top: 10px;
  }

  > div > ul {
    margin-top: 10px;

    > li {
      margin: 6px 0;
    }
  }
`

class TadaDriverTabs extends Component {
  constructor(props) {
    super(props)

    this.state = {
      selectedTopicIdx: null,
      isDropdownShow: false
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props && !this.props.isTopicFetching) {
      this.setTopicOption();
    }
  }

  componentDidMount() {
    this.setTopicOption();
  }

  setTopicOption = () => {
    const search = qs.parse(this.props.location.search, {
      ignoreQueryPrefix: true
    })

    const index = search.topicId ? this.props.topicList.findIndex(topic => topic.id === +search.topicId) : 0

    this.setState({
      selectedTopicIdx: index === -1 ? 0 : index
    })
  }

  handleDriverChange(index) {
    const search = qs.parse(this.props.location.search, { ignoreQueryPrefix: true })
    this.props.history.replace({ search: qs.stringify({ ...search, topicId: this.props.topicList[index].id }) })
    this.setState({ selectedTopicIdx: index })
  }

  render() {
    const { topicList, isTopicFetching, selectedLocale } = this.props
    const { isDropdownShow, selectedTopicIdx } = this.state
    const search = qs.parse(location.search, { ignoreQueryPrefix: true })

    return (
      <div>
        <FaqSearch />
        {!search.keyword && (
          <DropDown onClick={() => this.setState({ isDropdownShow: !isDropdownShow })}>
            <DropDownImgWrapper isTopicFetching={isTopicFetching} isDriverAppShow={isDropdownShow}>
              {isTopicFetching && <Spinner className="topic__loading_bar" name="circle" color="black" fadeIn="none" />}
              {getLocaleContent(topicList[selectedTopicIdx], selectedLocale).topicName}
            </DropDownImgWrapper>
            {isDropdownShow && (
              <DropDownContent isMobile={mobileCheck()}>
                {topicList.map((topic, index) => {
                  return (
                    <DropDownContentItem key={topic.id} onClick={() => this.handleDriverChange(index)}>
                      {getLocaleContent(topic, selectedLocale).topicName}
                    </DropDownContentItem>
                  )
                })}
              </DropDownContent>
            )}
          </DropDown>
        )}
        {this.props.title.includes(this.props.match.params.section) &&
          topicList[selectedTopicIdx] &&
          Array.isArray(topicList[selectedTopicIdx].contents) &&
          topicList[selectedTopicIdx].contents[0].description && (
            <SubCategoryWrapper>
              {topicList[selectedTopicIdx].contents[0].dataType === 'HTML' ? (
                <div
                  dangerouslySetInnerHTML={{
                    __html: decodeDescription(getLocaleContent(topicList[selectedTopicIdx], selectedLocale).description)
                  }}
                />
              ) : (
                <DescriptionContainer>
                  {getLocaleContent(topicList[selectedTopicIdx], selectedLocale).description}
                </DescriptionContainer>
              )}
            </SubCategoryWrapper>
          )}
        <FaqTable
          topicData={topicList[selectedTopicIdx]}
          tabSection={this.props.tabSection}
          currentSection={this.props.currentSection === 0 ? 'driver' : 'rider'}
        />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  selectedLocale: getLocale(state)
})

export default withRouter(connect(mapStateToProps)(TadaDriverTabs))
