import styled from 'styled-components';

import { RideStatusType } from './constant/types';
import { useRideStatusMessage } from './hooks';
import { useLiveRideContext } from './parts/LiveRideProvider';
import { getAdditionalEstimatedTime } from './utils';

interface RideStatusProps {
  rideStatus: RideStatusType;
  codStatus: number;
  sheetHeight: number;
}

const RideStatusContainer = styled.div<{ $height?: number }>`
  position: fixed;
  ${(props) => `bottom: ${props.$height}px;`}
  left: 0;
  width: 100%;
  background-color: transparent;
  z-index: 101;

  > p {
    display: flex;
    width: 90%;
    min-height: 40px;
    margin: 0 auto 10px auto;
    padding: 3px 12px;
    align-items: center;
    border-radius: 8px;
    background-color: #000;
    color: #fff;
    font-weight: 300;
    line-height: 16px;
    box-shadow: 0px 4px 16px 0px #00000024;
  }
`;

function RideStatus({ rideStatus, codStatus, sheetHeight }: RideStatusProps) {
  const { rideInfo, rideRouting, isNARide } = useLiveRideContext();
  const additionalEstimatedTime = rideRouting?.durationSeconds ? getAdditionalEstimatedTime(Math.ceil(rideRouting.durationSeconds / 60)) : 0;
  const message = useRideStatusMessage(rideStatus, rideInfo!, isNARide ? null : rideRouting, codStatus, additionalEstimatedTime);

  if (!rideStatus || !message) {
    return null;
  }

  return (
    <RideStatusContainer $height={sheetHeight}>
      <p>{message}</p>
    </RideStatusContainer>
  );
}

export default RideStatus;
