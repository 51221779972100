import React from 'react'
import { useHistory } from 'react-router'
import styled from 'styled-components'
import { MOBILE_NAVIGATOR_HEIGHT } from '../../constants/constants'
import Spinner from 'react-spinkit'
import Init from '../Init'

const Wrapper = styled.div`
  height: 100%;

  > .mobile__loading {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 100%;

    visibility: ${({ isLoading }) => (isLoading ? 'visible' : 'hidden')};

    background: rgba(100, 100, 100, 0.2);

    > .mobile__loading_indicator {
      width: 40px;
      height: 40px;
    }
  }

  > .mobile__navigator {
    display: flex;
    align-items: center;
    padding: 0 23px;

    height: ${MOBILE_NAVIGATOR_HEIGHT}px;
  }

  > .mobile__header {
    padding: 0 24px;
    height: ${({ headerSize }) => headerSize}px;
    > .mobile__header_title {
      font-size: 20px;
      margin: 0;
    }
  }
`

function MobileWebViewContainer({
  title,
  isNavigator = true,
  headerSize = 53,
  isLoading = false,
  onBack,
  isHiddenHeader = false,
  children
}) {
  const history = useHistory()

  const handleClickBack = () => {
    if (onBack) {
      onBack()
    } else {
      history.goBack()
    }
  }

  return (
    <Init height='100%'>
      <Wrapper headerSize={headerSize} isLoading={isLoading}>
        <div className='mobile__loading'>
          <Spinner className='mobile__loading_indicator' name='circle' fadeIn='none' />
        </div>
        {isNavigator && (
          <div className='mobile__navigator'>
            <svg
              width='24'
              height='24'
              viewBox='0 0 24 24'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
              onClick={handleClickBack}
            >
              <path d='M10.0896 19.1804L2.9996 12.0904L10.0896 5.00042' stroke='black' stroke-width='1.25' />
              <path d='M3 12.0908H22' stroke='black' stroke-width='1.25' />
            </svg>
          </div>
        )}
        {!isHiddenHeader && (
          <div className="mobile__header">
            <h1 className="mobile__header_title">{title}</h1>
          </div>
        )}
        {children}
      </Wrapper>
    </Init>
  )
}

export default MobileWebViewContainer
