import { useRef, useState } from 'react';

export const useLinearInterpolator = ({ initialCurrent, duration = 500 }: { initialCurrent: google.maps.LatLngLiteral; duration?: number }) => {
  const animationId = useRef<number | null>(null);
  const [position, setPosition] = useState(initialCurrent);

  const moveTo = (tg: google.maps.LatLngLiteral) => {
    cancelAnimationFrame(animationId.current!);
    setPosition(tg);
  };

  const animateTo = (tg: google.maps.LatLngLiteral, duration: number = 500) => {
    cancelAnimationFrame(animationId.current!);
    let startTime: number | null = null;

    const loop = (time: number) => {
      if (!startTime) {
        startTime = time;
      }

      const elapsed = time - startTime; // Animation 경과시간
      const progress = Math.min(1, elapsed / duration); // (Animation 경과시간 / Animation 전체 지속시간)를 0 ~ 1사이의 숫자로 진행률 선언

      if ((position?.lat === tg?.lat && position?.lng === tg?.lng) || !(position?.lat && position?.lng && tg?.lat && tg?.lng)) {
        return;
      }

      // 애니메이션이 진행됨에 따라 위도가 초기 위치에서 대상 위치까지 얼마나 변해야 하는지 결정
      // 진행률(progress)을 곱하면 animation이 점점 적용되어 시간이 지남에 따라 초기 위치에서 목표 위치로 전환
      const interpolatedPosition = {
        lat: position.lat + progress * (tg.lat - position.lat),
        lng: position.lng + progress * (tg.lng - position.lng),
      };

      setPosition(interpolatedPosition);

      if (progress < 1) {
        animationId.current = requestAnimationFrame(loop);
      }
    };

    animationId.current = requestAnimationFrame(loop);
  };

  return { position, animateTo, moveTo };
};
