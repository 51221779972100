import CODE_OF_CONDUCT_EN_SG from '@/assets/policies/code_of_conduct_en_sg.md';
import COMMUNITY_GUIDELINE from '@/assets/policies/community_guideline.md';
import COMMUNITY_GUIDELINE_EN_HK from '@/assets/policies/community_guideline_en_hk.md';
import COMMUNITY_GUIDELINE_EN_KH from '@/assets/policies/community_guideline_en_kh.md';
import COMMUNITY_GUIDELINE_EN_TH from '@/assets/policies/community_guideline_en_th.md';
import COMMUNITY_GUIDELINE_KH_KH from '@/assets/policies/community_guideline_kh_kh.md';
import COMMUNITY_GUIDELINE_TH_TH from '@/assets/policies/community_guideline_th_th.md';
import COMMUNITY_GUIDELINE_ZH_TW_HK from '@/assets/policies/community_guideline_zh-TW_hk.md';
import DRIVERS_CODE_OF_CONDUCT_EN_HK from '@/assets/policies/drivers_code_of_conduct_en_hk.md';
import DRIVERS_CODE_OF_CONDUCT_EN_KH from '@/assets/policies/drivers_code_of_conduct_en_kh.md';
import DRIVERS_CODE_OF_CONDUCT_EN_SG from '@/assets/policies/drivers_code_of_conduct_en_sg.md';
import DRIVERS_CODE_OF_CONDUCT_EN_TH from '@/assets/policies/drivers_code_of_conduct_en_th.md';
import DRIVERS_CODE_OF_CONDUCT_KH_KH from '@/assets/policies/drivers_code_of_conduct_kh_kh.md';
import DRIVERS_CODE_OF_CONDUCT_TH_TH from '@/assets/policies/drivers_code_of_conduct_th_th.md';
import DRIVERS_CODE_OF_CONDUCT_VI_VI from '@/assets/policies/drivers_code_of_conduct_vi_vi.md';
import DRIVERS_CODE_OF_CONDUCT_ZH_TW_HK from '@/assets/policies/drivers_code_of_conduct_zh-TW_hk.md';
import PRIVACY_POLICY_DRIVER_EN_HK from '@/assets/policies/privacy_policy_driver_en_hk.md';
import PRIVACY_POLICY_DRIVER_EN_KH from '@/assets/policies/privacy_policy_driver_en_kh.md';
import PRIVACY_POLICY_DRIVER_EN_SG from '@/assets/policies/privacy_policy_driver_en_sg.md';
import PRIVACY_POLICY_DRIVER_EN_TH from '@/assets/policies/privacy_policy_driver_en_th.md';
import PRIVACY_POLICY_DRIVER_KH_KH from '@/assets/policies/privacy_policy_driver_kh_kh.md';
import PRIVACY_POLICY_DRIVER_TH_TH from '@/assets/policies/privacy_policy_driver_th_th.md';
import PRIVACY_POLICY_DRIVER_VI_VI from '@/assets/policies/privacy_policy_driver_vi_vi.md';
import PRIVACY_POLICY_DRIVER_ZH_TW_HK from '@/assets/policies/privacy_policy_driver_zh-TW_hk.md';
import PRIVACY_POLICY_RIDER_EN_HK from '@/assets/policies/privacy_policy_rider_en_hk.md';
import PRIVACY_POLICY_RIDER_EN_KH from '@/assets/policies/privacy_policy_rider_en_kh.md';
import PRIVACY_POLICY_RIDER_EN_SG from '@/assets/policies/privacy_policy_rider_en_sg.md';
import PRIVACY_POLICY_RIDER_EN_TH from '@/assets/policies/privacy_policy_rider_en_th.md';
import PRIVACY_POLICY_RIDER_KH_KH from '@/assets/policies/privacy_policy_rider_kh_kh.md';
import PRIVACY_POLICY_RIDER_TH_TH from '@/assets/policies/privacy_policy_rider_th_th.md';
import PRIVACY_POLICY_RIDER_ZH_TW_HK from '@/assets/policies/privacy_policy_rider_zh-TW_hk.md';
import PRIVACY_POLICY_VI_VI from '@/assets/policies/privacy_policy_vi_vi.md';
import TADA_TERM_OF_DRIVER_EN_HK from '@/assets/policies/tada_term_for_driver_en_hk.md';
import TADA_TERM_OF_DRIVER_EN_KH from '@/assets/policies/tada_term_for_driver_en_kh.md';
import TADA_TERM_OF_DRIVER_EN_SG from '@/assets/policies/tada_term_for_driver_en_sg.md';
import TADA_TERM_OF_DRIVER_EN_TH from '@/assets/policies/tada_term_for_driver_en_th.md';
import TADA_TERM_OF_DRIVER_EN_VI from '@/assets/policies/tada_term_for_driver_en_vi.md';
import TADA_TERM_OF_DRIVER_KH_KH from '@/assets/policies/tada_term_for_driver_kh_kh.md';
import TADA_TERM_OF_DRIVER_TH_TH from '@/assets/policies/tada_term_for_driver_th_th.md';
import TADA_TERM_OF_DRIVER_VI_VI from '@/assets/policies/tada_term_for_driver_vi_vi.md';
import TADA_TERM_OF_DRIVER_ZH_TW_HK from '@/assets/policies/tada_term_for_driver_zh-TW_hk.md';
import TADA_TERM_OF_RIDER_EN_HK from '@/assets/policies/tada_term_for_rider_en_hk.md';
import TADA_TERM_OF_RIDER_EN_KH from '@/assets/policies/tada_term_for_rider_en_kh.md';
import TADA_TERM_OF_RIDER_EN_SG from '@/assets/policies/tada_term_for_rider_en_sg.md';
import TADA_TERM_OF_RIDER_EN_TH from '@/assets/policies/tada_term_for_rider_en_th.md';
import TADA_TERM_OF_RIDER_KH_KH from '@/assets/policies/tada_term_for_rider_kh_kh.md';
import TADA_TERM_OF_RIDER_TH_TH from '@/assets/policies/tada_term_for_rider_th_th.md';
import TADA_TERM_OF_RIDER_VI_VI from '@/assets/policies/tada_term_for_rider_vi_vi.md';
import TADA_TERM_OF_RIDER_ZH_TW_HK from '@/assets/policies/tada_term_for_rider_zh-TW_hk.md';

export default {
  DRIVERS_CODE_OF_CONDUCT_EN_SG,
  DRIVERS_CODE_OF_CONDUCT_VI_VI,
  DRIVERS_CODE_OF_CONDUCT_EN_KH,
  DRIVERS_CODE_OF_CONDUCT_KH_KH,
  DRIVERS_CODE_OF_CONDUCT_EN_TH,
  DRIVERS_CODE_OF_CONDUCT_TH_TH,
  CODE_OF_CONDUCT_EN_SG,
  COMMUNITY_GUIDELINE,
  COMMUNITY_GUIDELINE_EN_KH,
  COMMUNITY_GUIDELINE_KH_KH,
  COMMUNITY_GUIDELINE_EN_TH,
  COMMUNITY_GUIDELINE_TH_TH,
  PRIVACY_POLICY_DRIVER_EN_SG,
  PRIVACY_POLICY_RIDER_EN_SG,
  PRIVACY_POLICY_DRIVER_VI_VI,
  PRIVACY_POLICY_RIDER_VI_VI: PRIVACY_POLICY_VI_VI,
  PRIVACY_POLICY_RIDER_EN_TH,
  PRIVACY_POLICY_RIDER_TH_TH,
  PRIVACY_POLICY_RIDER_EN_KH,
  PRIVACY_POLICY_RIDER_KH_KH,
  PRIVACY_POLICY_DRIVER_EN_KH,
  PRIVACY_POLICY_DRIVER_KH_KH,
  PRIVACY_POLICY_DRIVER_EN_TH,
  PRIVACY_POLICY_DRIVER_TH_TH,
  TADA_TERM_OF_DRIVER_EN_SG,
  TADA_TERM_OF_DRIVER_EN_VI,
  TADA_TERM_OF_DRIVER_VI_VI,
  TADA_TERM_OF_DRIVER_EN_KH,
  TADA_TERM_OF_DRIVER_KH_KH,
  TADA_TERM_OF_DRIVER_EN_TH,
  TADA_TERM_OF_DRIVER_TH_TH,
  TADA_TERM_OF_RIDER_EN_SG,
  TADA_TERM_OF_RIDER_VI_VI,
  TADA_TERM_OF_RIDER_EN_TH,
  TADA_TERM_OF_RIDER_TH_TH,
  COMMUNITY_GUIDELINE_EN_HK,
  COMMUNITY_GUIDELINE_ZH_TW_HK,
  DRIVERS_CODE_OF_CONDUCT_EN_HK,
  DRIVERS_CODE_OF_CONDUCT_ZH_TW_HK,
  PRIVACY_POLICY_DRIVER_EN_HK,
  PRIVACY_POLICY_DRIVER_ZH_TW_HK,
  PRIVACY_POLICY_RIDER_EN_HK,
  PRIVACY_POLICY_RIDER_ZH_TW_HK,
  TADA_TERM_OF_DRIVER_EN_HK,
  TADA_TERM_OF_DRIVER_ZH_TW_HK,
  TADA_TERM_OF_RIDER_EN_HK,
  TADA_TERM_OF_RIDER_ZH_TW_HK,
  TADA_TERM_OF_RIDER_EN_KH,
  TADA_TERM_OF_RIDER_KH_KH,
};
