import React from 'react'
import { withTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import styled from 'styled-components'
import { HELP_CENTER_DETAIL_FOOTER_HEIGHT } from '../../../../constants/constants'
import Button from '../../../Common/Button'

const DetailFooter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 8px;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;

  height: ${HELP_CENTER_DETAIL_FOOTER_HEIGHT}px;
  padding: 20px 24px;
  border-top: 8px #edeff1 solid;
  box-sizing: border-box;

  & > .help_center__question_footer__title {
    flex: 0;
    order: 0;
    align-self: stretch;
    flex-grow: 0;

    margin: 0;
    padding-bottom: 5px;
    font-weight: 700;
    color: #414345;
  }
  & > .help_center__question_footer__content {
    flex: 0;
    order: 0;
    align-self: stretch;
    flex-grow: 0;

    margin: 0;
    font-size: 14px;
  }
  & > .help_center__question_footer__button {
    flex: none;
    order: 2;
    flex-grow: 0;

    padding: 0 12px;

    height: 32px;
    border: 1px solid #000;
    border-radius: 6px;
    font-size: 14px;

    color: #000;
  }
`
function HelpCenterDetailFooter ({ topic, t }) {
  const history = useHistory()

  const handleClickReport = () => {
    history.push({
      pathname: `/help-center/${topic.id}/report`,
      state: {
        topic
      }
    })
  }

  return (
    <DetailFooter>
      <h3 className='help_center__question_footer__title'>{t('report.title_report_issue')}</h3>
      <p className='help_center__question_footer__content'>{t('report.content_report_issue')}</p>
      <Button className='help_center__question_footer__button' onClick={handleClickReport}>
        {t('report.label_report_an_issue')}
      </Button>
    </DetailFooter>
  )
}

export default withTranslation()(HelpCenterDetailFooter)
