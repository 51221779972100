import { useEffect, useState } from 'react';

// @ts-ignore
import firebase from 'firebase/app';

import { getDatabase, signInAnonymously } from '../../../firebaseConfig';

const CLEAN_UP = Symbol('CLEAN_UP');

/*
  {
    "codStatus": 10,
    "dp": "1.4009722703372278,103.87058794498444,0.0",
    "nr": 1700,
    "o": 0,
    "rl": "0,0,0",
    "s": 60,
    "showRiderLocation": false,
    "warningLogId": "ea8e88b2-4000-4d19-a57b-b008b7e0b370",
    "wayPointCount": 0
  }
*/

export const useDriverRouteListener = (rideId: string) => {
  const [driverRoute, setDriverRoute] = useState<google.maps.LatLngLiteral | null>(null);
  const [rotation, setRotation] = useState<number | null>(null);
  const [codStatus, setCodStatus] = useState<number | null>(null); // codStatus 10이면 COD

  useEffect(() => {
    if (!firebase.apps.length) {
      return;
    }

    if (rideId) {
      const database = getDatabase();

      let rideRef: firebase.database.Reference | null | typeof CLEAN_UP = null;

      const connectDB = async () => {
        await signInAnonymously();

        if (rideRef === CLEAN_UP) {
          return;
        }

        rideRef = database.ref(`v2/rides/${rideId}`);
        rideRef.on(
          'value',
          (snapshot) => {
            const val = snapshot.val();

            if (val?.dp) {
              const [lat, lng, rotation] = val.dp.split(',');

              setDriverRoute({ lat: +lat, lng: +lng });
              setRotation(rotation);
            }

            if (val?.codStatus) {
              setCodStatus(val.codStatus);
            }
          },
          (error: string) => {
            console.error('firebase error: ', error);
          }
        );
      };

      connectDB();

      return () => {
        (rideRef as firebase.database.Reference)?.off();
        rideRef = CLEAN_UP;
      };
    }
  }, [rideId]);

  return { driverRoute, codStatus, rotation };
};

export const useRideStatusListener = (rideId: string) => {
  const [rideStatus, setRideStatus] = useState(null);

  useEffect(() => {
    if (!firebase.apps.length) {
      return;
    }

    if (rideId) {
      const database = getDatabase();

      let rideRef: firebase.database.Reference | typeof CLEAN_UP | null = null;

      const connectDB = async () => {
        await signInAnonymously();

        if (rideRef === CLEAN_UP) {
          return;
        }

        rideRef = database.ref(`v2/rides/${rideId}/s`);
        rideRef.on(
          'value',
          (snapshot) => {
            const val = snapshot.val();

            if (val) {
              setRideStatus(val);
            }
          },
          (error: string) => {
            console.error('firebase error: ', error);
          }
        );
      };

      connectDB();

      return () => {
        (rideRef as firebase.database.Reference)?.off();
        rideRef = CLEAN_UP;
      };
    }
  }, [rideId]);

  return { rideStatus };
};
