import { MutableRefObject } from 'react';

import styled from 'styled-components';

import { useLiveRideContext } from './LiveRideProvider';
import { REGION } from '../constant/constants';
import { getDriverRate } from '../utils';

const DriverProfileContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  > .profileWrapper {
    width: 48px;
    height: 48px;
    margin-right: 10px;
    border-radius: 30px;
    > img {
      overflow: hidden;
      width: 48px;
      height: 48px;
    }
  }

  > .profileContent {
    width: 100%;
    > .profileNameRank {
      display: flex;
      flex-direction: row;
      width: 100%;
      font-weight: 600;
      > span {
        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        margin-right: 10px;
      }
      > div {
        display: flex;
        justify-content: center;
        align-items: center;
        > img {
          margin-right: 5px;
          margin-bottom: 3px;
        }
      }
    }

    > .profileSubContent {
      margin-top: 5px;
      font-weight: 400;
      color: #b9b9b9;
    }
  }
`;

interface DriverProfileProps {
  driverRef: MutableRefObject<HTMLDivElement | null>;
}

function DriverProfile({ driverRef }: DriverProfileProps) {
  const { images, rideInfo } = useLiveRideContext();

  return (
    <DriverProfileContainer ref={driverRef}>
      <div className='profileWrapper'>
        <img src={rideInfo!.profilePhotoUrl!} alt='Profile' />
      </div>
      <div className='profileContent'>
        <div className='profileNameRank'>
          <span>{rideInfo!.driverName}</span>
          <div>
            <img src={images.star} alt='star' />
            {getDriverRate(rideInfo?.rate ?? 5)}
          </div>
        </div>
        <div className='profileSubContent'>
          <span>{rideInfo?.carPlate || ''} · </span>
          <span>{rideInfo?.carColor || ''} · </span>
          <span>
            {rideInfo?.carMaker || ''} {rideInfo?.carModel || ''}
          </span>
        </div>
        {rideInfo!.region === REGION.TH && <div className='profileSubContent'>{rideInfo?.province || ''}</div>}
      </div>
    </DriverProfileContainer>
  );
}

export default DriverProfile;
