import { Component, Fragment } from 'react';

import qs from 'qs';
import autoBind from 'react-autobind';
import ReactGA from 'react-ga';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import styled from 'styled-components';

import { localeSetToLanguage } from './hooks';
import actions from '../../../actions';
import triangle from '../../../assets/images/tada/triangle.png';
import vIconWhite from '../../../assets/images/tada/v_white.png';
import { LOCALE_TYPE } from '../../../constants/constants';
import { LANGS } from '../../../i18n';
import { getLocale, getDriver } from '../../../selectors';
import { mobileCheck } from '../../../utils/MyUtils';

export const Dropdown = styled.div`
  position: relative;
  display: inline-block;
  font-size: 14px;
  cursor: pointer;
  text-decoration: none;
`;

export const DropdownImgWrapper = styled.div`
  font-weight: 500;
  background-color: #fff;
  width: 120px;
  height: 30px;
  line-height: 30px;
  box-sizing: border-box;
  border: solid 1px #979797;
  position: relative;
  cursor: pointer;
  color: #000;
  padding-left: 16px;
  &:after,
  &::after {
    position: absolute;
    width: 18px;
    height: 10px;
    top: 10px;
    right: 8px;
    content: ' ';
    background-image: url(${triangle});
    background-repeat: no-repeat;
    background-position: ${(props) => (props.isShow ? '-17px' : '0')} 0;
  }

  @media (max-width: 1000px) {
    width: 100px;
    color: #fff;
    padding: 0;
    border: none;
    background-color: transparent;

    &:after,
    &::after {
      background-image: url(${vIconWhite});
      background-position: center;
    }
  }
`;

export const DropdownContent = styled.div`
  position: absolute;
  background-color: #ffffff;
  box-sizing: border-box;
  z-index: 1;
`;

export const DropdownContentSelect = styled.select`
  -webkit-appearance: button;
  -webkit-user-select: none;
  appearance: none;
  background: transparent;
  text-align: left;
  border: 0;
  color: #ffffff;
  overflow: hidden;
  height: 30px;
  padding-right: 20px;
`;

export const DropdownContentItem = styled.button`
  display: block;
  border: none;
  outline: 0;
  padding-left: 16px;
  box-sizing: border-box;
  cursor: pointer;
  width: 120px;
  height: 30px;
  font-size: 14px;
  font-weight: 500;
  color: #000;
  background-color: #fff;
  border: solid 1px #979797;
  border-top: none;
  text-align: left;
  &:hover {
    background-color: #eaba28;
    color: #fff;
  }
`;

class HeaderDropdown extends Component {
  constructor(props) {
    super(props);

    autoBind(this);

    this.state = {
      isShow: false,
      isMobile: mobileCheck(),
    };
  }

  toggleDropdown(e) {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    this.setState({ isShow: !this.state.isShow });
  }

  componentDidMount() {
    const { changeLocaleRequest } = this.props;
    const search = qs.parse(this.props.location.search.replace('?', ''));
    const language = localeSetToLanguage(search.language);
    !this.props.driver.locale && LANGS.includes(language) && changeLocaleRequest(language);
  }

  handleChangeLocale(locale) {
    const { changeLocaleRequest, selectedLocale } = this.props;
    ReactGA.event({
      category: 'Locale',
      action: 'Change',
      label: `${locale} / from ${selectedLocale}`,
    });

    changeLocaleRequest(locale);
    const search = qs.parse(this.props.location.search.replace('?', ''));
    this.props.history.replace({ search: qs.stringify({ ...search, language: locale }) });
    this.props.onSelect && this.props.onSelect();
  }

  render() {
    const { t, selectedLocale, location } = this.props;
    const { isMobile } = this.state;
    const openVietnam = true;
    return (
      <div>
        {isMobile ? (
          <DropdownImgWrapper isShow={this.state.isShow}>
            <DropdownContentSelect onChange={(e) => this.handleChangeLocale(e.target.value)} value={selectedLocale}>
              <option key={'en'} value={'en'}>
                {t('locale.en')}
              </option>
              {!location.pathname.startsWith('/driver_') && (
                <Fragment>
                  {/* <option key={'ko'} value={'ko'}>{t('locale.ko')}</option> */}
                  <option key={LOCALE_TYPE.ZH_CN} value={LOCALE_TYPE.ZH_CN}>
                    {t('locale.zh_hans')}
                  </option>
                  {/* <option key={'zh_hant'} value={'zh_hant'}>{t('locale.zh_hant')}</option> */}
                  {/* <option key={'vi'} value={'vi'}>{t('locale.vi')}</option> */}
                </Fragment>
              )}
              {openVietnam ? (
                <option key={'vi'} value={'vi'}>
                  {t('locale.vi')}
                </option>
              ) : null}
              <option key={'km'} value={'km'}>
                {t('locale.km')}
              </option>
              <option key={'ko'} value={'ko'}>
                {t('locale.ko')}
              </option>
              <option key={'th'} value={'th'}>
                {t('locale.th')}
              </option>
              <option key={'zh-TW'} value={'zh-TW'}>
                {t('locale.zh-TW')}
              </option>
              <option key={'jp'} value={'jp'}>
                {t('locale.jp')}
              </option>
            </DropdownContentSelect>
            {/* <MobileDropdownIcon className="dropdown-icn" /> */}
          </DropdownImgWrapper>
        ) : (
          <Dropdown onClick={this.toggleDropdown}>
            <DropdownImgWrapper isShow={this.state.isShow}>
              {t(`locale.${selectedLocale}`)}
              {/* <DropdownIcon className="dropdown-icn" /> */}
            </DropdownImgWrapper>
            {this.state.isShow && (
              <DropdownContent>
                <DropdownContentItem onClick={() => this.handleChangeLocale('en')}>{t('locale.en')}</DropdownContentItem>
                {!location.pathname.startsWith('/driver_') && (
                  <Fragment>
                    {/* <DropdownContentItem onClick={() => this.handleChangeLocale('ko')}>{t('locale.ko')}</DropdownContentItem> */}
                    <DropdownContentItem onClick={() => this.handleChangeLocale(LOCALE_TYPE.ZH_CN)}>{t('locale.zh_hans')}</DropdownContentItem>
                    {/* <DropdownContentItem onClick={() => this.handleChangeLocale('zh_hant')}>{t('locale.zh_hant')}</DropdownContentItem> */}
                    {/* <DropdownContentItem onClick={() => this.handleChangeLocale('vi')}>{t('locale.vi')}</DropdownContentItem> */}
                  </Fragment>
                )}
                {openVietnam ? <DropdownContentItem onClick={() => this.handleChangeLocale('vi')}>{t('locale.vi')}</DropdownContentItem> : null}
                <DropdownContentItem onClick={() => this.handleChangeLocale('km')}>{t('locale.km')}</DropdownContentItem>
                <DropdownContentItem onClick={() => this.handleChangeLocale('ko')}>{t('locale.ko')}</DropdownContentItem>
                <DropdownContentItem onClick={() => this.handleChangeLocale('th')}>{t('locale.th')}</DropdownContentItem>
                <DropdownContentItem onClick={() => this.handleChangeLocale('zh-TW')}>{t('locale.zh-TW')}</DropdownContentItem>
                <DropdownContentItem onClick={() => this.handleChangeLocale('jp')}>{t('locale.jp')}</DropdownContentItem>
              </DropdownContent>
            )}
          </Dropdown>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  selectedLocale: getLocale(state),
  driver: getDriver(state),
});

const mapDispatchToProps = actions;

const enhance = compose(withRouter, connect(mapStateToProps, mapDispatchToProps), withTranslation());

export default enhance(HeaderDropdown);
