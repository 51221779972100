import { useEffect, useState } from 'react';

import styled from 'styled-components';

import { getSupportPageInfo } from './util';
import { useSearch } from '../../../hooks/router';
import { decodeDescription } from '../../../utils/MyUtils';
import { getLocationWithFallback } from '../../../utils/location';
import Loading from '../../Common/Loading';
import MobileWebViewContainer from '../../Common/MobileWebViewContainer';

const SupportPageDescription = styled.div`
  margin-bottom: 24px;
  padding: 0 24px;
  overflow-wrap: break-word;
`;

function SupportPage() {
  const search = useSearch();

  const [contents, setContents] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        const { appAuthority, locale } = search;

        const { region, city } = await getLocationWithFallback(search.region, search.city);

        const content = await getSupportPageInfo(region, city, appAuthority, locale);

        setContents(content.contents);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [search]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <MobileWebViewContainer isNavigator={false} headerSize={0} isLoading={false}>
      {contents?.map((content) => (
        <SupportPageDescription dangerouslySetInnerHTML={{ __html: decodeDescription(content.description) }} key={content.id} />
      ))}
    </MobileWebViewContainer>
  );
}

export default SupportPage;
