import { useEffect, useState } from 'react';
import { getIsMobileView } from '../utils/MyUtils';

export const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(getIsMobileView());

  useEffect(() => {
    const onChange = () => {
      setIsMobile(getIsMobileView());
    };

    window.addEventListener('resize', onChange);

    return () => {
      window.removeEventListener('resize', onChange);
    };
  }, []);

  return isMobile;
};
