import _ from 'lodash'
import { decamelizeKeys } from 'humps'
import ReactGA from 'react-ga'
import * as Sentry from '@sentry/browser'
import { getContactInfo, LOCALE_TYPE, MINIMUM_DESKTOP_WIDTH, REGION_BY_CONTACT_INFO } from '../constants/constants'
import { getCurrentRegion } from './location'

const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
export const validateEmail = email => {
  if (!email) return false
  return emailRegex.test(email)
}

const amountContributeRegex = /^-?([0-9]+([.]\d{0,2})?)?$/
export const validateAmountContribute = value => {
  if (value === null || value === undefined) return false
  return amountContributeRegex.test(value)
}

export const scrollToTop = () => window.scrollTo(0, 0) //eslint-disable-line

export const scrollToHash = to => {
  ReactGA.event({
    category: 'ScrollToSection',
    action: 'Click',
    label: to
  })
  window.location.hash = to
  if (to === '#') {
    window.scroll(0, 0)
  } else {
    const elem = document.getElementById(_.replace(to, '#', ''))
    if (elem) {
      elem.scrollIntoView()
    }
  }
}

/* eslint-disable */
export const mobileCheck = () => {
  let check = false
  const agent = navigator.userAgent || navigator.vendor || window.opera
  if (
    /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
      agent
    ) ||
    /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
      agent.substr(0, 4)
    )
  ) {
    check = true
  }
  return check
}
/* eslint-disable */

export const trackEvent = (category, action, label, from) => {
  ReactGA.event({
    category,
    action,
    label: `${label}${from ? ' / ' + from : ''}`
  })
}

export const openNewTab = (url, from) => {
  trackEvent('Social', 'Click', url, from)
  window.open(url, '_blank')
} // eslint-disable-line no-undef

export const imageToFormData = (name, image) => {
  const fd = new FormData()
  fd.append(name, image)
  return fd
}

export const addDataToFormData = (fd, obj) => {
  _.forEach(decamelizeKeys(obj), (value, key) => {
    fd.append(key, value)
  })
  return fd
}

export const between = (check, start, end) => {
  return start <= check && check < end
}

export const mvlWordBreakSetting = props => {
  return !_.includes(['zh_hans', 'zh_hant', 'zh-CN', 'zh-TW'], props.selectedLocale) ? 'keep-all' : 'break-all'
}

export const toggleOnBeforeUnload = isCheck => {
  let temp = null
  if (isCheck) {
    temp = () => 'Are you sure you want to leave?'
  }
  window.onbeforeunload = temp // eslint-disable-line no-undef, no-alert
}

const ignoreErrors = [
  /__show__deepen/,
  /Access is denied/,
  /anonymous function: captureException/,
  /Blocked a frame with origin/,
  /console is not defined/,
  /event is not defined/,
  /feedConf/,
  /MyIPhoneApp_ModifyShowModalDialog/,
  /vid_mate_check is not defined/,
  /win\.document\.body/,
  /window\._sharedData\.entry_data/,
  /ztePageScrollModule/
]

export const captureException = error => {
  const msg = error.message
  if (!_.some(ignoreErrors, e => msg.match(e))) {
    Sentry.captureException(error)
  }
}

export const decodeDescription = description => {
  try {
    return decodeURIComponent(window.atob(description))
  } catch (e) {
    return description
  }
}

export const transformPathDToCoordinate = d => {
  // 정규식을 사용하여 d 속성의 좌표 추출
  const coordinatePattern = /([MLHVZ])([^MLHVZ]*)/g
  let match
  let currentX = 0
  let currentY = 0
  let points = []

  while ((match = coordinatePattern.exec(d)) !== null) {
    const command = match[1]
    const values = match[2]
      .trim()
      .split(/\s+|,/)
      .map(Number)

    switch (command) {
      case 'M': // Move to
      case 'L': // Line to
        for (let i = 0; i < values.length; i += 2) {
          currentX = values[i]
          currentY = values[i + 1]
          points.push([currentX, currentY])
        }
        break
      case 'V': // Vertical line to
        currentY = values[0]
        points.push([currentX, currentY])
        break
      case 'H': // Horizontal line to
        currentX = values[0]
        points.push([currentX, currentY])
        break
      case 'Z': // Close path
        if (points.length > 0) {
          points.push(points[0]) // 경로를 닫는 점 추가
        }
        break
      default:
        break
    }
  }

  return points
}

export const getTopVertex = points => {
  // y 값이 가장 작은 좌표 찾기 (맨 위 꼭짓점)
  let topVertex = points[0]
  for (let i = 1; i < points.length; i++) {
    if (points[i][1] < topVertex[1]) {
      topVertex = points[i]
    }
  }

  return topVertex
}

export const getWindowDimensions = () => {
  const { innerWidth, innerHeight } = window

  return {
    innerWidth,
    innerHeight
  }
}

export const getIsMobileView = () => {
  const { innerWidth } = getWindowDimensions()
  const isMobile = innerWidth < MINIMUM_DESKTOP_WIDTH

  return isMobile
}

export const checkIOS = () => {
  const userAgent = window.navigator.userAgent || window.navigator.vendor || window.opera

  return /iPad|iPhone|iPod/.test(userAgent) || (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
}

export const getContactInfoByRegion = async language => {
  if (language === LOCALE_TYPE.EN) {
    const region = await getCurrentRegion()

    if (region) {
      return REGION_BY_CONTACT_INFO[region]
    }
  }

  return getContactInfo(language)
}
