import { createContext, ReactNode, useContext } from 'react';

import { LiveRideContextType } from '../types';

export const LiveRideContext = createContext<LiveRideContextType>({
  branchKey: '',
  googleApiKey: '',
  serverHost: '',
  urlId: '',
  images: {
    additionalStop: '',
    appLogo: '',
    cross: '',
    currentLocation: '',
    dropOff: '',
    dropOffPin: '',
    expiredLinkIcon: '',
    mapBike: '',
    mapETukTuk: '',
    mapIontadaIcon: '',
    mapPh: '',
    mapRemoque: '',
    mapTaxi: '',
    mapTukTuk: '',
    pickup: '',
    pickupPin: '',
    star: '',
    targetPin: '',
    mapBikeWithHuman: '',
    mapHkTaxi: '',
    mapHkTerritoriesTaxi: '',
  },
  rideInfo: null,
  rideRouting: null,
  googleMap: null,
  isNARide: false,
});

export const useLiveRideContext = () => useContext(LiveRideContext);

export function LiveRideProvider({ value, children }: { value: LiveRideContextType; children: ReactNode }) {
  return <LiveRideContext.Provider value={value}>{children}</LiveRideContext.Provider>;
}
