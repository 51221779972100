import { getRegionFromLocale } from '../components/Pages/FaqPage/hooks';
import {
  AVAILABLE_LOCALE_POLICY,
  AVAILABLE_POLICY_REGION,
  CITIES_BY_REGION,
  COORD_BY_REGION,
  LOCALE_TYPE,
  POLICY_BY_REGION,
  REGION,
  REGION_BY_LOCALE,
  SERVICE_REGIONS,
} from '../constants/constants';

export const getCurrentLocation = () =>
  new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(resolve, reject, { maximumAge: 1000 * 60, timeout: 1000 });
  });

export const getRegionUsingCoords = (lat, lon) =>
  Object.keys(COORD_BY_REGION).find((key) => {
    const { SW, NE } = COORD_BY_REGION[key];
    return lat >= SW.lat && lon >= SW.lon && lat <= NE.lat && lon <= NE.lon;
  });

export const getCurrentRegion = async () => {
  // catch error when denied Geolocation
  try {
    const { coords: position } = await getCurrentLocation();
    return getRegionUsingCoords(position.latitude, position.longitude);
  } catch (e) {
    return null;
  }
};

const validateRegion = (region) => (AVAILABLE_POLICY_REGION.includes(region) ? region : '');

// policy: POLICY 타입, locale은 en, vi, km, ko, zh-CN, th
export const getPolicy = async (policy, locale, regionParam) => {
  const region = await getCurrentRegion();
  const regionData = validateRegion(regionParam) || region || REGION_BY_LOCALE[locale].at(0) || 'SG';

  // region, locale에 맞는 policy를 지원하지 않을 때 같은 region의 en 버전으로 지원
  if (!AVAILABLE_LOCALE_POLICY[policy][regionData].includes(locale) && AVAILABLE_LOCALE_POLICY[policy][regionData].includes(LOCALE_TYPE.EN)) {
    // en policy 지원된다면 같은 region의 en policy 리턴
    return POLICY_BY_REGION[regionData][LOCALE_TYPE.EN][policy];
  }

  // en policy 지원 안된다면 기존처럼 SG policy fallback
  return POLICY_BY_REGION[regionData][locale][policy];
};

export const normalizeLanguage = (language) => {
  if (['en', 'eng', 'english'].includes(language)) {
    return LOCALE_TYPE.EN;
  }

  if (['ko', 'kr', 'kor'].includes(language)) {
    return LOCALE_TYPE.KO;
  }

  if (['km', 'kh', 'khmer'].includes(language)) {
    return LOCALE_TYPE.KM;
  }

  if (['vi', 'vn', 'viet'].includes(language)) {
    return LOCALE_TYPE.VI;
  }

  if (['th', 'thai'].includes(language)) {
    return LOCALE_TYPE.TH;
  }

  if (['zh', 'zh_hans', 'hans', 'zh-CN'].includes(language)) {
    return LOCALE_TYPE.ZH_CN;
  }

  if (['zh_hant', 'hant', 'zh-TW'].includes(language)) {
    return LOCALE_TYPE.ZH_TW;
  }

  return LOCALE_TYPE.EN;
};

/* 
  ### region, city 기본 fallback 처리
 - 위치 기반 location 으로 region, city fallback
 - 선택된 언어 기반 region으로 fallback (기존에도 언어기반으로 fallback하는 케이스에서 적용)
 - region이 non service 지역인 경우 SG - SIN으로 fallback

### 현재 발생할 수 있는 케이스
  1. 앱에서 region, city 보냄
  2. 앱에서 region만 보냄
  3. 앱에서 아무것도 안보냄

### 각 케이스별 대응
  먼저 non service 지역인지 체크 (SG, KH, VN, TH, HK), 아닌 경우 -> fallback 처리
  1. search의 region, city 그대로 리턴
  2. search의 city가 없는 경우 -> region 기반 city 기본값으로 fallback (SG - SIN, KH - PNH, VN - HCM, TH - BKK, HK - HKG)
  3. search의 region, city 둘 다 없는 경우 -> currentLocation 으로 return, non service 지역이면 SG 기준으로 fallback
*/
const getRegionByLocationAndLocale = async (selectedLocale) =>
  (await getCurrentRegion()) ?? (selectedLocale ? getRegionFromLocale(selectedLocale) : REGION.SG);

export const getLocationWithFallback = async (region, city, selectedLocale) => {
  const isServiceRegion = SERVICE_REGIONS.includes(region);
  const regionData = isServiceRegion ? region : await getRegionByLocationAndLocale(selectedLocale);
  const cityData = (isServiceRegion && city) || CITIES_BY_REGION[regionData]?.[0];

  return {
    region: regionData,
    city: cityData,
  };
};
