import React, { useEffect } from 'react'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { withTranslation } from 'react-i18next'
import { useSearch } from '../../../hooks/router'
import { normalizeLanguage } from '../../../utils/location'
import { LOCALE_TYPE } from '../../../constants/constants'
import RequestOTPContent from './parts/RequestOTPContent'

const SITE_KEY = process.env.VITE_INVISIBLE_RECAPTCHA_SITE_KEY || '6Lc1PZEpAAAAAPw9ZuyvY_DZUCXXDq9E8UV5ZXzr'

const getAvailableLanguage = lang => {
  const ALL_LOCALE_TYPE = Object.values(LOCALE_TYPE)

  return ALL_LOCALE_TYPE.includes(lang) ? lang : null
}

function DeleteAccountRequestOTP({ t, i18n }) {
  const search = useSearch()

  useEffect(() => {
    const language = normalizeLanguage(
      getAvailableLanguage(search.language) || getAvailableLanguage(navigator.language) || LOCALE_TYPE.EN
    )
    i18n.changeLanguage(language)
  }, [search])

  return (
    <GoogleReCaptchaProvider reCaptchaKey={SITE_KEY}>
      <RequestOTPContent />
    </GoogleReCaptchaProvider>
  )
}

export default withTranslation()(DeleteAccountRequestOTP)
