import React, { Component } from 'react'
import Button from '../Common/Button'
import styled from 'styled-components'
import Slider from 'react-slick'
import iPhoneX from '../../assets/images/tada/i-phone-x.png'
import { getLocale } from '../../selectors'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import AppCarouselCore2 from './AppCarouselCore'

export const AppCarouselContainer = styled.div`
  // width: 390px;
  display: flex;
  flex-direction: column;

  @media (max-width: 1000px) {
    align-self: flex-start;
    overflow: hidden;
    width: 100%;
  }
`

export const AppCarouselTitle = styled.h2`
  width: 437px;
  text-align: center;
  margin: 0;
  padding: 0;
  font-size: 36px;
  font-weight: 800;
  font-style: normal;
  color: #fff;

  @media (max-width: 1000px) {
    width: 100%;
    font-size: 24px;
    font-weight: bold;
  }
`

export const AppCarouselSlider = styled(Slider)`
  width: 351px;

  @media (max-width: 1000px) {
    width: 223px;
  }
`

export const AppCarouselControlBtn = styled(Button)`
  font-size: 14px;
  font-weight: 500;

  @media (max-width: 1000px) {
    font-size: 12px;
  }
`

export const AppCarouselController = styled.ul`
  width: 390px;
  justify-content: space-between;
  margin: 0 13px;
  padding: 0;
  display: flex;
  border-bottom: solid 1px #0a3142;
  position: absolute;

  @media (min-width: 1001px) {
    top: -200px;
  }

  @media (max-width: 1000px) {
    width: 339px;
    bottom: -100px;
  }

  & > li {
    display: inline-flex;

    & > ${AppCarouselControlBtn} {
      color: #fff;
      height: 60px;
      line-height: 60px;
    }

    &.slick-active > ${AppCarouselControlBtn} {
      color: #0a3142;
      border-bottom: solid 5px #0a3142;
    }
  }
`

export const AppCarouselContentWrapper = styled.div`
  box-sizing: border-box;
  width: 351px;

  @media (max-width: 1000px) {
    width: 223px;
  }
`

export const AppCarouselContent = styled.img`
  width: 351px;

  @media (max-width: 1000px) {
    margin: 0 auto;
    display: block;
    width: 223px;
  }
`

export class AppCarousel extends Component {
  constructor(props) {
    super(props)

    this.state = {
      index: 0
    }
  }

  render() {
    const { t, type, selectedLocale } = this.props
    const { index } = this.state

    return (
      <AppCarouselContainer>
        <AppCarouselTitle>{t(`tada.${type}.app.name_${index + 1}`)}</AppCarouselTitle>
        <AppCarouselCore2
          type={type}
          selectedLocale={selectedLocale}
          t={t}
          setIndex={idx => this.setState({ index: idx })}
        />
      </AppCarouselContainer>
    )
  }
}

const mapStateToProps = state => ({
  selectedLocale: getLocale(state)
})

const enhance = compose(connect(mapStateToProps), withTranslation())

export default enhance(AppCarousel)
