import Sheet from 'react-modal-sheet';
import styled from 'styled-components';

import RideStatus from './RideStatus';
import { CANCELED_RIDE_STATUS_LIST, RIDE_STATUS } from './constant/constants';
import { RideStatusType } from './constant/types';
import { useRideBottomSheet } from './hooks';
import { useDriverRouteListener } from './hooks/firebase';
import DriverProfile from './parts/DriverProfile';
import { useLiveRideContext } from './parts/LiveRideProvider';
import PointSummary from './parts/PointSummary';
import { getBoundsOfMarkers } from './utils';

interface BottomSheetProps {
  rideStatus: RideStatusType;
}

const HeaderContainer = styled.div`
  width: 100%;
  height: 100%;
  & > * {
    box-sizing: border-box;
    font-family: 'Apple SD Gothic Neo';
  }
`;

const ModalContainer = styled.div`
  height: 100%;
  & > * {
    box-sizing: border-box;
    font-family: 'Apple SD Gothic Neo';
  }
`;

const CurrentLocationContainer = styled.div<{ $height?: number }>`
  position: fixed;
  display: flex;
  ${(props) => (props.$height ? `bottom: ${props.$height + 70}px;` : '')}
  right: 12px;
  width: 100%;
  height: 40px;
  z-index: 101;
  cursor: pointer;

  > div {
    display: flex;
    width: 40px;
    height: 40px;
    margin-left: auto;
    justify-content: center;
    border-radius: 100px;
    background: #fff;
    box-shadow: 0px 4px 16px 0px #00000024;

    > img {
      width: 24px;
      height: 24px;
      margin: auto;
    }
  }
`;

const BottomSheet = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  padding: 4px 16px 16px 16px;
  border-radius: 16px 16px 0 0;
  z-index: 101;
  background-color: #fff;

  > .draghandler {
    height: 15px;
    cursor: pointer;
    &:after {
      content: '';
      display: flex;
      width: 40px;
      margin: auto;
      border: 3px solid #d9d9d9;
      border-radius: 4px;
    }
  }
`;

function RideContent({ rideStatus }: BottomSheetProps) {
  const { rideInfo, images, googleMap } = useLiveRideContext();
  const isCancledStatus = CANCELED_RIDE_STATUS_LIST.includes(rideStatus);
  const { driverRoute, codStatus } = useDriverRouteListener(rideInfo!.rideId);
  const { snapPoints, initialSnap, snapIdx, sheetHeight, sheetRef, sheetModalRef, driverRef, onSnap, onCloseSheet } = useRideBottomSheet({
    isCancledStatus,
    rideInfo: rideInfo!,
  });

  const getRiderPosition = (rideStatus: RideStatusType) => {
    if (rideStatus < RIDE_STATUS.RENTAL_STATUS_INUSE) {
      const locaton = rideInfo!.gpsLog?.riderLocation ? rideInfo!.gpsLog.riderLocation : rideInfo!.pickUpPoint!;

      return {
        latitude: locaton.latitude,
        longitude: locaton.longitude,
      };
    }

    if (rideStatus >= RIDE_STATUS.RENTAL_STATUS_INUSE && rideStatus < RIDE_STATUS.RENTAL_STATUS_FINISHED) {
      return {
        latitude: driverRoute!.lat,
        longitude: driverRoute!.lng,
      };
    }

    return null;
  };

  const setCurrentRiderLocation = () => {
    const riderPosition = getRiderPosition(rideStatus);

    if (!riderPosition) {
      return;
    }
    const defaultZoom = googleMap!.current?.getZoom() as number;

    googleMap!.current?.fitBounds(getBoundsOfMarkers(riderPosition), 500);
    googleMap!.current?.setZoom(defaultZoom);
  };

  return (
    <Sheet onClose={onCloseSheet} snapPoints={snapPoints} onSnap={onSnap} initialSnap={initialSnap} ref={sheetModalRef} isOpen>
      <Sheet.Container>
        <Sheet.Header disableDrag>
          <HeaderContainer>
            <CurrentLocationContainer onClick={setCurrentRiderLocation} $height={sheetHeight}>
              <div>
                <img src={images.currentLocation} alt='currentLocationIcon' />
              </div>
            </CurrentLocationContainer>
            <RideStatus rideStatus={rideStatus} codStatus={codStatus!} sheetHeight={sheetHeight} />
          </HeaderContainer>
        </Sheet.Header>
        <Sheet.Content>
          <ModalContainer>
            <BottomSheet ref={sheetRef}>
              <div className='draghandler' />
              {!isCancledStatus && <DriverProfile driverRef={driverRef} />}
              <PointSummary images={images} snapIdx={snapIdx} />
            </BottomSheet>
          </ModalContainer>
        </Sheet.Content>
      </Sheet.Container>
    </Sheet>
  );
}

export default RideContent;
