import bikeIcon from '../../../assets/images/RideProduct/bike.svg'
import carIcon from '../../../assets/images/RideProduct/car.svg'
import etuktukIcon from '../../../assets/images/RideProduct/Etuktuk.svg'
import premiumLargeIcon from '../../../assets/images/RideProduct/premium-large.svg'
import premiumTaxiLargeIcon from '../../../assets/images/RideProduct/premium-taxi-large.svg'
import premiumTaxiIcon from '../../../assets/images/RideProduct/premium-taxi.svg'
import premiumIcon from '../../../assets/images/RideProduct/premium.svg'
import remorqueIcon from '../../../assets/images/RideProduct/remorque.svg'
import standardTaxiLargeIcon from '../../../assets/images/RideProduct/standard-taxi-large.svg'
import standardTaxiIcon from '../../../assets/images/RideProduct/standard-taxi.svg'
import suvIcon from '../../../assets/images/RideProduct/suv.svg'
import tuktukIcon from '../../../assets/images/RideProduct/yellow-tuktuk.svg'
import premiumBikeIcon from '../../../assets/images/RideProduct/bike-plus.svg'

export const CAR_TYPE = {
  CAR_TYPE_SEDAN: 0,
  CAR_TYPE_VAN: 1,
  CAR_TYPE_BUSINESS_VAN: 4,
  CAR_TYPE_BLACK_SEDAN: 13,
  CAR_TYPE_TUKTUK: 1000,
  CAR_TYPE_BIKE: 1001,
  CAR_TYPE_REMORQUE: 1002,
  CAR_TYPE_PREMIUM_BIKE: 1003,
  CAR_TYPE_WIN_BIKE: 1004,
  CAR_TYPE_BUS: 3000,
  CAR_TYPE_MINI_BUS: 3001,
  CAR_TYPE_TAXI_STANDARD: 10000,
  CAR_TYPE_TAXI_STANDARD_LARGE: 10001,
  CAR_TYPE_TAXI_PREMIUM: 10002,
  CAR_TYPE_TAXI_PREMIUM_LARGE: 10003,
  CAR_TYPE_DELIVERY_VAN: 20000,
  CAR_TYPE_DELIVERY_BIKE: 20001,
  CAR_TYPE_ONION_TUKTUK: 30000
}

export const PRODUCT_TYPE = {
  PH: 10,
  TAXI: 11,
  ANYTADA: 12,
  STUDENT: 13,
  EV: 14,
  DELIVERY: 20
}

export const CAR_TYPE_ICON = {
  [CAR_TYPE.CAR_TYPE_SEDAN]: carIcon,
  [CAR_TYPE.CAR_TYPE_VAN]: suvIcon,
  [CAR_TYPE.CAR_TYPE_BUSINESS_VAN]: premiumLargeIcon,
  [CAR_TYPE.CAR_TYPE_BLACK_SEDAN]: premiumIcon,
  [CAR_TYPE.CAR_TYPE_TUKTUK]: tuktukIcon,
  [CAR_TYPE.CAR_TYPE_BIKE]: bikeIcon,
  [CAR_TYPE.CAR_TYPE_REMORQUE]: remorqueIcon,
  [CAR_TYPE.CAR_TYPE_PREMIUM_BIKE]: premiumBikeIcon,
  [CAR_TYPE.CAR_TYPE_WIN_BIKE]: bikeIcon,
  [CAR_TYPE.CAR_TYPE_TAXI_STANDARD]: standardTaxiIcon,
  [CAR_TYPE.CAR_TYPE_TAXI_STANDARD_LARGE]: standardTaxiLargeIcon,
  [CAR_TYPE.CAR_TYPE_TAXI_PREMIUM]: premiumTaxiIcon,
  [CAR_TYPE.CAR_TYPE_TAXI_PREMIUM_LARGE]: premiumTaxiLargeIcon,
  [CAR_TYPE.CAR_TYPE_DELIVERY_VAN]: suvIcon,
  [CAR_TYPE.CAR_TYPE_DELIVERY_BIKE]: bikeIcon,
  [CAR_TYPE.CAR_TYPE_ONION_TUKTUK]: etuktukIcon
}

export const FAMILY_LARGE_CAR_TYPES = [
  CAR_TYPE.CAR_TYPE_VAN,
  CAR_TYPE.CAR_TYPE_BUSINESS_VAN,
  CAR_TYPE.CAR_TYPE_TAXI_PREMIUM_LARGE,
  CAR_TYPE.CAR_TYPE_MINI_BUS
]

export const CAR_TYPE_STRING = {
  [CAR_TYPE.CAR_TYPE_SEDAN]: 'car_type_economy',
  [CAR_TYPE.CAR_TYPE_VAN]: 'car_type_economy_large',
  [CAR_TYPE.CAR_TYPE_BUSINESS_VAN]: 'car_type_premium_large',
  [CAR_TYPE.CAR_TYPE_BLACK_SEDAN]: 'car_type_premium',
  [CAR_TYPE.CAR_TYPE_TUKTUK]: 'car_type_tuktuk',
  [CAR_TYPE.CAR_TYPE_BIKE]: 'car_type_tada_bike',
  [CAR_TYPE.CAR_TYPE_REMORQUE]: 'car_type_remorque',
  [CAR_TYPE.CAR_TYPE_PREMIUM_BIKE]: 'car_type_premium_bike',
  [CAR_TYPE.CAR_TYPE_WIN_BIKE]: 'car_type_win_bike',
  [CAR_TYPE.CAR_TYPE_BUS]: 'car_type_bus',
  [CAR_TYPE.CAR_TYPE_MINI_BUS]: 'car_type_mini_bus',
  [CAR_TYPE.CAR_TYPE_TAXI_STANDARD]: 'car_type_taxi',
  [CAR_TYPE.CAR_TYPE_TAXI_STANDARD_LARGE]: 'car_type_taxi_large',
  [CAR_TYPE.CAR_TYPE_TAXI_PREMIUM]: 'car_type_premium_taxi',
  [CAR_TYPE.CAR_TYPE_TAXI_PREMIUM_LARGE]: 'car_type_premium_taxi_large',
  [CAR_TYPE.CAR_TYPE_DELIVERY_VAN]: 'car_type_delivery_van',
  [CAR_TYPE.CAR_TYPE_DELIVERY_BIKE]: 'car_type_delivery_bike',
  [CAR_TYPE.CAR_TYPE_ONION_TUKTUK]: 'car_type_onion_tuktuk'
}

export const PRODUCT_TYPE_STRINGS = {
  [PRODUCT_TYPE.PH]: 'product_type_ph',
  [PRODUCT_TYPE.TAXI]: 'product_type_taxi',
  [PRODUCT_TYPE.ANYTADA]: 'product_type_anytada',
  [PRODUCT_TYPE.EV]: 'product_type_ev'
}

export const RIDE_OPTION_TYPE = {
  SMART_CALL: 0,
  CAR_SEAT: 1,
  BOOSTER_SEAT: 2,
  CHANGE_OF_DESTINATION: 3,
  FUEL_SURCHARGE: 4,
  WAITING_FEE: 5,
  AIRPORT_FEE: 6,
  PET_CARRIER: 7,
  PET_LEASHED: 8,
  WHEELCHAIR_SPACE: 9,
  ETC: 999
}

export const RIDE_OPTION_STRING = {
  [RIDE_OPTION_TYPE.PET_CARRIER]: 'ride_option_pet_carrier',
  [RIDE_OPTION_TYPE.PET_LEASHED]: 'ride_option_pet_leashed'
}
