import React from 'react'
import { withTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import styled from 'styled-components'
import {
  HELP_CENTER_DETAIL_FOOTER_HEIGHT,
  HELP_CENTER_DETAIL_REPORT_HEIGHT,
  MOBILE_NAVIGATOR_HEIGHT
} from '../../../constants/constants'

import MobileWebViewContainer from '../../Common/MobileWebViewContainer'
import { sendExitHelpCenterToApp, useQuestionList } from './hooks'
import HelpCenterDetailFooter from './parts/HelpCenterDetailFooter'
import HelpCenterDetailReport from './parts/HelpCenterDetailReport'
import HelpCenterQuestionRow from './parts/HelpCenterQuestionRow'

const HelpCenterDetailBody = styled.div`
  padding: 16px 24px;
  padding-bottom: 0;
  height: calc(100% - ${MOBILE_NAVIGATOR_HEIGHT}px - 33px - ${({ footerHeight }) => footerHeight}px);
  overflow-y: scroll;
  box-sizing: border-box;
`

function HelpCenterDetail() {
  const { topic } = useLocation().state

  const { isFetching: isFetchReport } = useSelector(state => state.helpCenter)
  const isStaticTopic = useSelector(state => state.helpCenter.isStaticTopic)

  const { questionList, isFetching } = useQuestionList(topic)

  return (
    <MobileWebViewContainer
      title={topic.contents[0].topicName}
      headerSize={33}
      isLoading={isFetching || isFetchReport}
      onBack={isStaticTopic && sendExitHelpCenterToApp}
    >
      <HelpCenterDetailBody
        footerHeight={topic.allowReportDirectly ? HELP_CENTER_DETAIL_REPORT_HEIGHT : HELP_CENTER_DETAIL_FOOTER_HEIGHT}
      >
        {questionList.map(question => (
          <HelpCenterQuestionRow key={question.id} question={question} />
        ))}
      </HelpCenterDetailBody>
      {topic.allowReportDirectly ? <HelpCenterDetailReport topic={topic} /> : <HelpCenterDetailFooter topic={topic} />}
    </MobileWebViewContainer>
  )
}

export default withTranslation()(HelpCenterDetail)
