import React, { useState } from 'react'
import { withTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { useLocation } from 'react-router'
import styled from 'styled-components'
import { HELP_CENTER_REPORT_MIN_LENGTH, MOBILE_NAVIGATOR_HEIGHT } from '../../../constants/constants'
import { fetchReportRequest, fetchReportSuccess } from '../../../modules/helpCenter/reducer'
import { useActionSubscribe } from '../../../store/middleware/actionSubscribe'
import Button from '../../Common/Button'
import MobileModal from '../../Common/MobileModal'
import MobileWebViewContainer from '../../Common/MobileWebViewContainer'
import { sanitizeReportParam, sendExitHelpCenterToApp } from './hooks'

const HelpCenterReportBody = styled.div`
  padding: 20px 24px;
  height: calc(100% - ${MOBILE_NAVIGATOR_HEIGHT}px - 33px - 80px);
  box-sizing: border-box;

  overflow-y: scroll;

  > p {
    margin: 0;
  }

  > .help_center__report__label {
    font-size: 14px;
    color: #747678;
    margin-bottom: 8px;
  }

  > .help_center__report__input {
    &::placeholder {
      color: rgba(12, 12, 12, 0.3);
    }

    padding: 12px 16px;
    margin-bottom: 8px;

    width: 100%;
    height: 284px;
    resize: none;

    border: 1px solid #dfe1e3;
    border-radius: 6px;

    box-sizing: border-box;

    font-size: 14px;
  }

  > .help_center__report__length {
    text-align: right;
    font-size: 12px;
    color: #747678;
  }
`

const HelpCenterReportFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;

  height: 80px;

  > button {
    width: 157.5px;
    height: 48px;

    border-radius: 6px;

    color: #000;

    box-sizing: border-box;
  }

  > .help_center_report__button_cancel {
    border: 1px solid #dfe1e3;
  }

  > .help_center_report__button_submit {
    background: linear-gradient(135deg, #ffd74f 0%, #ffc400 100%);
    ${({ submitDisabled }) => submitDisabled && 'opacity: 45%'};
  }
`

function HelpCenterReport ({ t }) {
  const dispatch = useDispatch()

  const { reportInfo, isFetching } = useSelector(state => state.helpCenter)

  const { topic } = useLocation().state

  const [description, setDescription] = useState('')

  const [modalsOpen, setModalsOpen] = useState({
    cancelModal: false,
    submitModal: false
  })

  const toggleModal = name => {
    const key = name + 'Modal'

    setModalsOpen({
      ...modalsOpen,
      [key]: !modalsOpen[key]
    })
  }

  const handleClickSubmit = () => {
    dispatch(
      fetchReportRequest({
        description,
        topicId: topic.id,
        ...sanitizeReportParam(reportInfo)
      })
    )
  }

  const handleClickOk = name => {
    toggleModal(name)
    sendExitHelpCenterToApp()
  }

  useActionSubscribe(fetchReportSuccess.type, () => {
    toggleModal('submit')
  })

  return (
    <MobileWebViewContainer headerSize={33} title={topic.contents[0].topicName} isLoading={isFetching}>
      <MobileModal
        isOpen={modalsOpen.cancelModal}
        title={t('report.title_cancel_modal')}
        content={t('report.content_cancel_modal')}
        cancelText={t('report.button_cancel_no')}
        okText={t('button_cancel')}
        toggle={() => toggleModal('cancel')}
        onOk={() => handleClickOk('cancel')}
      />
      <MobileModal
        isOpen={modalsOpen.submitModal}
        isCancelVisible={false}
        title={t('report.title_submit_modal')}
        content={t('report.content_submit_modal')}
        toggle={() => toggleModal('submit')}
        onOk={() => handleClickOk('submit')}
      />
      <HelpCenterReportBody>
        <p className='help_center__report__label'>{t('report.label_report_an_issue')}</p>
        <textarea
          className='help_center__report__input'
          placeholder={t('report.label_describe_issue')}
          value={description}
          onChange={e => {
            if (e.target.value.length > 500) {
              return
            }
            setDescription(e.target.value)
          }}
        />
        <p className='help_center__report__length'>
          {description.length} / 500 (
          {t('report.label_minimum_character', { minLength: HELP_CENTER_REPORT_MIN_LENGTH })})
        </p>
      </HelpCenterReportBody>
      <HelpCenterReportFooter submitDisabled={description.length < HELP_CENTER_REPORT_MIN_LENGTH}>
        <Button className='help_center_report__button_cancel' onClick={() => toggleModal('cancel')}>
          {t('button_cancel')}
        </Button>
        <Button
          className='help_center_report__button_submit'
          disabled={description.length < HELP_CENTER_REPORT_MIN_LENGTH}
          onClick={handleClickSubmit}
        >
          {t('signup.submit')}
        </Button>
      </HelpCenterReportFooter>
    </MobileWebViewContainer>
  )
}

export default withTranslation()(HelpCenterReport)
