// !!! Do not edit this file directly.
// !!! 이 파일을 직접 수정하지 말고 sheet 수정 후 script 실행할 것
export default {
  translations: {
    desc_mdt_date: 'Ngày gần nhất (vd: ảnh chụp là được chụp gần nhất)',
    btn_save_next: 'Lưu và tiếp theo',
    repeat_password: 'Nhập lại mật khẩu',
    phone_number: 'Số điện thoại',
    digit_pin_number: 'Số PIN gồm 4 chữ số',
    refferal_code: 'Mã giới thiệu',
    singapore: 'Singapore',
    country: 'Quốc gia',
    signup: {
      introduce1:
        'Chào mừng bạn đến với TADA Global! Để đăng kí trở thành Đối Tác Tài Xế của TADA, xin vui lòng cung cấp các thông tin và làm theo hướng dẫn phía bên dưới. Đơn đăng ký của bạn sẽ chỉ được xác nhận là hoàn tất khi cung cấp đủ các thông tin như yêu cầu. Nếu có bất kì khó khăn nào, vui lòng liên hệ với chúng tôi qua địa chỉ email ',
      email: 'hotro@tada.global',
      introduce2: ',TADA sẽ phản hồi bạn trong thời gian sớm nhất. Lưu ý rằng hồ sơ có thể được tải lên dưới dạng hình ảnh hoặc file pdf.',
      submit: 'Đăng ký',
      login_here: 'Đăng nhập ở đây',
      refferal_code: 'Mã giới thiệu (không bắt buộc)',
      pin_number: 'Số mã PIN',
      mobile_number: 'Số điện thoại',
      agree: 'Tôi đồng ý',
      my_information: 'Thông tin của tôi',
    },
    driver: {
      available_after_complete: 'Hiển thị sau khi hoàn thành',
      filled_out_zero: 'Bạn đã điền 0/{{totalSteps}} mục thông tin',
      my_page: 'Trang của tôi',
      application: 'Đăng ký Tài xế',
      application_taxi: 'Ứng dụng tài xế taxi',
      application_tuktuk: 'Ứng dụng trình điều khiển TukTuk',
      application_bike: 'Ứng dụng điều khiển xe đạp',
      application_not_complete: 'Hồ sơ đăng ký của bạn chưa hoàn tất',
      filled_out: 'Bạn đã điền {{doneSteps}}/{{totalSteps}} mục thông tin',
      being_reviewed: 'Hồ sơ đăng ký đang được xét duyệt',
      information: 'Thông tin Cá nhân',
      name: 'Tên',
      mobile_number: 'Số điện thoại di động',
      refferal_code: 'Mã giới thiệu của tôi',
      logout: 'Thoát',
      application_complete: 'Đăng kí của bạn đã hoàn thành. Chúng tôi đã gửi xác nhận tới email của bạn.  ',
      application_not_succesful:
        'Đăng kí của bạn không thành công. Vui lòng kiểm tra email của bạn và gửi lại các giấy tờ cần bổ sung như thông tin chúng tôi đã cung cấp qua email. ',
    },
    judicial_record: 'Lý lịch tư pháp',
    vehicle_registration: 'Giấy đăng ký xe',
    tuktuk_registration_card: 'Thẻ đăng ký Tuk Tuk',
    certificate_of_registry: 'Giấy chứng nhận đăng ký\n',
    commercial_insurance_certificate: 'Bảo hiểm trách nhiệm dân sự loại kinh doanh',
    contract_car_badge: 'Phù hiệu xe hợp đồng',
    national_id: 'Chứng minh nhân dân',
    identity_card_number: 'Số chứng minh nhân dân\n',
    license_plate_number: 'Biển số xe',
    car_make: 'Hãng xe',
    car_make_ex: 'Hãng xe (VD: BMW)',
    car_model_ex: 'Dòng xe (VD: QM3)',
    bike_make: 'Nhà sản xuất',
    bike_model: 'Dòng xe',
    desc_sharp_clear_photo: 'Cần rõ nét, khong bị nhòe',
    valid_driving_license: 'Bằng lái tải lên phải hợp lệ (bằng lái không hết hạn)',
    valid_document: 'Hồ sơ tải lên phải hợp lệ (hồ sơ không hết hạn) ',
    valid_nric: 'NRIC hợp lệ phải được tải lên (tức là chưa hết hạn).',
    valid_registration_card: 'Thẻ đăng ký hợp lệ phải được tải lên (tức là chưa hết hạn)\n',
    waring_acra_form: 'Tải lên 3 trang đầu tiên của tài liệu cho thấy bạn tự làm chủ và có một công ty đã đăng ký cho mục đích thuế thu nhập.',
    waring_bike_form: 'Biển số phải phù hợp với tất cả các tài liệu',
    acra_form_skip: 'Bước này không bắt buộc đối với những người có kế hoạch vận hành với những chiếc xe thuê.',
    upload_the_backside: 'Tải lên mặt sau chứa thông tin của xe ',
    gps_tracking_device: 'Loại kinh doanh, có gắn thiết bị giám sát hành trình',
    license_plate: 'Biển số xe trong bảo hiểm trách nghiệm dân sự phải khớp với số trong giấy đăng ký xe và đăng kiểm xe',
    national_id_uploaded: 'CMND tải lên phải hợp lệ (không hết hạn)',
    upload_driving_license_front: 'Tải lên hình chụp bằng lái (mặt trước)',
    upload_driving_license_back: 'Tải lên hình chụp bằng lái (mặt sau)',
    upload_judicial_record: 'Tải lên lý lịch tư pháp (mặt trước) ',
    upload_vehicle_registration: 'Tải lên Đăng ký xe (trở lại)\n',
    upload_certificate_of_registry: 'Tải lên Chứng chỉ đăng ký (Quay lại)\n',
    upload_insurance: 'Tải lên bảo hiểm xe (mặt trước) ',
    upload_insurance_certificate: 'Tải lên chứng nhận bảo hiểm (Quay lại)\n',
    upload_car_contract_badge: 'Tải lên phù hiệu xe hợp đồng (mặt trước) ',
    upload_national_id: 'Tải lên CMND (mặt trước) ',
    upload_identity_card_number_front: 'Tải lên Số Chứng minh nhân dân (phía trước)\n',
    upload_identity_card_number_back: 'Tải lên số Chứng minh nhân dân (trở lại)',
    upload_bike_picture_front: 'Tải lên hình ảnh xe đạp tổng thể',
    upload_bike_picture_back: 'Tải lên một hình ảnh xe đạp khác cho thấy ít nhất một nửa chiếc xe đạp với biển số',
    uploaded_profile_picture: 'Tải ảnh đại diện',
    upload_tuktuk_registration_card: 'Tải lên Thẻ đăng ký Tuk Tuk (Mặt trước)\n',
    upload_profile_picture:
      'Nếu bạn bỏ qua bước này, bạn sẽ được yêu cầu tải lên ảnh đại diện một lần nữa trước khi bạn có thể bắt đầu nhận các chuyến xe. ',
    no_wearing_of_accessories:
      'Vui lòng không đeo các trang sức, phụ kiện (ví dụ: mũ, kính râm, vv...) có thể che các đặc điểm nhận dạng khuôn mặt của bạn. ',
    please_white_background: 'Ảnh đại diện phải là ảnh chụp trên nền trắng',
    please_upload_photo: 'Ảnh chụp tải lên phải rõ nét ',
    reset_password_success: 'Mật khẩu tài khoản của bạn ({{email}})đã được thay đổi an toàn.',
    change_password: 'Thay đổi mật khẩu',
    forgot_password: 'Tìm mật khẩu',
    reset_password: 'Khởi động lại mật khẩu',
    error_password_length: 'Mật khẩu phải từ 6-20 ký tự',
    faq: 'Các câu hỏi thường gặp',
    login: 'Đăng nhập',
    login_required: 'Đã yêu cầu đăng nhập',
    logout: 'Đăng xuất',
    sign_up: 'Đăng ký',
    taxi_sign_up: 'Đăng ký Đối Tác Tài Xế TADA ',
    my_page: 'Trang của tôi',
    email: 'Email  ',
    email_placeholder: 'Nhập địa chỉ email của bạn.',
    email_used: 'Email {{email}} đã sử dụng rồi.',
    password: 'Mật khẩu',
    password_placeholder: 'Nhập mật khẩu của bạn.',
    password_confirm: 'Xác nhận mật khẩu của bạn.',
    password_confirm_placeholder: 'Nhập lại mật khẩu lần nữa.',
    new_password_placeholder: 'Nhập mật khẩu mới',
    verify_mail_title: 'Kiểm tra email xác nhận',
    verify_mail_desc: 'Kiểm tra email xác nhận trong hộp thư đến. ({{email}})',
    privacy_policy: 'Chính sách bảo mật',
    terms_and_conditions: 'Điều khoản và điều kiện',
    button_confirm: 'Xác nhận ',
    button_cancel: 'Hủy',
    locale: {
      en: 'English',
      ko: '한국어',
      zh_hans: '简体中文',
      zh_hant: '繁體中文',
      vi: 'Tiếng Việt',
      km: 'ភាសាខ្មែរ',
      th: 'ภาษาไทย',
      'zh-CN': '简体中文',
      'zh-TW': '繁體中文',
      jp: '日本語',
    },
    btn_save: 'Lưu',
    first_name: 'Tên ',
    last_name: 'Họ',
    phone_placeholder: 'Số điện thoại',
    wrong_number_request: '{{driverPhone}} là số sai. Vui lòng nhập một số khác.\n',
    photo_file_format: '.jpg, .png',
    delete_image: 'Xóa hình',
    upload_image: 'Tải hình lên',
    agree_policy_please: 'Đồng ý với tất cả các điều khoản và điều kiện.',
    button_resend_email_verify: 'Gửi lại email xác minh.',
    success_resend_email_verify: 'Email xác mình đã được gửi.',
    optional_brackets: '(Không bắt buộc)',
    error_time_out: 'Quá hạn thời gian kết nối với hệ thống. Vui lòng thử lại.',
    error_captcha_not_valid: 'ReCaptcha không hợp lệ',
    car_model: 'Dòng xe',
    plate_number: 'Biển số xe',
    banking_details: 'Thông tin tài khoản ngân hàng',
    bank_account_holder_name: 'Tên chủ tài khoản ngân hàng',
    bank_select_name: 'Tên ngân hàng',
    bank_account_number: 'Số tài khoản ngân hàng',
    bank_branch: 'Chi nhánh ngân hàng',
    bank_skip_warn:
      'Nếu bạn bỏ qua bước này, bạn sẽ được yêu cầu cung cấp lại thông tin tài khoản ngân hàng của bạn khi bạn bắt đầu nhận các chuyến đặt xe và muốn rút ra tiền thu nhập của mình ',
    bank_photo_warn:
      'Vui lòng tải lên hình ảnh thông tin tài khoản ngân hàng (từ biên lai ATM hoặc sao kê ngân hàng) hiển thị tên tài khoản và số tài khoản ngân hàng\n',
    profile_picture: 'Ảnh đại diện',
    driving_license: 'Bằng lái xe',
    insurance_certificate: 'Giấy chứng nhận bảo hiểm',
    error_password_confirm: 'Mật khẩu không khớp',
    phone_used: 'Số điện thoại đã được sử dụng',
    send_pin: 'Gửi PIN',
    edit: 'Chỉnh sửa',
    tada: {
      home: '<p>Chào mừng đến với kỷ nguyên mới của dịch vụ gọi xe. Chào mừng đến với TADA.<br></p>\n<p>Bước vào một thế giới mới với TADA - nơi dịch vụ gọi xe được định nghĩa lại.<br></p>\n<p>Là nơi các Tài xế được phát triển mạnh mà không phải trả phí hoa hồng.<br></p>\n<p>Là nơi Hành khách được hưởng lợi từ mức giá minh bạch, công bằng, phải chăng cho những chuyến đi an toàn, mọi lúc mọi nơi.<br></p>\n<p>Bắt đầu một hành trình mang tính chuyển đổi - nơi sự tiện lợi kết hợp với sự công bằng, độ tin cậy và sự tin tưởng để định nghĩa lại những chuyến hành trình của bạn.</p>',
      driver: {
        app: {
          name_1: 'Tìm chuyến',
          name_2: 'Đón khách',
          name_3: 'Chạy xe',
          name_4: 'Trả khách & Thanh toán',
          waiting:
            'Phiên bản IOS dành cho Đối Tác Tài Xế TADA đang được cập nhật. \nChúng tôi sẽ thông báo tới bạn khi ứng dụng sẵn sàng để tải về!  ',
        },
        info: {
          title_1: 'Đầu tiên và duy nhất cam kết \n0% chiết khấu từ thu nhập Đối tác tài xế',
          content_1:
            'TADA không thu chiết khấu. \nNgoại trừ phí nền tảng và phí giao dịch cho thẻ trả trước/ tín dụng, \ntất cả lợi nhuận đều thuộc về Đối tác Tài xế. \nVà chúng tôi sẽ tiếp tục giữ vững lời hứa này. ',
          title_2: 'Dễ dàng \nĐăng ký trở thành Đối Tác Tài Xế ',
          content_2: 'Chúng tôi đã đơn giản hóa quy trình đăng kí \nđể thuận tiện hơn cho bạn.',
          title_3: 'Hệ thống \nkết nối nâng cao',
          content_3:
            'TADA phân tích mô hình dịch vụ của các tài xế \ndựa trên cơ sở dữ liệu lớn và cung cấp hệ thống \nkết nối được cá nhân hóa để nhiều hành khách \ncó thể được kết nối với một tài xế.',
          title_4: 'Dịch vụ \nđón khách liên tục',
          content_4:
            'Bạn có thể nhận được yêu cầu đặt xe mới khi \nHành khách còn đang trên xe nếu như bạn đã gần điểm đến. \nBạn có thể nhận chuyến xe liên tục mà không cần chờ đợi.',
          title_5: 'Dễ dàng truy cập \nThông tin vận hành',
          content_5:
            'Chúng tôi cung cấp tính năng bảng điều khiển \ncho phép bạn có thể kiểm tra thông tin chuyến đi như: \nLịch sử hoạt động hàng ngày/ hàng tuần, Thu nhập hiện tại, và nhiều thông tin khác.',
          title_6: 'Quà tặng và \nTiền thưởng',
          content_6: 'Chúng tôi cung cấp các chương trình chạy thưởng\nđể nâng cao thu nhập của bạn.',
        },
        driverappdownload: 'Tải ứng dụng dành cho tài xế',
      },
      rider: {
        app: {
          name_1: 'Yêu cầu chuyến đi',
          name_2: 'Tìm chuyến',
          name_3: 'Chuyến đi',
          name_4: 'Thanh toán & Phản hồi',
        },
        info: {
          title_1: 'Chuyến xe nhanh hơn\ncho Hành khách',
          content_1:
            'Chúng tôi kết nối bạn với Tài xế \nnhanh chóng dựa trên \ncông nghệ kết nối hiệu quả.\nChúng tôi cũng sẽ giúp bạn tìm kiếm 01 Tài xế có thể đến sớm nhất\n đảm bảo cho 01 chuyến xe thuận lợi và an toàn.',
          title_2: 'Cước phí\nphải chăng và tốt hơn',
          content_2: 'Chuyến đi đáng tin cậy và "hợp túi tiền"\nvới chính sách 0% chiết khấu của chúng tôi.',
          title_3: 'Kết nối\ntrong giờ cao điểm',
          content_3: 'Chúng tôi giúp bạn kết nối với Tài xế \nkể cả trong giờ cao điểm, dù mưa hay nắng.',
          title_4: 'Khu vực \nphục vụ rộng lớn',
          content_4: 'Bất cứ nơi nào bạn muốn đến, chúng tôi sẽ kết nối bạn \nvới một Tài xế đáng tin cậy',
          title_5: 'Thanh toán nhanh chóng\nvà dễ dàng',
          content_5:
            'Bạn có thể chọn thanh toán bằng tiền mặt, hoặc thẻ trả trước/tín dụng.\nChọn liên kết Thẻ giúp cho việc thanh toán\nnhanh chóng và tiện lợi.',
          title_6: 'Quản lý\nĐánh giá cẩn thận',
          content_6:
            'Bạn có thể để lại đánh giá của mình dành cho Tài xế.\nĐánh giá của bạn là tiền đề cho chúng tôi sàng lọc\n những đối tác tài xế đáng tin cậy.',
        },
        riderappdownload: 'Tải ứng dụng cho hành khách',
      },
      contact: {
        contact_us: 'Liên hệ với chúng tôi',
        name: {
          label: 'Tên, hoặc biệt danh (bắt buộc)',
          placeholder: '(30 kí tự)',
        },
        email: {
          label: 'Địa chỉ email của bạn (bắt buộc)',
          example: '<ví dụ : mvler@mvlchain.io)',
          placeholder: {
            name: 'mvler',
            domain: 'mvlchain.io',
          },
        },
        title: {
          label: 'Tiêu đề ( Bắt buộc)',
          placeholder: '(30 kí tự)',
        },
        content: {
          label: 'Miêu tả (bắt buộc)',
          placeholder:
            "[Chú ý] \\n\\n-tối đa 2000 kí tự \\n-Vui lòng miêu tả chi tiết\\n-Vui lòng hạn chế từ ngữ tế nhị\\n-Vui lòng hạn chế đăng tải những thông cá nhân nhạy cảm(ID, mặt khẩu, thông tin liên lạc etc.)\\n-Nếu chúng tôi cần thêm thông tin, chúng tôi sẽ liên lạc với bạn \\n-Bằng việc nhấn nút 'Gửi' dưới đây, bạn đồng ý và chấp thuận cung cấp thông tin về địa điểm, IP, OS, etc.",
        },
        submit: {
          request: {
            submit: 'Gửi',
            title: 'Bạn có chắc muốn gửi không?',
            desc: '(địa chỉ email:',
            review: 'Đánh giá',
          },
          confirm: {
            title: 'Đăng kí hoàn tất',
            desc: 'Chúng tôi sẽ phản hồi lại yêu cầu của bạn sau khi xem xét.',
            confirm: 'Xác nhận',
          },
        },
      },
    },
    title: {
      about: 'Về chúng tôi',
      driver: 'Tài xế',
      rider: 'Hành khách',
      contact: 'Liên hệ',
      faq: 'FAQ',
      powerd_by_mvl: '<span>Powered by</span> MVL',
      terms: 'Điều khoản',
      privacy: 'Quyền riêng tư',
      code_of_contact: 'Quy tắc ứng xử của người lái xe\r\n',
    },
    faq_driver_app: 'Tài xế',
    faq_rider_app: 'Hành khách',
    title_otp_request: 'Để tiếp tục, vui lòng đăng nhập vào tài khoản của bạn để xác minh.',
    label_mobile_number: 'Số điện thoại',
    label_code_number: 'Mã CODE',
    btn_send: 'Gửi',
    btn_continue: 'Tiếp tục',
    error_verification_code: 'Mã xác nhận không chính xác',
    label_account_deletion: 'Xóa tài khoản của bạn',
    label_reason_delete_account: 'Lí do xoá tài khoản',
    hint_min_character: 'ít nhất {{count}} ký tự',
    btn_submit: 'Đăng ký',
    dialog_account_deletion_desc: 'Bạn sẽ không thể sử dụng dịch vụ TADA kể từ bây giờ.',
    apply_account_deletion_title: 'Chúng tôi đang trong quá trình xóa tài khoản của bạn.',
    apply_account_deletion_desc: 'Có thể mất vài ngày để hoàn tất quá trình xóa tài khoản.',
    dialog_account_deletion_failure_title: 'Không xóa được tài khoản của bạn',
    content_delete_account_error_balance_remaining:
      'Số dư phải bằng 0 để xóa tài khoản. Vui lòng thử lại sau khi rút số tiền còn lại hoặc gửi email đến trung tâm Hỗ trợ để được trợ giúp.',
    content_delete_account_error_exist_rider_penalty_fee:
      'Không thể xóa tài khoản của bạn do tài khoản của bạn đang bị phạt. Vui lòng liên hệ với Đội CSKH của chúng tôi qua email để được hỗ trợ.',
    content_delete_account_error_exist_active_ride:
      'Bạn đang trong chuyến đi, quy trình xóa tài khoản của bạn chỉ có thể được xử lý sau khi hoàn tất hoặc hủy đặt chỗ hiện tại.',
    label_cs_center: 'CSKH',
    label_notice: 'Lưu ý',
    label_get_rewarded: 'NHẬN THƯỞNG',
    label_for_me: 'Cho tôi',
    label_for_my_friend: 'Cho bạn của tôi',
    label_invite_rider: 'Mời thêm Hành khách và nhận thưởng',
    text_contact_description:
      'Hoạt động ở các quốc gia Đông Nam Á, văn phòng chúng tôi ở Singapore, Campuchia, Thái Lan, Việt Nam và Hongkong đã sẵn sàng để hỗ trợ bạn. Hãy kết nối với chúng tôi từ bất kỳ nơi nào cũng những quốc gia này.',
    text_contact_empty_description: 'Vui lòng chọn nào điểm của một trong những văn phòng này trên bàn đồ để xem chi tiết.',
    report: {
      label_report_issue: 'Báo cáo sự cố',
      title_report_issue: 'Sự cố của bạn đã được giải quyết chưa?',
      content_report_issue: 'Để được hỗ trợ thêm, vui lòng nhấn vào "Báo cáo sự cố" "để chia sẻ thêm thông tin.',
      label_report_an_issue: 'Báo cáo sự cố',
      label_describe_issue: 'Mô tả sự cố của bạn',
      label_minimum_character: 'Tối thiểu {{minLength}} kí tự',
      title_cancel_modal: 'Huỷ báo cáo',
      content_cancel_modal: 'Báo cáo của bạn sẽ không được lưu. Bạn có muốn rời trang?',
      button_cancel_no: 'Tiếp tục',
      title_submit_modal: 'Cảm ơn về phần hồi của bạn',
      content_submit_modal: 'Chúng tôi có thể sẽ liên lạc cho bạn để làm rõ thông tin',
      button_ok: 'OK',
    },
    car_type_economy: 'TADA 4',
    car_type_economy_large: 'TADA 7',
    car_type_premium: 'Premium',
    car_type_premium_large: 'Premium Large',
    car_type_taxi: 'TADA Taxi',
    car_type_taxi_large: 'Standard Large Taxi',
    car_type_premium_taxi: 'Premium Taxi',
    car_type_premium_taxi_large: 'Premium Large Taxi',
    car_type_tuktuk: 'Tuk Tuk',
    car_type_bike: 'TADA Bike',
    car_type_remorque: 'Xe lam',
    car_type_delivery_van: 'Xe giao hàng',
    car_type_onion_tuktuk: 'E-Tuk Tuk',
    car_type_delivery_bike: 'Delivery Bike',
    product_type_ph: 'Xe riêng',
    product_type_taxi: 'Taxi',
    product_type_anytada: 'AnyTADA',
    product_seat_count: 'Tối đa {{seat}} Chỗ ngồi',
    car_type_bus: 'Xe buýt',
    car_type_mini_bus: 'Xe buýt mini',
    ride_option_pet_carrier: 'TADA Pets (có lồng)',
    ride_option_pet_leashed: 'TADA Pets (có xích)',
    fare_type_app_metered_fare: 'App Metered',
    fare_type_car_metered_fare: 'Car Metered',
    fare_type_fixed_fare: 'Fixed',
    car_type_win_bike: 'Win Bike',
    car_type_premium_bike: 'TADA Bike Plus',
    car_junior_type: 'TADA Junior',
    car_junior_large_type: 'TADA Junior Large',
    car_wheelchair_space_type: 'Phù hợp cho xe lăn',
    car_type_tada_bike: 'TADA Bike',
    text_live_ride_expired_content: 'Đường dẫn này đã hết hạn hoặc Chuyến xe đã kết thúc.',
    text_live_ride_expired_sub_content: 'Vui lòng kiểm tra với hành khách xem liên kết có chính xác không',
    text_try_tada: 'Bạn đã thử TADA chưa?',
    text_book_first_trip: 'Đặt chuyến đi đầu tiên ngay!',
    text_download_tada_ride: 'Đi cùng TADA, tải xuống ngay!',
    label_install: 'Tải app',
    text_message_pickup_waiting: '{{riderName}} đang chờ để được đón',
    text_message_pickup_arrived: 'Tài xế đã đến điểm đón',
    text_message_inuse: '{{riderName}} sẽ đến trong ~{{time}} phút',
    text_message_finished: '{{riderName}} sẽ đến lúc {{time}}!',
    text_message_cancelled: 'Chuyến đi đã bị huỷ',
    text_message_na_inuse: '{{riderName}} đang trên đường.',
    text_message_na_finished: '{{riderName}} đã đến',
    label_na: 'N/A',
    text_message_cod: '{{riderName}} đã thay đổi điểm đến và sẽ đến trong ~{{time}} phút nữa.',
  },
};
