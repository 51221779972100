import { useEffect, useRef, useState } from 'react';

import { useDispatch } from 'react-redux';
import Slider from 'react-slick';
import styled from 'styled-components';

import '@/components/Pages/RideProduct/index.css';
import MobileWebViewContainer from '@/components/Common/MobileWebViewContainer';
import Paginator from '@/components/Pages/RideProduct/Paginator';
import RideProductItem from '@/components/Pages/RideProduct/RideProductItem';
import { getInformationList } from '@/components/Pages/RideProduct/utils';
import { useSearch } from '@/hooks/router';
import { LANGS } from '@/i18n';
import { DriverActions } from '@/modules/driver/DriverState';

const RideProductBody = styled.div`
  box-sizing: border-box;
  height: 100vh;
  padding-bottom: 56px;
  overflow: hidden;

  & * {
    box-sizing: border-box;
  }
  .tab-carousel {
    height: calc(100vh - 56px);
  }
  .car_info_wrap {
    height: calc(100vh - 56px);
    overflow-y: scroll;
    width: 100vw;
  }

  .ride_product__car_info {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .ride_product__car_info > .ride_product__car_info_image {
    margin-top: 16px;
    width: 88px;
  }

  .ride_product__car_info > .ride_product__car_info_marketing_image {
    width: 100%;
  }

  .ride_product__car_info > .ride_product__car_info_car_type {
    margin: 0;
    margin-top: 16px;
    font-size: 20px;
  }

  .ride_product__car_info > .ride_product__car_info_person {
    margin: 0;
    margin-top: 4px;
    font-size: 12px;
    color: #747678;
  }

  .ride_product__car_info > .ride_product__car_info_category {
    display: flex;
    align-items: flex-start;
    margin-top: 32px;
    padding: 0px 8px;
    border-radius: 100px;
    background: #0c0c0c80;
    color: #fff;
    font-size: 12px;
    font-weight: 700;
    line-height: 16px;
  }

  .ride_product__description {
    margin-top: 24px;
    padding: 0 16px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    width: 100%;
    overflow: hidden;
  }

  .ride_product__paginator {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 56px;
    padding: 0 16px;
    box-shadow:
      0px 6px 24px 0px rgba(0, 0, 0, 0.2),
      0px 0px 8px 0px rgba(0, 0, 0, 0.1);
    background: #fff;
  }

  .ride_product__paginator > .ride_product__paginator_button {
    background: none;
    border: none;
    width: 24px;
    height: 24px;
    padding: 0;
  }

  .ride_product__paginator > .ride_product__paginator_button.hidden {
    visibility: hidden;
  }

  .ride_product__paginator > .ride_product__paginator_button > img {
    width: 100%;
    height: 100%;
  }
`;

function RideProduct() {
  const dispatch = useDispatch();

  const search = useSearch();
  const slideRef = useRef(null);
  const [currentIndex, setCurrentIndex] = useState(null);
  const [items, setItems] = useState([]);
  const [isInitialized, setIsInitialized] = useState(false);

  const handleAfterChange = (index) => {
    setCurrentIndex(index);
  };

  useEffect(() => {
    (async () => {
      try {
        const { codes, targetCode, ids, targetId, language = 'en' } = search;

        const codeArray = codes ? JSON.parse(codes) : null;
        const idArray = ids ? JSON.parse(ids) : null;

        const itemList = await getInformationList(codeArray, idArray, language);

        const index = itemList.findIndex((item) => item.productCode === targetCode || item.productId === targetId);
        const currentIndex = index === -1 ? 0 : index;

        setItems(itemList);
        setCurrentIndex(currentIndex);
      } catch (e) {}
    })();
  }, [search]);

  useEffect(() => {
    const { language } = search;
    dispatch(DriverActions.changeLocaleRequest(LANGS.includes(language) ? language : 'en'));
  }, [search.language]);

  useEffect(() => {
    // currentIndex가 null이거나 slideRef 초기세팅이 된 상태면 return
    if (currentIndex === null || isInitialized) {
      return;
    }

    const checkSlideRef = () => {
      if (slideRef.current) {
        slideRef.current.slickGoTo(currentIndex);
        setIsInitialized(true);
        return;
      }

      requestAnimationFrame(checkSlideRef); // slideRef가 설정될 때까지 반복
    };

    requestAnimationFrame(checkSlideRef);
  }, [currentIndex]);

  if (!items.length || currentIndex === null) {
    return null;
  }

  return (
    <MobileWebViewContainer isNavigator={false} headerSize={0} isLoading={false}>
      <RideProductBody>
        <Slider
          dots={false}
          infinite={false}
          arrows={false}
          accessibility={false}
          centerPadding={0}
          className='tab-carousel'
          initialSlide={currentIndex}
          afterChange={handleAfterChange}
          ref={slideRef}
        >
          {items.map((item, index) => (
            <RideProductItem item={item} key={item.id} index={index} currentIndex={currentIndex} />
          ))}
        </Slider>
        <Paginator slideRef={slideRef} currentIndex={currentIndex} items={items} />
      </RideProductBody>
    </MobileWebViewContainer>
  );
}

export default RideProduct;
