import React, { useState } from 'react'
import styled from 'styled-components'
import { compose } from 'recompose'
import { withTranslation } from 'react-i18next'
import triangle from '../../../assets/images/tada/triangle.png'
import { useSearch } from '../../../hooks/router'
import { REGION } from '../../../constants/constants'
import { getRegionFromLocale } from '../FaqPage/hooks'

const DriverTable = styled.table`
  width: 100%;
  height: 60px;
  margin-top: 50px;
  z-index: 1;
`

const DAtd = styled.td`
  padding: 15px;
  border-bottom: ${props => (props.totalCount - 1 === props.index ? '1px solid black' : 'none')};
  font-size: 16px;
  word-break: break-all;
  @media (max-width: 1000px) {
    font-size: 15px;
  }

  > div > ul {
    margin-left: 20px;

    > li > ul {
      li {
        margin-left: 20px;
      }
    }
  }
`

const Dtr = styled.tr`
  width: 100%;
`

const DQtd = styled.td`
  height: 60px;
  cursor: pointer;
  padding-left: 16px;
  border-top: 1px solid black;
  border-bottom: ${props => (props.totalCount - 1 === props.index ? '1px solid black' : 'none')};
  font-size: 16px;
  word-break: break-all;
  position: relative;
  &:after,
  &::after {
    position: absolute;
    width: 19px;
    height: 11px;
    top: 50%;
    margin-top: -5.5px;
    right: 10px;
    content: ' ';
    object-fit: contain;
    background-image: url(${triangle});
  }
  &:hover {
    background-color: rgba(192, 192, 192, 0.1);
  }
  @media (max-width: 1000px) {
    font-size: 15px;
  }

  & > div {
    margin: 0 40px 0 0;
    & > div {
      margin: 0 40px 0 0;
    }
  }
`

const DQOpenedTd = styled.td`
  height: 60px;
  cursor: pointer;
  padding-left: 16px;
  position: relative;
  font-size: 16px;
  font-weight: bold;
  border-top: 1px solid black;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  margin: 0 20px 0 0;
  &:after,
  &::after {
    position: absolute;
    width: 19px;
    height: 11px;
    top: 50%;
    margin-top: -5.5px;
    right: 10px;
    content: ' ';
    object-fit: contain;
    background-image: url(${triangle});
    background-position: ${props => (props.isOpenedShow ? '-19px' : '0')} 0;
  }
  @media (max-width: 1000px) {
    font-size: 15px;
  }

  & > div {
    margin: 0 40px 0 0;
    & > div {
      margin: 0 40px 0 0;
    }
  }
`

function TabContent ({ faqTitle, t }) {
  const { region, language } = useSearch()

  const prefixList = faqTitle.map(prefix =>
    [region, getRegionFromLocale(language)].includes(REGION.TH) ? `th_${prefix}` : prefix
  )

  const [isOpen, setIsOpen] = useState(() =>
    Object.values(prefixList).reduce((acc, value) => {
      acc[value] = false
      return acc
    }, {})
  )

  return (
    <DriverTable>
      {prefixList.map((prefix, index) => (
        <tbody key={prefix}>
          <Dtr onClick={() => setIsOpen({ ...isOpen, [prefix]: !isOpen[prefix] })}>
            {isOpen[prefix] ? (
              <DQOpenedTd isOpenedShow={isOpen[prefix]}>
                <div dangerouslySetInnerHTML={{ __html: t(`${prefix}_question`) }} />
              </DQOpenedTd>
            ) : (
              <DQtd totalCount={prefixList.length} index={index}>
                <div dangerouslySetInnerHTML={{ __html: t(`${prefix}_question`) }} />
              </DQtd>
            )}
          </Dtr>
          {isOpen[prefix] && (
            <Dtr>
              <DAtd totalCount={prefixList.length} index={index}>
                <div dangerouslySetInnerHTML={{ __html: t(`${prefix}_answer`) }} />
              </DAtd>
            </Dtr>
          )}
        </tbody>
      ))}
    </DriverTable>
  )
}

export default compose(withTranslation())(TabContent)
