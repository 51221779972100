import { Polyline } from 'react-google-map-wrapper';

interface DottedPolylineProps {
  value: google.maps.LatLngLiteral[];
}

function DottedPolyline({ value }: DottedPolylineProps) {
  return (
    <>
      <Polyline
        path={value}
        strokeOpacity={0}
        icons={[
          {
            icon: {
              path: google.maps.SymbolPath.CIRCLE,
              strokeOpacity: 0.1,
              strokeColor: '#414345',
              fillColor: '#fff',
              fillOpacity: 1,
              strokeWeight: 2,
              scale: 8,
              anchor: new google.maps.Point(0.06, 0),
            },
            offset: '0',
            repeat: '20px',
          },
        ]}
      />
      <Polyline
        path={value}
        strokeOpacity={0}
        icons={[
          {
            icon: {
              path: google.maps.SymbolPath.CIRCLE,
              strokeOpacity: 1,
              fillColor: '#414345',
              strokeColor: '#414345',
              strokeWeight: 6,
              scale: 2,
            },
            offset: '0',
            repeat: '20px',
          },
        ]}
      />
    </>
  );
}

export default DottedPolyline;
