import { LOCALE_TYPE, SUPPORT_LANGUAGES } from '../constant/constants';

const normalizeLanguage = (language: string) => {
  if (['en', 'en-US', 'en-AU', 'en-CA', 'en-IN', 'en-IE', 'en-NZ', 'en-ZA', 'en-GB-oxendict', 'en-GB'].includes(language)) {
    return LOCALE_TYPE.EN;
  }

  if (['zh', 'zh-HK', 'zh-CN', 'zh-TW', 'zh-SG', 'zh-MO'].includes(language)) {
    return LOCALE_TYPE.ZH_HANS;
  }

  if (['vi', 'vn', 'vi-VN'].includes(language)) {
    return LOCALE_TYPE.VN;
  }

  if (['km', 'km-KH'].includes(language)) {
    return LOCALE_TYPE.KM;
  }

  if (['th', 'th-TH'].includes(language)) {
    return LOCALE_TYPE.TH;
  }

  return language;
};

export const getLanguageFromBrowser = () => {
  const mainLanguage = normalizeLanguage(navigator.language) as string;
  const languages = navigator.languages;

  if (SUPPORT_LANGUAGES.includes(mainLanguage)) {
    return mainLanguage;
  }

  const supportedLanguage = languages.find((lang) => SUPPORT_LANGUAGES.includes(normalizeLanguage(lang) as string));

  return supportedLanguage ?? LOCALE_TYPE.EN;
};
