import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { phoneSelector } from '../../../../modules/deleteAccount/selector'
import { useRegions } from '../hooks'
import DeleteAccountButton from './DeleteAccountButton'
import { withTranslation } from 'react-i18next'
import { REGION_BY_COUNTRY_CODE } from '../constants'
import CopyToClipboard from 'react-copy-to-clipboard'

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .cs_center__text_wrap {
    display: flex;
    flex-direction: column;
    gap: 4px;

    .text_wrap__title {
      font-weight: 700;
      font-size: 12px;
      color: rgba(0, 0, 0, 0.5);
    }
    .text_wrap__email {
      font-weight: 600;
      font-size: 16px;
    }
  }

  .cs_center__button_wrap {
    & > button {
      padding: 7px 12px;
      width: auto;
      height: auto;
      font-size: 14px;
      font-weight: 600;
      line-height: 22px;
    }
  }
`

function DeleteAccountCSCenter({ t }) {
  const { code } = useSelector(phoneSelector)
  const regions = useRegions()
  const userRegionCode = REGION_BY_COUNTRY_CODE[code.replace('+', '')]
  const region = regions?.find(region => region.country === userRegionCode)

  if (!region) {
    return null
  }

  return (
    <Wrapper>
      <div className="cs_center__text_wrap">
        <p className="text_wrap__title">{t('label_cs_center')}</p>
        <p className="text_wrap__email">{region?.meta?.riderCsEmail}</p>
      </div>
      <div className="cs_center__button_wrap">
        <CopyToClipboard text={region?.meta?.riderCsEmail}>
          <DeleteAccountButton variant="outline">Copy</DeleteAccountButton>
        </CopyToClipboard>
      </div>
    </Wrapper>
  )
}

export default withTranslation()(DeleteAccountCSCenter)
