import qs from 'qs';
import { TOKEN_KEYS } from '../../../constants/constants';
import { get } from '../../../utils/api';

/*
Response Type

{
  "inviterType": USER_MODEL, // RIDER, ...
  "inviteeType": USER_MODEL | null, // RIDER, ... 
  "region": REGION, //SG, KH, ...
  "referralRewardRuleInviterDescription": string
  "referralRewardRuleInviteeDescription": string | null
  "referralRewardRuleTnC": string | null
}
*/

export const getReferralRewardRules = async (inviterType, inviteeType, region, locale, driverType) => {
  const query = qs.stringify({
    inviterType,
    inviteeType,
    region,
    locale,
    driverType,
  });
  return await get(`/membersvc/api/referral-reward-rules?${query}`, undefined, TOKEN_KEYS.faq);
};
