import { Component } from 'react';

import styled from 'styled-components';

import appIcon from '@/assets/images/tada/app-icon-rider@2x.png';
import human from '@/assets/images/tada/icon-human@2x.png';
import { AppBannerContainer, AppIcon, AppTitle } from '@/components/AppBanner/index';

const RiderAppBannerContainer = styled(AppBannerContainer)`
  &:after,
  &::after {
    position: absolute;
    background-image: url(${human});
    background-size: contain;
    background-repeat: no-repeat;
    right: 0;
    width: 65px;
    height: 93px;
    content: ' ';
  }

  @media (max-width: 1000px) {
    height: 220px;

    &:after,
    &::after {
      left: calc(50% + 100px);
      bottom: calc(50% + 11px);
      // left: 100px;
      // bottom: 61px;
      top: auto;
      height: 60px;
    }
  }
`;

export default class RiderAppBanner extends Component {
  render() {
    return (
      <RiderAppBannerContainer>
        <AppIcon src={appIcon} />
        <AppTitle>
          TADA for
          <br />
          <span>RIDER</span>
        </AppTitle>
      </RiderAppBannerContainer>
    );
  }
}
