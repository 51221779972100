export const useTadaRiderAppLink = (branchKey: string) => {
  const openLink = async () => {
    const res = await window.fetch('https://api2.branch.io/v1/url', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
      },
      mode: 'cors',
      cache: 'default',
      body: JSON.stringify({
        branch_key: branchKey,
      }),
    });

    if (res.ok) {
      const json = await res.json();

      if (/iPhone|iPod|iPad/.test(navigator.userAgent)) {
        window.location = json.url;
      } else {
        window.open(json.url, '_system', 'location=yes');
      }
    }
  };

  return { openLink };
};
