import moment from 'moment';

import { DEFAULT_CENTER, DEFAULT_CENTER_OF_REGION } from './constant/constants';
import { Region } from './constant/types';
import { SimpleLatLngPoint } from './types';

export const getRandomUuid = () => {
  // UUID v4 generator in JavaScript (RFC4122 compliant)
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0;
    const v = c === 'x' ? r : (r & 3) | 8;
    return v.toString(16);
  });
};

export const getCurrentDeviceUUID = (rideId: string) => {
  const existUUID = localStorage.getItem(rideId);

  if (existUUID) {
    return existUUID;
  }

  const newUUID = getRandomUuid();

  localStorage.setItem(rideId, newUUID);

  return newUUID;
};

export const getDriverRate = (rate: number) => {
  // 소수점이 없을 시 .0 붙이도록
  return rate % 1 === 0 ? `${rate}.0` : rate.toFixed(1).toString();
};

export const getBoundsOfPolygon = (vertices: google.maps.LatLngLiteral[]) => {
  const lngs = vertices.map((vertice) => vertice.lng);
  const lats = vertices.map((vertice) => vertice.lat);

  const minLng = Math.min.apply(null, [...lngs, 180]);
  const maxLng = Math.max.apply(null, [...lngs, -180]);
  const minLat = Math.min.apply(null, [...lats, 85]);
  const maxLat = Math.max.apply(null, [...lats, -85]);

  return new google.maps.LatLngBounds(
    { lat: parseFloat(`${minLat}`), lng: parseFloat(`${minLng}`) }, // sw
    { lat: parseFloat(`${maxLat}`), lng: parseFloat(`${maxLng}`) } // ne
  );
};

export const getBoundsOfMarkers = (location: { latitude: number; longitude: number }) => {
  // 세계지도 보여주는 default 값
  let minLng = 180;
  let maxLng = -180;
  let minLat = 85;
  let maxLat = -85;

  minLng = Math.min(location.longitude, minLng);
  maxLng = Math.max(location.longitude, maxLng);
  minLat = Math.min(location.latitude, minLat);
  maxLat = Math.max(location.latitude, maxLat);

  return new google.maps.LatLngBounds(
    { lat: minLat, lng: minLng }, // sw
    { lat: maxLat, lng: maxLng } // ne
  );
};

export const getDefaultCenterOfRegion = (region?: Region) => {
  return region ? DEFAULT_CENTER_OF_REGION[region] : DEFAULT_CENTER;
};

const DEFAULT_DATE_FORMAT = 'DD-MM-YYYY HH:mm:ss Z';
export const getFormattingTime = (t: moment.MomentInput, format: string = DEFAULT_DATE_FORMAT) => (t ? moment(t).format(format) : '');

export const getAdditionalEstimatedTime = (estimatedTime: number) => {
  if (estimatedTime <= 5) {
    return 1;
  }

  if (estimatedTime <= 10) {
    return 2;
  }

  if (estimatedTime <= 20) {
    return 3;
  }

  return 5;
};

export const transformToLatLngLiteral = (location: SimpleLatLngPoint): google.maps.LatLngLiteral => ({
  lat: location.latitude,
  lng: location.longitude,
});

export const checkIOS = () => {
  const userAgent = window.navigator.userAgent || window.navigator.vendor;

  return /iPad|iPhone|iPod/.test(userAgent) || (navigator.userAgent.includes('Mac') && 'ontouchend' in document);
};
