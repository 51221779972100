import { useMemo, useRef } from 'react';

export const useSwitchInAndOut = () => {
  const contactRef1 = useRef(null);
  const contactRef2 = useRef(null);
  const refs = useMemo(() => [contactRef1, contactRef2], []);
  const cursor = useRef(0);

  const applySwitchInElements = (htmlString) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');
    const animationStepElements = doc.querySelectorAll('*[class^="animation-step-"]');
    const hrElements = doc.querySelectorAll('hr');

    animationStepElements.forEach((el) => {
      el.innerHTML = `<div class="ts-switch-in">${el.innerHTML}</div><div class="ts-switch-in hidden-section">${el.innerHTML}</div>`;
    });

    hrElements.forEach((el) => {
      el.className = 'ts-fade-in';
    });

    return doc.body.innerHTML;
  };

  const applySwitchOutAnimation = (ele) => {
    const prevAnimationStepElements = ele.querySelectorAll('*[class^="animation-step-"]');
    const prevHrElements = ele.querySelectorAll('hr');

    prevAnimationStepElements.forEach((el) => {
      el.children[0].className = 'ts-switch-out';
    });

    prevHrElements.forEach((el) => {
      el.className = 'ts-fade-out';
    });
  };

  const increaseCursor = () => {
    cursor.current = (cursor.current + 1) % 2;
  };

  return { refs, cursor, applySwitchInElements, applySwitchOutAnimation, increaseCursor };
};
