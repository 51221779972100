import styled from 'styled-components';

interface RideInfoProps {
  address: string;
  image: string;
  snapIdx: number;
}

const RideInfoContanier = styled.div<{ $snapIdx: number }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  > img {
    width: 16px;
    height: 24px;
    margin-right: 10px;
    margin-bottom: auto;
  }

  > span {
    line-height: 20px;
    ${(props) => `
          ${
            props.$snapIdx === 1
              ? `
              text-overflow: ellipsis;
              overflow: hidden;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
            `
              : ''
          }
        `}
  }

  & + & {
    margin-top: 5px;
  }
`;

function RideInfo({ address, image, snapIdx }: RideInfoProps) {
  return (
    <RideInfoContanier $snapIdx={snapIdx}>
      <img src={image} alt='pickupIcon' />
      <span>{address}</span>
    </RideInfoContanier>
  );
}

export default RideInfo;
