import { useRef, useState } from 'react';

import AppLink from './AppLink';
import ExpiredRide from './ExpiredRide';
import Map from './Map';
import RideContent from './RideContent';
import { RIDE_STATUS } from './constant/constants';
import { useRideMap } from './hooks';
import { useDriverRouteListener, useRideStatusListener } from './hooks/firebase';
import { useLiveRideContext, LiveRideProvider } from './parts/LiveRideProvider';
import { Loading } from './parts/Loading';
import { SharedRideInfo } from './types';

function RideMap() {
  const googleMap = useRef<google.maps.Map>(null);
  const liveRideContext = useLiveRideContext();

  const [rideInfo, setRideInfo] = useState<SharedRideInfo | null>(null);

  const { rideStatus } = useRideStatusListener(rideInfo?.rideId || '');
  const { codStatus } = useDriverRouteListener(rideInfo?.rideId || '');

  const { rideRouting, isErrorInfo, isErrorRoute, isNARide } = useRideMap({
    rideStatus: rideStatus!,
    rideInfo: rideInfo!,
    codStatus,
    setRideInfo: (result) => setRideInfo(result),
  });

  if ((!rideInfo && !isErrorInfo) || (!rideRouting && !isErrorRoute)) {
    return <Loading />;
  }

  if (rideStatus === RIDE_STATUS.RENTAL_STATUS_EXPIRED || (isErrorInfo && isErrorRoute)) {
    return <ExpiredRide />;
  }

  return (
    <LiveRideProvider value={{ ...liveRideContext, rideInfo, rideRouting, googleMap: googleMap, isNARide }}>
      <div>
        <AppLink />
        <Map />
        <RideContent rideStatus={rideStatus!} />
      </div>
    </LiveRideProvider>
  );
}

export default RideMap;
