import { Component } from 'react';

import qs from 'qs';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Slider from 'react-slick';
import { compose } from 'recompose';
import styled from 'styled-components';

import './table.css';
import FaqTabs from './FaqTabs';
import { getAppAuthorityFromSection } from './hooks';
import { fetchFaqQuestionByAlias, fetchFaqTopicByAlias } from './util';
import { STATIC_TOPIC_NAME, TOKEN_KEYS } from '../../../constants/constants';
import { getLocale } from '../../../selectors';
import { getIsMobileView } from '../../../utils/MyUtils';
import { get } from '../../../utils/api';
import { getLocationWithFallback } from '../../../utils/location';
import { AliasWebview } from '../../AliasWebview/AliasWebview';
import DocumentPageContainer from '../../Common/DocumentPageContainer';
import Loading from '../../Common/Loading';
import { TabContainer, TabContent, TabControlBtn, TabController } from '../../Tab/index';

const items = [
  {
    title: 'faq_driver_app',
    section: 'driver',
  },
  {
    title: 'faq_rider_app',
    section: 'rider',
  },
];

const TadaFaqPageTitle = styled.h1`
  padding: 0;
  margin: 0 0 57px;
  font-size: 72px;
  font-weight: bold;
  color: #4a4a4a;
  @media (max-width: 1000px) {
    margin-bottom: 41px;
    text-align: center;
    font-size: 40px;
    font-weight: 900;
    color: #0a3142;
  }
`;

const TadaFaqForm = styled.form`
  &:after,
  &::after {
    clear: both;
  }
`;

const FAQSlider = styled(Slider)`
  > div {
    min-height: 1000px;
  }
`;

export class TadaFaqPage extends Component {
  constructor(props) {
    super(props);

    const slideIndex = qs.parse(props.match.params);
    let currentSection = 0;
    if (slideIndex.section === items[0].section) {
      currentSection = 0;
    } else {
      currentSection = 1;
    }

    this.controller = new window.AbortController();
    this.state = {
      isBackButtonChange: false, // 브라우저 back 버튼 혹은 forward 버튼 눌렀을 때 flag
      currentSection: currentSection,
      topicList: [],
      isTopicFetching: false,
      topicData: null,
      questionData: null,
      questionList: [],
      isFirst: true,
    };
  }

  slider;

  async componentDidMount() {
    this.fetchTopicList();
  }

  componentDidUpdate(prevProps) {
    const {
      match: { params },
      location,
      selectedLocale,
    } = this.props;

    const search = qs.parse(location.search, { ignoreQueryPrefix: true });
    const prevSearch = qs.parse(prevProps.location.search, { ignoreQueryPrefix: true });

    if (
      prevProps.match.params.section !== params.section ||
      search.region !== prevSearch.region ||
      selectedLocale !== prevProps.selectedLocale ||
      (search.keyword !== prevSearch.keyword && search.keyword === '')
    ) {
      this.controller.abort();
      this.props.history.replace(`/faq/${params.section}?${qs.stringify({ ...search, topicId: undefined })}`);
      this.fetchTopicList();
    }
  }

  async fetchTopicList() {
    const {
      match: { params },
      location,
      selectedLocale,
    } = this.props;

    const search = qs.parse(location.search, { ignoreQueryPrefix: true });

    const language = search.language || selectedLocale;

    const { region } = await getLocationWithFallback(search.region, search.city, selectedLocale);

    this.controller = new window.AbortController();

    try {
      this.setState({
        isTopicFetching: true,
        isFirst: false,
      });
      const result = await get(
        `/cssvc/v1/faq/topic/list?appAuthority=${getAppAuthorityFromSection(params.section)}&region=${region}&locale=${language}`,
        undefined,
        TOKEN_KEYS.faq,
        undefined,
        {
          signal: this.controller.signal,
        }
      );

      this.setState({
        topicList: [...result.content],
      });

      const questionKey = params.section === 'driver' ? 'driverQuestion' : 'riderQuestion';
      let topicId;
      let questionId;

      if (search.aliasKey) {
        const topicParams = `${search.aliasKey}?appAuthority=${getAppAuthorityFromSection(params.section)}&region=${region}&locale=${language}`;
        const topicData = await fetchFaqTopicByAlias(topicParams, {
          signal: this.controller.signal,
        });
        const questionParams = `${search.aliasKey}?appAuthority=${getAppAuthorityFromSection(params.section)}&region=${region}&locale=${language}`;
        const questionData = await fetchFaqQuestionByAlias(questionParams, {
          signal: this.controller.signal,
        });

        const listQuery = {
          appAuthority: getAppAuthorityFromSection(params.section),
          region,
          topicId: topicData.id || search.topicId,
          locale: search.language || selectedLocale,
          status: search.status,
          keyword: search.keyword || undefined,
        };

        const questionList = await get(`/cssvc/v1/faq/question/list?${qs.stringify(listQuery)}`, undefined, TOKEN_KEYS.faq);

        topicId = topicData?.id ?? undefined;
        questionId = questionData?.id ?? undefined;

        this.setState({
          topicData,
          questionData,
          questionList: questionList?.content ?? [],
        });
      } else if (search[questionKey]) {
        const topicNames = STATIC_TOPIC_NAME[params.section];

        if (topicNames[search[questionKey]]) {
          let topic;

          if (search[questionKey] === 'driver_referral') {
            topic = result.content.find((topic) => topic.contents[0].topicName.includes(topicNames[search[questionKey]][selectedLocale]));
          } else {
            topic = result.content.find((topic) => topic.contents[0].topicName === topicNames[search[questionKey]][selectedLocale]);
          }

          if (topic) {
            topicId = topic.id;
          }
        }
      }

      this.props.history.replace({
        pathname: location.pathname,
        search: qs.stringify({
          ...search,
          topicId: topicId ?? undefined,
          questionId: questionId ?? undefined,
        }),
      });

      this.setState({
        isTopicFetching: false,
      });
    } catch (e) {
      this.setState({
        isTopicFetching: false,
      });
    }
  }

  render() {
    const search = qs.parse(this.props.location.search, { ignoreQueryPrefix: true });
    const { t, history, match } = this.props;

    const { topicList, topicData, questionData, questionList, isTopicFetching, isFirst } = this.state;

    const isCustomView = search.aliasKey && getIsMobileView();
    const isLoading = (isCustomView && !topicData && ((isFirst && !isTopicFetching) || (!isFirst && isTopicFetching))) || isTopicFetching;

    const changeSection = (index) => {
      const cloneSearch = { ...search };
      delete cloneSearch.topicId;
      delete cloneSearch.keyword;

      // index = 0: driver, 1: rider
      const section = index === 0 ? 'driver' : 'rider';
      if (this.state.isBackButtonChange) {
        this.setState({ isBackButtonChange: false });
      } else {
        history.push(`/faq/${section}?${qs.stringify(cloneSearch)}`);
      }
      this.setState({ currentSection: index });
    };

    const beforeChange = (_, newIndex) => {
      this.setState({
        topicList: [],
        isTopicFetching: true,
      });

      changeSection(newIndex);
    };

    const slideIndex = qs.parse(match.params);
    const setting = {
      dots: true,
      arrows: false,
      infinite: false,
      initialSlide: null,
      autoplay: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      swipe: false,
      dotsClass: '',
      className: 'tab-carousel',
      beforeChange: beforeChange,

      appendDots: (dots) => <TabController>{dots}</TabController>,
      customPaging: (i) => <TabControlBtn>{t(items[i].title)}</TabControlBtn>,
    };
    if (slideIndex.section === items[0].section) {
      setting.initialSlide = 0;
    } else {
      setting.initialSlide = 1;
    }
    if (this.slider && !this.state.isBackButtonChange && this.state.currentSection !== setting.initialSlide) {
      this.setState({ isBackButtonChange: true });
      this.slider.slickGoTo(setting.initialSlide);
    }

    if (isLoading) {
      return <Loading />;
    }

    return (
      <div>
        {isCustomView && topicData ? (
          <AliasWebview topicData={topicData} questionData={questionData} questionList={questionList} />
        ) : (
          <DocumentPageContainer>
            <TadaFaqPageTitle>{t('faq')}</TadaFaqPageTitle>
            <TadaFaqForm onSubmit={(e) => e.preventDefault()}>
              <TabContainer>
                <FAQSlider ref={(slider) => (this.slider = slider)} {...setting}>
                  {items.map((item) => {
                    return (
                      <TabContent key={item.title}>
                        <FaqTabs
                          title={item.title}
                          tabSection={item.section}
                          currentSection={this.state.currentSection}
                          topicList={topicList}
                          isTopicFetching={isTopicFetching}
                        />
                      </TabContent>
                    );
                  })}
                </FAQSlider>
              </TabContainer>
            </TadaFaqForm>
          </DocumentPageContainer>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  selectedLocale: getLocale(state),
});

const enhance = compose(withTranslation(), connect(mapStateToProps, null));

export default enhance(withRouter(TadaFaqPage));
