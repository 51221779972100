import { useEffect, useRef } from 'react';

import { useMapContext } from 'react-google-map-wrapper';
import styled from 'styled-components';

import AnimatedMarker from './AnimatedMarker';
import DottedPolyline from './DottedPolyline';
import { useLiveRideContext } from './LiveRideProvider';
import MarkerWithInfoWindow from './MarkerWithInfoWindow';
import RoutePolyLine from './RoutePolyLine';
import { useCarIconByRideInfo } from '../hooks';
import { useDriverRouteListener } from '../hooks/firebase';
import { checkIOS, getBoundsOfPolygon } from '../utils';

interface MapContentProps {
  rideRouteValue: google.maps.LatLngLiteral[];
  pickUpFromRoadValue: google.maps.LatLngLiteral[];
  destinationFromRoadValue: google.maps.LatLngLiteral[];
}

const CarWrapper = styled.img<{ $markerHeadingRotation: number }>`
  rotate: ${({ $markerHeadingRotation }) => $markerHeadingRotation}deg;
`;

const COMMON_BOUND_PADDING = {
  right: 30,
  top: 40,
  bottom: 260,
  left: 30,
};

const IOS_BOUND_PADDING = {
  right: 30,
  top: 50,
  bottom: 280,
  left: 30,
};

function MapContent({ rideRouteValue, pickUpFromRoadValue, destinationFromRoadValue }: MapContentProps) {
  const { images, rideInfo, isNARide } = useLiveRideContext();
  const { driverRoute, rotation } = useDriverRouteListener(rideInfo!.rideId);
  const map = useMapContext();
  const carImage = useCarIconByRideInfo();
  const isInitialFitBound = useRef<boolean>(false);

  const getBoundPadding = (bounds: google.maps.LatLngBounds) => {
    const isIOS = checkIOS();

    const northEast = bounds.getNorthEast();
    const southWest = bounds.getSouthWest();

    const width = Math.abs(northEast.lng() - southWest.lng());
    const height = Math.abs(northEast.lat() - southWest.lat());

    // 세로가 더 긴 route인 경우
    if (height > width) {
      return isIOS ? IOS_BOUND_PADDING : COMMON_BOUND_PADDING;
    }
    // 가로가 더 긴 route인 경우
    return 110;
  };

  useEffect(() => {
    const poly = (isNARide ? [driverRoute].filter(Boolean) : rideRouteValue) as google.maps.LatLngLiteral[];
    if (!poly.length || !map || isInitialFitBound.current) {
      return;
    }

    isInitialFitBound.current = true;
    const bound = getBoundsOfPolygon(poly);

    map.fitBounds(bound, getBoundPadding(bound));
    isNARide && map.setZoom(16);
  }, [rideRouteValue, driverRoute, isNARide]);

  return (
    <div>
      {driverRoute?.lat && driverRoute?.lng && (
        <AnimatedMarker position={driverRoute}>
          <CarWrapper src={carImage} $markerHeadingRotation={rotation!} />
        </AnimatedMarker>
      )}
      {rideInfo!.pickUpPoint && (
        <MarkerWithInfoWindow
          label={(rideInfo!.pickUpPoint.name || rideInfo!.pickUpPoint.address) as string}
          icon={images.pickupPin}
          position={{
            lat: rideInfo!.pickUpPoint.latitude,
            lng: rideInfo!.pickUpPoint.longitude,
          }}
        />
      )}
      {!!rideRouteValue.length && (
        <>
          <RoutePolyLine value={rideRouteValue} />
          <DottedPolyline value={pickUpFromRoadValue} />
          <DottedPolyline value={destinationFromRoadValue} />
        </>
      )}
      {rideInfo!.targetPointList?.map((targetPoint) => (
        <MarkerWithInfoWindow
          label={(targetPoint.name || targetPoint.address) as string}
          icon={images.dropOffPin}
          position={{
            lat: targetPoint.latitude,
            lng: targetPoint.longitude,
          }}
          key={targetPoint.googlePlaceId}
        />
      ))}
      {rideInfo!.destinationPoint && !isNARide && (
        <MarkerWithInfoWindow
          label={(rideInfo!.destinationPoint.name || rideInfo!.destinationPoint.address) as string}
          icon={rideInfo!.targetPointList?.length ? images.targetPin : images.dropOffPin}
          position={{
            lat: rideInfo!.destinationPoint.latitude,
            lng: rideInfo!.destinationPoint.longitude,
          }}
        />
      )}
    </div>
  );
}

export default MapContent;
