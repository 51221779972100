import { call, put, takeLatest } from 'redux-saga/effects';
import {
  requestOTPFailure,
  requestOTPRequest,
  requestOTPSuccess,
  validateOTPRequest,
  validateOTPSuccess,
  validateOTPFailure,
  checkExistsRequest,
  checkExistsSuccess,
  checkExistsFailure,
  getUserInfoRequest,
  getUserInfoSuccess,
  getUserInfoFailure,
  checkAvailableDeleteRequest,
  checkAvailableDeleteSuccess,
  checkAvailableDeleteFailure,
  deleteAccountRequest,
  deleteAccountSuccess,
  deleteAccountFailure,
} from './reducer';
import { get, post } from '../../utils/api';
import { TOKEN_KEYS } from '../../constants/constants';
import { setAuthenticationToken } from '../../utils/authentication';

function* requestOTP({ payload: { phone, brand, recaptchaToken } }) {
  try {
    const response = yield call(
      post,
      '/accountsvc/v1/otp',
      {
        phone: `${phone.code}${phone.number}`,
        brand,
        recaptchaToken,
      },
      undefined,
      TOKEN_KEYS.faq,
      {
        decamelize: false,
        setLocale: false,
      }
    );

    const action = response.otpThrottleSeconds ? requestOTPSuccess(response) : requestOTPFailure(response);
    yield put(action);
  } catch (err) {
    yield put(requestOTPFailure(err));
  }
}

function* validateOTP({ payload }) {
  try {
    const response = yield call(post, '/accountsvc/v1/otp/validate', payload, undefined, TOKEN_KEYS.faq, {
      decamelize: false,
      setLocale: false,
    });

    const action = typeof response === 'boolean' ? validateOTPSuccess(response) : validateOTPFailure(response);
    yield put(action);
  } catch (err) {
    yield put(validateOTPFailure(err.message));
  }
}

function* checkExists({ payload }) {
  try {
    const response = yield call(post, '/accountsvc/v1/identifier:exists', payload, undefined, TOKEN_KEYS.faq, {
      decamelize: false,
      setLocale: false,
    });

    yield put(checkExistsSuccess(response));
  } catch (err) {
    yield put(checkExistsFailure(err.message));
  }
}

function* getUserInfo({ payload }) {
  try {
    const response = yield call(post, '/accountsvc/v1/oauth/token', payload, undefined, TOKEN_KEYS.faq, {
      decamelize: false,
      setLocale: false,
    });

    if (!response.accessToken) {
      put(getUserInfoFailure(response.error_description));
    } else {
      const { accessToken } = response;

      setAuthenticationToken({ accessToken });
      const { uuid } = yield call(get, '/accountsvc/v1/accounts/me');

      const action = getUserInfoSuccess({
        token: accessToken,
        uuid,
      });

      yield put(action);
    }
  } catch (err) {
    yield put(getUserInfoFailure(err.message));
  }
}

function* checkAvailableDelete() {
  try {
    const response = yield call(get, '/membersvc/api/v1/users/withdrawalRequest/available');

    yield put(checkAvailableDeleteSuccess(response));
  } catch (err) {
    yield put(checkAvailableDeleteFailure(err));
  }
}

function* deleteAccount({ payload }) {
  try {
    const response = yield call(
      post,
      `/membersvc/api/v2/users/withdrawalRequest?id=${payload.uuid}`,
      { description: payload.description },
      undefined,
      undefined,
      {
        decamelize: false,
        setLocale: false,
      }
    );

    yield put(deleteAccountSuccess(response));
  } catch (err) {
    yield put(deleteAccountFailure(err.message));
  }
}

export default function* defaultSaga() {
  yield takeLatest(requestOTPRequest.type, requestOTP);
  yield takeLatest(validateOTPRequest.type, validateOTP);
  yield takeLatest(checkExistsRequest.type, checkExists);
  yield takeLatest(getUserInfoRequest.type, getUserInfo);
  yield takeLatest(checkAvailableDeleteRequest.type, checkAvailableDelete);
  yield takeLatest(deleteAccountRequest.type, deleteAccount);
}
