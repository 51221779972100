import _ from 'lodash';

import policies from './policy/policies';

const constants = {
  WHITE_PAPER_URL: '/papers/mvl_white_paper.pdf?t=1',
  TERMS_POLICY: {
    PRIVACY_POLICY: 'privacy_policy',
    TERMS_AND_CONDITIONS: 'terms_and_conditions',
  },
  MY_PAGE_SELECTOR: {
    MY_PAGE: 0,
    ICO: 1,
    KYC: 2,
    TOKEN_SALE: 3,
    BOUNTY: 4,
    AIRDROP: 5,
    BOUNTY_CHECK: 6,
  },
  ICO_STEP: {
    INFO: '0',
    WALLET_ADDRESS: '1',
    WELCOME: '2',
  },
  KYC_PICTURE_TYPE: {
    PASSPORT: 'passport',
    ID_CARD: 'idcard',
    DRIVING_LICENCE: 'driving_licence',
  },
  KYC_STEP: {
    USER_INFO: '0',
    PICTURE_UPLOAD: '1',
    SELFIE_UPLOAD: '2',
    RESULT: '3',
  },
  KYC_STATUS_NOT_YET: -1,
  KYC_STATUS_PENDING: 0,
  KYC_STATUS_PASSED: 1,
  KYC_STATUS_FAILED: 2,
  KYC_STATUS_REJECTED: 3,
  KYC_OCR_NOT_YET: -1,
  KYC_OCR_PENDNG: 0,
  KYC_OCR_PASSED: 1,
  KYC_OCR_FAILED: 2,
  ICO_STATUS: {
    NOT_YET: 0,
    OPEN: 1,
    EARLY_FINISH: 2,
    FINISH: 3,
  },
  DRIVER_APPLICATION_STATUS: {
    NOT_YET: -1,
    PENDING: 0,
    PASSED: 1,
    FAILED: 2,
  },
  DRIVER_TYPE: {
    PRIVATE_HIRE: 0,
    LITE: 1,
    TAXI: 2,
    TUKTUK: 3,
    BIKE: 4,
  },
  DRIVER_REGION: {
    SINGAPORE: 'SG',
    VIETNAM: 'VN',
    CAMBODIA: 'KH',
    THAILAND: 'TH',
  },
  TAXI_COMPANY: {
    SMRT_CAB: 'SMRT Cab',
    PREMIER: 'Premier',
    PRIME: 'Prime',
    COMFORT_AND_CITYCAB: 'ComfortDelgro & Citycab',
    TRANS_CAB: 'Trans-cab',
    HDT: 'HDT',
    YELLOW_TOP: 'Yellow-Top',
  },
};

export default constants;

export const REGION = {
  SG: 'SG',
  VN: 'VN',
  KH: 'KH',
  KR: 'KR',
  ET: 'ET',
  ID: 'ID',
  HK: 'HK',
  TH: 'TH',
  GLOBAL: 'GLOBAL',
};

export const CITY = {
  SINGAPORE: 'SIN',
  HOCHIMINH: 'HCM',
  HANOI: 'HAN',
  PHNOMPENH: 'PNH',
  SIEMREAP: 'REP',
  SIHANOUK_VILLE: 'SHV',
  KPK: 'KPK',
  SEOUL: 'SEL',
  ADDIS_ABABA: 'ADD',
  JAKARTA: 'JKT',
  HONGKONG: 'HKG',
  BANGKOK: 'BKK',
  PBM: 'PBM',
  BTB: 'BTB',
  GLOBAL: 'GLOBAL',
};

export const CITIES_BY_REGION = {
  SG: [CITY.SINGAPORE],
  KH: [CITY.PHNOMPENH, CITY.SIEMREAP, CITY.SIHANOUK_VILLE, CITY.KPK],
  VN: [CITY.HOCHIMINH],
  TH: [CITY.BANGKOK],
  HK: [CITY.HONGKONG],
};

export const REGION_BY_CONTACT_INFO = {
  [REGION.SG]: {
    address: 'Corporate Office & TADA Station | 40 Sin Ming Lane, Midview City, Singapore 573958',
    company: 'MVL Foundation Pte. Ltd.',
  },
  [REGION.KH]: {
    address: 'Set Art Building, #93B,Street 590, Village 13, Sangkat Beong Kok II, Khan Toul Kork, Phnom Penh, Cambodia',
    phone: '+855-61-987-658/+855-81-987-657',
    email: 'supportkh@tada.global',
    company: 'MVL Foundation Pte. Ltd.',
  },
  [REGION.VN]: {
    // address: 'G01, số 37, đường số 02, phường Bình An, quận 02, TP. HCM',
    address: 'Phòng G01, 37 đường số 02, phường An Khánh, TP Thủ Đức, TP Hồ Chí Minh',
    phone: '84-908-457-393',
    email: 'hotro@tada.global',
    company: 'TADA Technologies Pte.,Ltd',
  },
  [REGION.TH]: {
    address: 'No 2322 Soi Pracha Songkhro, Din Daeng, Bangkok 10400 (+66)2-460-7008',
    company: 'MVL Foundation Pte. Ltd.',
  },
  [REGION.HK]: {
    address: '',
    company: 'MVL Foundation Pte. Ltd.',
  },
};

// Address is Singapore office, should change to Vietnam office :
// Room G01, No. 37, Street No.02, An Khanh Ward, Thu Duc City, HCMC, Vietnam
// Company name: TADA Technologies Pte.,Ltd

export function getContactInfo(language) {
  switch (language) {
    case 'km':
      return REGION_BY_CONTACT_INFO[REGION.KH];
    case 'vi':
      return REGION_BY_CONTACT_INFO[REGION.VN];
    case 'th':
      return REGION_BY_CONTACT_INFO[REGION.TH];
    default:
      return REGION_BY_CONTACT_INFO[REGION.SG];
  }
}

export const DOMAIN_TYPE = {
  VN_DOMAIN: 'VN_DOMAIN',
  TADA_DOMAIN: 'TADA_DOMAIN',
};

export const LOCALE_TYPE = {
  EN: 'en',
  KO: 'ko',
  KM: 'km',
  VI: 'vi',
  TH: 'th',
  ZH_CN: 'zh-CN',
  ZH_TW: 'zh-TW',
  JP: 'jp',
};

export function getDomainType() {
  if (window.location.hostname.includes('tadaridevn.com') || window.location.hostname.includes('tadaridevn.vn')) {
    return DOMAIN_TYPE.VN_DOMAIN;
  }

  return DOMAIN_TYPE.TADA_DOMAIN;
}

export const TOKEN_KEYS = {
  default: window.btoa('tada_driver_app:tada_driver_secret7'),
  faq: window.btoa('mvl-homepage:mvl-homepage-secret0'),
};

export const APP_AUTHORITY = {
  RIDER_APP: 'RIDER_APP',
  DRIVER_APP: 'DRIVER_APP',
  WEB_HOMEPAGE: 'WEB_HOMEPAGE',
};

export const STATIC_TOPIC_NAME = {
  driver: {
    payment_related: {
      en: 'Payment-related Questions',
      km: 'សំណួរទាក់ទងនឹងការទូទាត់',
      ko: '결재 관련 질문',
      vi: 'Những câu hỏi liên quan đến thanh toán',
      th: 'Payment-related Questions',
      [LOCALE_TYPE.ZH_CN]: '费用相关问题',
      [LOCALE_TYPE.ZH_TW]: '費用相關問題',
    },
    driver_referral: {
      en: 'Driver rider referral',
      km: 'អ្នកបើកបរណែនាំអ្នកដំណើរ',
      ko: '드라이버 추천인 프로그램',
      vi: 'Chương trình giới thiệu tài xế tham gia nền tảng gọi xe công nghệ TADA ',
      th: 'โปรแกรมแนะนำคนขับ',
      [LOCALE_TYPE.ZH_CN]: 'Driver Referral Programme',
      [LOCALE_TYPE.ZH_TW]: 'Driver Referral Programme',
    },
    auto_accept: {
      en: 'Auto Accept',
      km: 'Auto Accept',
      ko: 'Auto Accept',
      vi: 'Auto Accept',
      th: 'การยอมรับงานอัตโนมัติ',
      [LOCALE_TYPE.ZH_CN]: 'Auto Accept',
      [LOCALE_TYPE.ZH_TW]: 'Auto Accept',
    },
    my_destination: {
      en: 'My Destination Feature',
      km: 'មុខងារគោលដៅរបស់ខ្ញុំ',
      ko: 'My Destination Feature',
      vi: 'Điểm đến của tôi',
      th: 'คุณลักษณะของจุดหมายของฉัน',
      [LOCALE_TYPE.ZH_CN]: 'My Destination Feature',
      [LOCALE_TYPE.ZH_TW]: 'My Destination Feature',
    },
    pet_service: {
      en: 'Pet Service',
      km: 'Pet Service',
      ko: 'Pet Service',
      vi: 'Pet Service',
      th: 'Pet Service',
      [LOCALE_TYPE.ZH_CN]: '宠物载送服务',
      [LOCALE_TYPE.ZH_TW]: '宠物载送服务',
    },
  },
  rider: {
    common_enquiries: {
      en: 'Common Enquiries',
      km: 'សំណូមពរទូទៅ',
      ko: '일반 문의',
      vi: 'Yêu cầu chung',
      th: 'ข้อมูลทั่วไปสำหรับผู้โดยสาร',
      [LOCALE_TYPE.ZH_CN]: '常见咨询',
      [LOCALE_TYPE.ZH_TW]: '常見谘詢',
    },
    referral_code: {
      en: 'Referral Code',
      km: 'Referral Code',
      ko: 'Referral Code',
      vi: 'Referral Code',
      th: 'Referral Code',
      [LOCALE_TYPE.ZH_CN]: 'Referral Code',
      [LOCALE_TYPE.ZH_TW]: 'Referral Code',
    },
    poi_management: {
      en: 'Add/Report a Place',
      km: 'Add/Report a Place',
      ko: 'Add/Report a Place',
      vi: 'Add/Report a Place',
      th: 'Add/Report a Place',
      [LOCALE_TYPE.ZH_CN]: '添加/举报地点',
      [LOCALE_TYPE.ZH_TW]: 'Add/Report a Place',
    },
  },
};

export const SAFETY_STATIC_TOPIC = {
  driver: {},
  rider: {},
};

export const HELP_CENTER_STATIC_TOPIC = {
  rider: {
    accident_and_safety: {
      en: 'Accident and Safety',
      km: 'គ្រោះថ្នាក់ និងសុវត្ថិភាព',
      ko: 'Accident and Safety',
      vi: 'Accident and Safety',
      th: 'ความปลอดภัยและอุบัติเหตุ',
      [LOCALE_TYPE.ZH_CN]: 'Accident and Safety',
      [LOCALE_TYPE.ZH_TW]: 'Accident and Safety',
    },
  },
  driver: {},
};

export const MOBILE_NAVIGATOR_HEIGHT = 60;

export const HELP_CENTER_DETAIL_REPORT_HEIGHT = 390;
export const HELP_CENTER_DETAIL_FOOTER_HEIGHT = 164;
export const HELP_CENTER_REPORT_MIN_LENGTH = 10;

export const HELP_CENTER_REQUIRED_FIELD = {
  language: 'language',
  region: 'region',
  appAuthority: 'appAuthority',
  issuerUUID: 'issuerUUID',
  userAgent: 'userAgent',
};

export const ALL_HELP_CENTER_REQUIRED_FIELD = Object.values(HELP_CENTER_REQUIRED_FIELD);

// SW: 왼쪽 아래 꼭짓점
// NE: 오른쪽 위 꼭짓점
export const COORD_BY_REGION = {
  [REGION.SG]: {
    SW: {
      lat: 1.1303609609603882,
      lon: 103.56666564941406,
    },
    NE: {
      lat: 1.478472113609314,
      lon: 104.12517547607422,
    },
  },
  [REGION.VN]: {
    SW: {
      lat: 9.775994300842285,
      lon: 105.2865982055664,
    },
    NE: {
      lat: 21.385221481323242,
      lon: 107.58256530761719,
    },
  },
  [REGION.KH]: {
    SW: {
      lat: 11.22593879699707,
      lon: 104.48118591308594,
    },
    NE: {
      lat: 11.85853099822998,
      lon: 105.09603881835938,
    },
  },
  [REGION.TH]: {
    SW: { lat: 12.452299, lon: 99.687748 },
    NE: { lat: 14.694374, lon: 101.560641 },
  },
  [REGION.HK]: {
    SW: { lat: 22.160074, lon: 113.824138 },
    NE: { lat: 22.578608, lon: 114.411405 },
  },
};

export const POLICY = {
  COMMUNITY_GUIDELINE: 'community_guideline',
  DRIVERS_CODE_OF_CONDUCT: 'drivers_code_of_conduct',
  PRIVACY_POLICY_RIDER: 'privacy_policy_rider',
  PRIVACY_POLICY_DRIVER: 'privacy_policy_driver',
  TADA_TERM_FOR_DRIVER: 'tada_term_for_driver',
  TADA_TERM_FOR_RIDER: 'tada_term_for_rider',
};

export const AVAILABLE_LOCALE_POLICY = {
  [POLICY.COMMUNITY_GUIDELINE]: {
    [REGION.SG]: [LOCALE_TYPE.EN],
    [REGION.KH]: [LOCALE_TYPE.EN, LOCALE_TYPE.KM],
    [REGION.VN]: [],
    [REGION.TH]: [LOCALE_TYPE.EN, LOCALE_TYPE.TH],
    [REGION.HK]: [LOCALE_TYPE.EN],
  },
  [POLICY.DRIVERS_CODE_OF_CONDUCT]: {
    [REGION.SG]: [LOCALE_TYPE.EN],
    [REGION.KH]: [LOCALE_TYPE.EN, LOCALE_TYPE.KM],
    [REGION.VN]: [LOCALE_TYPE.VI],
    [REGION.TH]: [LOCALE_TYPE.EN, LOCALE_TYPE.TH],
    [REGION.HK]: [LOCALE_TYPE.EN],
  },
  [POLICY.PRIVACY_POLICY_RIDER]: {
    [REGION.SG]: [LOCALE_TYPE.EN],
    [REGION.KH]: [LOCALE_TYPE.EN, LOCALE_TYPE.KM],
    [REGION.VN]: [LOCALE_TYPE.VI],
    [REGION.TH]: [LOCALE_TYPE.EN, LOCALE_TYPE.TH],
    [REGION.HK]: [LOCALE_TYPE.EN],
  },
  [POLICY.PRIVACY_POLICY_DRIVER]: {
    [REGION.SG]: [LOCALE_TYPE.EN],
    [REGION.KH]: [LOCALE_TYPE.EN, LOCALE_TYPE.KM],
    [REGION.VN]: [LOCALE_TYPE.VI],
    [REGION.TH]: [LOCALE_TYPE.EN, LOCALE_TYPE.TH],
    [REGION.HK]: [LOCALE_TYPE.EN],
  },
  [POLICY.TADA_TERM_FOR_DRIVER]: {
    [REGION.SG]: [LOCALE_TYPE.EN],
    [REGION.KH]: [LOCALE_TYPE.EN, LOCALE_TYPE.KM],
    [REGION.VN]: [LOCALE_TYPE.EN, LOCALE_TYPE.VI],
    [REGION.TH]: [LOCALE_TYPE.EN, LOCALE_TYPE.TH],
    [REGION.HK]: [LOCALE_TYPE.EN],
  },
  [POLICY.TADA_TERM_FOR_RIDER]: {
    [REGION.SG]: [LOCALE_TYPE.EN],
    [REGION.KH]: [],
    [REGION.VN]: [LOCALE_TYPE.VI],
    [REGION.TH]: [LOCALE_TYPE.EN, LOCALE_TYPE.TH],
    [REGION.HK]: [LOCALE_TYPE.EN],
  },
};

const POLICY_FALLBACK = {
  [POLICY.COMMUNITY_GUIDELINE]: policies.COMMUNITY_GUIDELINE,
  [POLICY.DRIVERS_CODE_OF_CONDUCT]: policies.DRIVERS_CODE_OF_CONDUCT_EN_SG,
  [POLICY.PRIVACY_POLICY_RIDER]: policies.PRIVACY_POLICY_RIDER_EN_SG,
  [POLICY.PRIVACY_POLICY_DRIVER]: policies.PRIVACY_POLICY_DRIVER_EN_SG,
  [POLICY.TADA_TERM_FOR_DRIVER]: policies.TADA_TERM_OF_DRIVER_EN_SG,
  [POLICY.TADA_TERM_FOR_RIDER]: policies.TADA_TERM_OF_RIDER_EN_SG,
};

const POLICY_FALLBACK_VN = {
  [POLICY.COMMUNITY_GUIDELINE]: policies.COMMUNITY_GUIDELINE,
  [POLICY.DRIVERS_CODE_OF_CONDUCT]: policies.DRIVERS_CODE_OF_CONDUCT_VI_VI,
  [POLICY.PRIVACY_POLICY_RIDER]: policies.PRIVACY_POLICY_RIDER_VI_VI,
  [POLICY.PRIVACY_POLICY_DRIVER]: policies.PRIVACY_POLICY_DRIVER_VI_VI,
  [POLICY.TADA_TERM_FOR_DRIVER]: policies.TADA_TERM_OF_DRIVER_VI_VI,
  [POLICY.TADA_TERM_FOR_RIDER]: policies.TADA_TERM_OF_RIDER_VI_VI,
};

export const POLICY_BY_REGION = {
  [REGION.SG]: {
    [LOCALE_TYPE.EN]: {
      [POLICY.COMMUNITY_GUIDELINE]: policies.COMMUNITY_GUIDELINE,
      [POLICY.DRIVERS_CODE_OF_CONDUCT]: policies.DRIVERS_CODE_OF_CONDUCT_EN_SG,
      [POLICY.PRIVACY_POLICY_RIDER]: policies.PRIVACY_POLICY_RIDER_EN_SG,
      [POLICY.PRIVACY_POLICY_DRIVER]: policies.PRIVACY_POLICY_DRIVER_EN_SG,
      [POLICY.TADA_TERM_FOR_DRIVER]: policies.TADA_TERM_OF_DRIVER_EN_SG,
      [POLICY.TADA_TERM_FOR_RIDER]: policies.TADA_TERM_OF_RIDER_EN_SG,
    },
    [LOCALE_TYPE.KO]: POLICY_FALLBACK,
    [LOCALE_TYPE.VI]: POLICY_FALLBACK_VN,
    [LOCALE_TYPE.KM]: POLICY_FALLBACK,
    [LOCALE_TYPE.ZH_CN]: POLICY_FALLBACK,
    [LOCALE_TYPE.ZH_TW]: POLICY_FALLBACK,
    [LOCALE_TYPE.TH]: POLICY_FALLBACK,
  },
  [REGION.VN]: {
    [LOCALE_TYPE.EN]: {
      [POLICY.COMMUNITY_GUIDELINE]: policies.COMMUNITY_GUIDELINE,
      [POLICY.DRIVERS_CODE_OF_CONDUCT]: policies.DRIVERS_CODE_OF_CONDUCT_VI_VI,
      [POLICY.PRIVACY_POLICY_RIDER]: policies.PRIVACY_POLICY_RIDER_VI_VI,
      [POLICY.PRIVACY_POLICY_DRIVER]: policies.PRIVACY_POLICY_DRIVER_VI_VI,
      [POLICY.TADA_TERM_FOR_DRIVER]: policies.TADA_TERM_OF_DRIVER_EN_VI,
      [POLICY.TADA_TERM_FOR_RIDER]: policies.TADA_TERM_OF_RIDER_EN_SG,
    },
    [LOCALE_TYPE.KO]: POLICY_FALLBACK,
    [LOCALE_TYPE.VI]: {
      [POLICY.COMMUNITY_GUIDELINE]: policies.COMMUNITY_GUIDELINE,
      [POLICY.DRIVERS_CODE_OF_CONDUCT]: policies.DRIVERS_CODE_OF_CONDUCT_VI_VI,
      [POLICY.PRIVACY_POLICY_RIDER]: policies.PRIVACY_POLICY_RIDER_VI_VI,
      [POLICY.PRIVACY_POLICY_DRIVER]: policies.PRIVACY_POLICY_DRIVER_VI_VI,
      [POLICY.TADA_TERM_FOR_DRIVER]: policies.TADA_TERM_OF_DRIVER_VI_VI,
      [POLICY.TADA_TERM_FOR_RIDER]: policies.TADA_TERM_OF_RIDER_VI_VI,
    },
    [LOCALE_TYPE.KM]: POLICY_FALLBACK,
    [LOCALE_TYPE.ZH_CN]: POLICY_FALLBACK,
    [LOCALE_TYPE.ZH_TW]: POLICY_FALLBACK,
    [LOCALE_TYPE.TH]: POLICY_FALLBACK,
  },
  [REGION.KH]: {
    [LOCALE_TYPE.EN]: {
      [POLICY.COMMUNITY_GUIDELINE]: policies.COMMUNITY_GUIDELINE_EN_KH,
      [POLICY.DRIVERS_CODE_OF_CONDUCT]: policies.DRIVERS_CODE_OF_CONDUCT_EN_KH,
      [POLICY.PRIVACY_POLICY_RIDER]: policies.PRIVACY_POLICY_RIDER_EN_KH,
      [POLICY.PRIVACY_POLICY_DRIVER]: policies.PRIVACY_POLICY_DRIVER_EN_KH,
      [POLICY.TADA_TERM_FOR_DRIVER]: policies.TADA_TERM_OF_DRIVER_EN_KH,
      [POLICY.TADA_TERM_FOR_RIDER]: policies.TADA_TERM_OF_RIDER_EN_KH,
    },
    [LOCALE_TYPE.KO]: POLICY_FALLBACK,
    [LOCALE_TYPE.VI]: POLICY_FALLBACK_VN,
    [LOCALE_TYPE.KM]: {
      [POLICY.COMMUNITY_GUIDELINE]: policies.COMMUNITY_GUIDELINE_KH_KH,
      [POLICY.DRIVERS_CODE_OF_CONDUCT]: policies.DRIVERS_CODE_OF_CONDUCT_KH_KH,
      [POLICY.PRIVACY_POLICY_RIDER]: policies.PRIVACY_POLICY_RIDER_KH_KH,
      [POLICY.PRIVACY_POLICY_DRIVER]: policies.PRIVACY_POLICY_DRIVER_KH_KH,
      [POLICY.TADA_TERM_FOR_DRIVER]: policies.TADA_TERM_OF_DRIVER_KH_KH,
      [POLICY.TADA_TERM_FOR_RIDER]: policies.TADA_TERM_OF_RIDER_KH_KH,
    },
    [LOCALE_TYPE.ZH_CN]: POLICY_FALLBACK,
    [LOCALE_TYPE.ZH_TW]: POLICY_FALLBACK,
    [LOCALE_TYPE.TH]: POLICY_FALLBACK,
  },
  [REGION.KR]: {
    [LOCALE_TYPE.EN]: POLICY_FALLBACK,
    [LOCALE_TYPE.KO]: POLICY_FALLBACK,
    [LOCALE_TYPE.VI]: POLICY_FALLBACK_VN,
    [LOCALE_TYPE.KM]: POLICY_FALLBACK,
    [LOCALE_TYPE.ZH_CN]: POLICY_FALLBACK,
    [LOCALE_TYPE.ZH_TW]: POLICY_FALLBACK,
    [LOCALE_TYPE.TH]: POLICY_FALLBACK,
  },
  [REGION.TH]: {
    [LOCALE_TYPE.EN]: {
      [POLICY.COMMUNITY_GUIDELINE]: policies.COMMUNITY_GUIDELINE_EN_TH,
      [POLICY.DRIVERS_CODE_OF_CONDUCT]: policies.DRIVERS_CODE_OF_CONDUCT_EN_TH,
      [POLICY.PRIVACY_POLICY_RIDER]: policies.PRIVACY_POLICY_RIDER_EN_TH,
      [POLICY.PRIVACY_POLICY_DRIVER]: policies.PRIVACY_POLICY_DRIVER_EN_TH,
      [POLICY.TADA_TERM_FOR_DRIVER]: policies.TADA_TERM_OF_DRIVER_EN_TH,
      [POLICY.TADA_TERM_FOR_RIDER]: policies.TADA_TERM_OF_RIDER_EN_TH,
    },
    [LOCALE_TYPE.KO]: POLICY_FALLBACK,
    [LOCALE_TYPE.VI]: POLICY_FALLBACK_VN,
    [LOCALE_TYPE.KM]: POLICY_FALLBACK,
    [LOCALE_TYPE.ZH_CN]: POLICY_FALLBACK,
    [LOCALE_TYPE.ZH_TW]: POLICY_FALLBACK,
    [LOCALE_TYPE.TH]: {
      [POLICY.COMMUNITY_GUIDELINE]: policies.COMMUNITY_GUIDELINE_TH_TH,
      [POLICY.DRIVERS_CODE_OF_CONDUCT]: policies.DRIVERS_CODE_OF_CONDUCT_TH_TH,
      [POLICY.PRIVACY_POLICY_RIDER]: policies.PRIVACY_POLICY_RIDER_TH_TH,
      [POLICY.PRIVACY_POLICY_DRIVER]: policies.PRIVACY_POLICY_DRIVER_TH_TH,
      [POLICY.TADA_TERM_FOR_DRIVER]: policies.TADA_TERM_OF_DRIVER_TH_TH,
      [POLICY.TADA_TERM_FOR_RIDER]: policies.TADA_TERM_OF_RIDER_TH_TH,
    },
  },
  [REGION.HK]: {
    [LOCALE_TYPE.EN]: {
      [POLICY.COMMUNITY_GUIDELINE]: policies.COMMUNITY_GUIDELINE_EN_HK,
      [POLICY.DRIVERS_CODE_OF_CONDUCT]: policies.DRIVERS_CODE_OF_CONDUCT_EN_HK,
      [POLICY.PRIVACY_POLICY_RIDER]: policies.PRIVACY_POLICY_RIDER_EN_HK,
      [POLICY.PRIVACY_POLICY_DRIVER]: policies.PRIVACY_POLICY_DRIVER_EN_HK,
      [POLICY.TADA_TERM_FOR_DRIVER]: policies.TADA_TERM_OF_DRIVER_EN_HK,
      [POLICY.TADA_TERM_FOR_RIDER]: policies.TADA_TERM_OF_RIDER_EN_HK,
    },
    [LOCALE_TYPE.KO]: POLICY_FALLBACK,
    [LOCALE_TYPE.VI]: POLICY_FALLBACK_VN,
    [LOCALE_TYPE.KM]: POLICY_FALLBACK,
    [LOCALE_TYPE.ZH_CN]: POLICY_FALLBACK,
    [LOCALE_TYPE.ZH_TW]: POLICY_FALLBACK,
    [LOCALE_TYPE.TH]: POLICY_FALLBACK,
  },
};

export const REGION_BY_LOCALE = {
  [LOCALE_TYPE.EN]: [REGION.SG, REGION.HK],
  [LOCALE_TYPE.VI]: [REGION.VN],
  [LOCALE_TYPE.KM]: [REGION.KH],
  [LOCALE_TYPE.KO]: [REGION.KR],
  [LOCALE_TYPE.TH]: [REGION.TH],
  [LOCALE_TYPE.ZH_CN]: [REGION.SG],
  [LOCALE_TYPE.ZH_TW]: [REGION.HK],
};

export const AVAILABLE_POLICY_REGION = [REGION.SG, REGION.KH, REGION.VN, REGION.KR, REGION.TH, REGION.HK];

export const USER_MODEL = {
  Driver: 'Driver',
  Rider: 'Rider',
};

export const USER_MODEL_BY_PAGE_SECTION = {
  rider: USER_MODEL.Rider,
  driver: USER_MODEL.Driver,
};

export const FARE_TYPE = {
  APP_METERED_FARE: 0, // 앱에서 전송하는 gps를 통해 가격을 측정하는 상품
  CAR_METERED_FARE: 1, // 차에 있는 미터기를 통해 가격을 입력 받는 상품
  FIXED_FARE: 2, // destination이 있어서 미리 estimation 한 가격으로 만드는 상품
};

export const FARE_TYPE_STRINGS = {
  [FARE_TYPE.APP_METERED_FARE]: 'fare_type_app_metered_fare',
  [FARE_TYPE.CAR_METERED_FARE]: 'fare_type_car_metered_fare',
  [FARE_TYPE.FIXED_FARE]: 'fare_type_fixed_fare',
};

export const SUPPORT_REGIONS = [REGION.SG, REGION.TH, REGION.VN, REGION.KH, REGION.HK];

export const SUPPORT_REGION_POLYGON_PATHS = [
  {
    regionIdx: 0,
    region: REGION.SG,
    pathIdx: 64,
  },
  {
    regionIdx: 1,
    region: REGION.TH,
    pathIdx: 54,
  },
  {
    regionIdx: 2,
    region: REGION.VN,
    pathIdx: 45,
  },
  {
    regionIdx: 3,
    region: REGION.KH,
    pathIdx: 38,
  },
  {
    regionIdx: 4,
    region: REGION.HK,
    pathIdx: 401,
  },
];

export const POLYGON_MESSAGE_BY_REGION = {
  [REGION.SG]: '🇸🇬 Singapore',
  [REGION.KH]: '🇰🇭 Cambodia',
  [REGION.VN]: '🇻🇳 Vietnam',
  [REGION.TH]: '🇹🇭 Thailand',
  [REGION.HK]: '🇭🇰 Hong Kong',
};

export const MINIMUM_DESKTOP_WIDTH = 1001;

export const FACEBOOK_URL_BY_LOCALE = {
  [LOCALE_TYPE.EN]: 'https://www.facebook.com/TADASGP',
  [LOCALE_TYPE.KM]: 'https://www.facebook.com/TADAKHM',
  [LOCALE_TYPE.VI]: 'https://www.facebook.com/tada.global.vn',
};

export const FONT_FAMILY_BY_LOCALE = {
  [LOCALE_TYPE.EN]: 'Pretendard',
  [LOCALE_TYPE.VI]: 'Pretendard',
  [LOCALE_TYPE.KO]: 'Pretendard',
  [LOCALE_TYPE.JP]: 'Pretendard JP',
  [LOCALE_TYPE.KM]: 'Noto Sans Khmer',
  [LOCALE_TYPE.TH]: 'Noto Sans Thai',
  [LOCALE_TYPE.ZH_CN]: 'Noto Sans SC',
  [LOCALE_TYPE.ZH_TW]: 'Noto Sans TC',
};

export const SERVICE_REGIONS = [REGION.SG, REGION.KH, REGION.VN, REGION.TH, REGION.HK];
