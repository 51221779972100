import { ReactNode, useEffect } from 'react';

import { CustomMarker } from 'react-google-map-wrapper';

import { useLinearInterpolator } from '../hooks/useLinearInterpolator';

interface AnimatedMarkerProps {
  position: google.maps.LatLngLiteral;
  children: ReactNode;
}

function AnimatedMarker({ position, children }: AnimatedMarkerProps) {
  const { position: markerPosition, animateTo } = useLinearInterpolator({
    initialCurrent: position,
  });

  useEffect(() => {
    animateTo(position);
  }, [position]);

  if (!markerPosition.lat || !markerPosition.lng) {
    return null;
  }

  return (
    <CustomMarker
      lat={markerPosition.lat}
      lng={markerPosition.lng}
      ref={(overlay) => {
        overlay!.container.classList.add('car-marker');
      }}
    >
      {children}
    </CustomMarker>
  );
}

export default AnimatedMarker;
