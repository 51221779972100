import { useEffect, useRef, useState } from 'react';

import { changeLanguage } from 'i18next';
import { useTranslation } from 'react-i18next';
import { SheetRef } from 'react-modal-sheet';

import { usePrevious } from '../../../hooks/usePrevious';
import {
  ALL_TAXI_CAR_TYPES,
  BIKE_WITH_HUMAN_ICON_REQUIRED_REGIONS,
  CAR_TYPE,
  PRODUCT_TYPE,
  REGION,
  RIDE_STATUS,
  RIDE_STATUS_MESSAGE,
  SMALL_SHEET_SIZE,
  TAXI_TYPE,
  TOKEN_KEYS,
} from '../constant/constants';
import { CarType, ProductType, RideStatusType, TaxiType } from '../constant/types';
import { useLiveRideContext } from '../parts/LiveRideProvider';
import { RideMapHooksParam, SharedRideInfo, SharedRoutingResultDto } from '../types';
import { getCurrentDeviceUUID } from '../utils';
import { get } from '../utils/api';
import { getLanguageFromBrowser } from '../utils/language';

export const useCarIcon = (carType: CarType, productType: ProductType, region: string, taxiType: TaxiType | null): string => {
  const { images } = useLiveRideContext();

  const isBikeWithHumanImgRequired = (BIKE_WITH_HUMAN_ICON_REQUIRED_REGIONS as string[]).includes(region);

  if (region === REGION.HK && (ALL_TAXI_CAR_TYPES as CarType[]).includes(carType)) {
    if (taxiType === TAXI_TYPE.NEW_TERRITORIES_TAXI) {
      return images.mapHkTerritoriesTaxi;
    }

    if (taxiType === TAXI_TYPE.LANTAU_TAXI) {
      return images.mapTaxi;
    }

    return images.mapHkTaxi;
  }

  if (productType === PRODUCT_TYPE.EV) {
    return images.mapIontadaIcon;
  }

  const CAR_ICON_BY_CAR_TYPE = {
    [CAR_TYPE.SEDAN]: images.mapPh,
    [CAR_TYPE.VAN]: images.mapPh,
    [CAR_TYPE.BUSINESS_VAN]: images.mapPh,
    [CAR_TYPE.BLACK_SEDAN]: images.mapPh,
    [CAR_TYPE.TUKTUK]: images.mapTukTuk,
    [CAR_TYPE.BIKE]: isBikeWithHumanImgRequired ? images.mapBikeWithHuman : images.mapBike,
    [CAR_TYPE.REMORQUE]: images.mapRemoque,
    [CAR_TYPE.TAXI_STANDARD]: images.mapTaxi,
    [CAR_TYPE.TAXI_STANDARD_LARGE]: images.mapTaxi,
    [CAR_TYPE.TAXI_PREMIUM]: images.mapTaxi,
    [CAR_TYPE.TAXI_PREMIUM_LARGE]: images.mapTaxi,
    [CAR_TYPE.DELIVERY_VAN]: images.mapPh,
    [CAR_TYPE.DELIVERY_BIKE]: images.mapBike,
    [CAR_TYPE.ONION_TUKTUK]: images.mapETukTuk,
  };

  return CAR_ICON_BY_CAR_TYPE[carType] ?? CAR_ICON_BY_CAR_TYPE[CAR_TYPE.SEDAN];
};

export const useCarIconByRideInfo = () => {
  const { rideInfo } = useLiveRideContext();

  return useCarIcon(rideInfo!.carType, rideInfo!.productType!, rideInfo!.region, rideInfo!.taxiType);
};

export const useRideStatusMessage = (
  rideStatus: RideStatusType,
  rideInfo: SharedRideInfo,
  rideRouting: SharedRoutingResultDto | null,
  codStatus: number | null,
  additionalEstimtedTime?: number
) => {
  const { t } = useTranslation();

  if (rideStatus === RIDE_STATUS.RENTAL_STATUS_INUSE && codStatus === 10) {
    return t('text_message_cod', {
      riderName: rideInfo.riderName,
      time: rideRouting?.durationSeconds ? Math.ceil(rideRouting.durationSeconds / 60) + additionalEstimtedTime! : 0,
    });
  }

  const getMessage = RIDE_STATUS_MESSAGE[rideStatus];

  if (!getMessage) {
    return '';
  }

  const message = getMessage(rideInfo, rideRouting, additionalEstimtedTime);

  return t(message.key, { ...message?.data });
};

export const useRideMap = ({ rideStatus, rideInfo, codStatus, setRideInfo }: RideMapHooksParam) => {
  const { serverHost, urlId } = useLiveRideContext();
  const [rideRouting, setRideRouting] = useState<SharedRoutingResultDto | null>(null);
  const [isErrorInfo, setIsErrorInfo] = useState(false);
  const [isErrorRoute, setIsErrorRoute] = useState(false);
  const [isNARide, setIsNARide] = useState(false);

  useEffect(() => {
    const fetchRideInfo = async () => {
      try {
        const result: SharedRideInfo = await get(`${serverHost}/ridesvc/rides/${urlId}/show`, undefined, TOKEN_KEYS.ride, {
          Identifier: getCurrentDeviceUUID(urlId),
        });

        setRideInfo(result);
        setIsErrorInfo(false);
      } catch (error) {
        setIsErrorInfo(true);
      }
    };

    const fetchRideRouting = async () => {
      try {
        const result: SharedRoutingResultDto = await get(`${serverHost}/ridesvc/rides/${urlId}/routes`, undefined, TOKEN_KEYS.ride, {
          Identifier: getCurrentDeviceUUID(urlId),
        });

        setRideRouting(result);
        setIsErrorRoute(false);
      } catch (error) {
        setIsErrorRoute(true);
      }
    };

    (async function fetchRide() {
      await Promise.all([fetchRideInfo(), fetchRideRouting()]);
    })();
  }, [rideStatus, codStatus]);

  useEffect(() => {
    if (rideStatus !== RIDE_STATUS.RENTAL_STATUS_INUSE) {
      return;
    }

    const fetchRideRouting = async () => {
      try {
        const result: SharedRoutingResultDto = await get(`${serverHost}/ridesvc/rides/${urlId}/routes`, undefined, TOKEN_KEYS.ride, {
          Identifier: getCurrentDeviceUUID(urlId),
        });

        setRideRouting(result);
      } catch (error) {}
    };

    const interval = setInterval(async () => {
      await fetchRideRouting();
    }, 1000 * 30);

    return () => clearInterval(interval);
  }, [rideStatus]);

  useEffect(() => {
    if (!rideInfo) {
      return;
    }

    // destination point가 없거나 name, address 가 둘 다 없을 경우 N/A Ride로 체크
    setIsNARide(!rideInfo.destinationPoint || (!rideInfo.destinationPoint.name && !rideInfo.destinationPoint.address));
  }, [rideStatus, rideInfo]);

  useEffect(() => {
    const language = getLanguageFromBrowser();
    changeLanguage(language);
  }, []);

  return { rideRouting, isErrorInfo, isErrorRoute, isNARide };
};

export const useRideBottomSheet = ({ isCancledStatus, rideInfo }: { isCancledStatus: boolean; rideInfo: SharedRideInfo }) => {
  const sheetRef = useRef<HTMLDivElement | null>(null);
  const driverRef = useRef<HTMLDivElement | null>(null);
  const sheetModalRef = useRef<SheetRef | null>(null);

  const [snapIdx, setSnapIdx] = useState(0);
  const [initialSheetHeight, setInitialSheetHeight] = useState(105);
  const [initialDriverHeight, setInitialDriverHeight] = useState(70);
  const [smallSheetHeight, setSmallSheetHeight] = useState(SMALL_SHEET_SIZE);

  const prevSmallSheetSize = usePrevious(SMALL_SHEET_SIZE);

  const initialSnap = isCancledStatus ? 0 : 1;

  /*
    Address, Driver Name이 2줄 이상 넘어가는 케이스인 경우에 유동적으로 sheet 크기에 맞춰 Sheet의 snapPoint(시트 드래그가 걸리는 좌표)를 수정하기 위해서 아래 계산을 함

    BottomSheet의 height (최대크기),
    생략처리된 작은 sheet의 height + TH 드라이버인 경우 province 표기도 고려해서 20 더하기 (최대 2줄로 생략되어 보이는 화면)
    DriverProfile height에 여유있게 20 더하기 (DriverProfile만 보이는 화면)

    Sheet 라이브러리에 snapPoint가 내림차순이어야 하므로 sort 처리
  */
  const snapPoints = isCancledStatus
    ? [initialSheetHeight + (isCancledStatus ? 0 : 50)]
    : [initialSheetHeight, smallSheetHeight + (rideInfo.region === REGION.TH && rideInfo.province ? 20 : 0), initialDriverHeight + 20].sort(
        (a, b) => b - a
      );

  const onSnap = (idx: number) => {
    setSnapIdx(idx);
    if (sheetRef.current?.offsetHeight && !isCancledStatus) {
      if (sheetRef.current.offsetHeight < prevSmallSheetSize) {
        setSmallSheetHeight(sheetRef.current.offsetHeight);
      }
    }

    if (driverRef.current?.offsetHeight && initialDriverHeight !== driverRef.current.offsetHeight) {
      setInitialDriverHeight(driverRef.current.offsetHeight);
    }
  };

  const onCloseSheet = () => {
    if (isCancledStatus) {
      sheetModalRef.current?.snapTo(0);
      return;
    }

    sheetModalRef.current?.snapTo(2);
  };

  useEffect(() => {
    sheetRef.current?.offsetHeight !== undefined && setInitialSheetHeight(sheetRef.current.offsetHeight);
    driverRef.current?.offsetHeight !== undefined && setInitialDriverHeight(driverRef.current.offsetHeight);
    setSmallSheetHeight(SMALL_SHEET_SIZE);
  }, []);

  return {
    snapPoints,
    initialSnap,
    snapIdx,
    sheetHeight: snapPoints[0],
    sheetRef,
    sheetModalRef,
    driverRef,
    onSnap,
    onCloseSheet,
  };
};
