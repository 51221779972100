import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { useLiveRideContext } from './LiveRideProvider';
import RideInfo from './RideInfo';
import { Images } from '../types';

interface PointSummaryProps {
  images: Images;
  snapIdx: number;
}

const PointSummaryContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px 12px 8px 12px;
  border-radius: 4px;
  background-color: #f3f6f8;
`;

function PointSummary({ images, snapIdx }: PointSummaryProps) {
  const { rideInfo, isNARide } = useLiveRideContext();
  const { t } = useTranslation();
  return (
    <PointSummaryContainer>
      {rideInfo!.pickUpPoint && (
        <RideInfo address={(rideInfo!.pickUpPoint.name || rideInfo!.pickUpPoint.address) as string} image={images.pickup} snapIdx={snapIdx} />
      )}
      {rideInfo!.targetPointList?.map((point) => (
        <RideInfo address={(point.name || point.address) as string} image={images.additionalStop} key={point.googlePlaceId} snapIdx={snapIdx} />
      ))}
      <RideInfo
        address={
          (rideInfo!.destinationPoint && !isNARide ? rideInfo!.destinationPoint.name || rideInfo!.destinationPoint.address : t('label_na')) as string
        }
        image={images.dropOff}
        snapIdx={snapIdx}
      />
    </PointSummaryContainer>
  );
}

export default PointSummary;
