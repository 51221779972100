import { InfoWindow, Marker } from 'react-google-map-wrapper';

function MarkerWithInfoWindow({
  position,
  label,
  icon,
}: {
  position: google.maps.LatLngLiteral;
  label: string;
  icon: string | google.maps.Icon | google.maps.Symbol;
}) {
  return (
    <InfoWindow content={label} open>
      <Marker lat={position.lat} lng={position.lng} icon={icon} />
    </InfoWindow>
  );
}

export default MarkerWithInfoWindow;
