import qs from 'qs';
import { TOKEN_KEYS } from '../../../constants/constants';
import { get } from '../../../utils/api';

/*
Response Type

{
  id: number
  region: Region
  city: City? (nullable)
  appAuthority: AppAuthority (RIDER_APP, DRIVER_APP)
  enabled: Boolean
  contents: List<SupportPageContentDTO>
  createdAt: string;
  updatedAt: string;
}

SupportPageContentDto {
  id: UUID
  locale: String
  description: String
}
*/

export const getSupportPageInfo = async (region, city, appAuthority, locale) => {
  const query = qs.stringify({
    region,
    city,
    appAuthority,
    locale,
  });
  return await get(`/cssvc/supportpage/find?${query}`, undefined, TOKEN_KEYS.faq);
};
