import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  .gm-style .gm-style-iw-tc {
    display: none;
  }

  .gm-style .gm-style-iw-d {
    display: inline !important;
  }

  .gm-style .gm-style-iw-c {
    padding: 6px 10px !important;
    border-radius: 2px !important;
    background: #fff !important;
    box-shadow: 0px 6px 24px 0px #00000033 !important;
    font-size: 12px !important;
    line-height: 16px !important;
    font-weight: 400 !important;

    > .gm-style-iw-d {
      overflow: unset !important;
      > div {
      text-overflow: ellipsis !important;
      overflow: hidden !important;
      display: -webkit-box !important;
      -webkit-box-orient: vertical !important;
      -webkit-line-clamp: 2 !important;
    }
    }
  }

  div.gm-style-iw > .gm-style-iw-chr {
    > .gm-style-iw-ch {
      padding-top: 0px !important;
    }

    > button {
      display: none !important;
    }
  }
`;

export const DriverMarkerGlobalStyle = createGlobalStyle`
  .car-marker {
    transform: translate(-50%, -50%);
    > img {
    transition: rotate .5s linear 0s;
  }
  } 
`;
