import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import FaqTable from './tabContent'
import { compose } from 'recompose'
import { withTranslation } from 'react-i18next'

const DRIVER_SAFETY_AND_ACCIDENT = 'driver_safety_and_accident'
const contents = {
  name: DRIVER_SAFETY_AND_ACCIDENT,
  size: 5
}

class TadaDriverTabs extends Component {
  constructor (props) {
    super(props)

    this.renderDriverContents = this.renderDriverContents.bind(this)
  }

  renderDriverContents () {
    const driverDetail = []
    for (let k = 0; k < contents.size; k++) {
      if (this.props.t(`${contents.name}${k}_question`) !== 'N/A') {
        driverDetail.push(`${contents.name}${k}`)
      }
    }
    return driverDetail
  }

  render () {
    return <FaqTable faqTitle={this.renderDriverContents()} />
  }
}

export default compose(withTranslation())(withRouter(TadaDriverTabs))
