import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { useTadaRiderAppLink } from './hooks/branch';
import { useLiveRideContext } from './parts/LiveRideProvider';

const ExpiredRideContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  padding: 24px 12px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;

  > .expired-ride {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: auto;

    > img {
      width: 106px;
      height: 106px;
    }

    > .content {
      display: flex;
      width: 70%;
      margin: 0;
      margin-top: 20px;
      text-align: center;
      font-size: 20px;
      font-weight: 800;
      line-height: 24px;
    }

    > .sub-content {
      display: flex;
      width: 80%;
      margin: 0;
      margin-top: 6px;
      padding: 0 14px;
      text-align: center;
      font-size: 14px;
      font-weight: 700;
      line-height: 20px;
    }
  }

  > button {
    display: flex;
    width: 90%;
    height: 48px;
    margin-top: auto;
    padding: 10px 16px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 0;
    border-radius: 6px;
    background-color: #ffc400;
    > p {
      margin: 0;
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
    }
  }
`;

function ExpiredRide() {
  const { t } = useTranslation();
  const { branchKey, images } = useLiveRideContext();
  const { openLink } = useTadaRiderAppLink(branchKey);

  return (
    <ExpiredRideContainer>
      <div className='expired-ride'>
        <img src={images.expiredLinkIcon} alt='expiredLink' />
        <p className='content'>{t('text_live_ride_expired_content')}</p>
        <p className='sub-content'>{t('text_live_ride_expired_sub_content')}</p>
      </div>
      <button onClick={openLink}>
        <p>{t('text_try_tada')}</p>
        <p>{t('text_book_first_trip')}</p>
      </button>
    </ExpiredRideContainer>
  );
}

export default ExpiredRide;
