import React, { useEffect, useState } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import styled from 'styled-components'
import triangle from '../../../assets/images/tada/triangle.png'
import qs from 'qs'
import { getLocaleContent } from '../FaqPage/hooks'
import { decodeDescription } from '../../../utils/MyUtils'
import Spinner from 'react-spinkit'
import { mobileCheck } from '../../../utils/MyUtils'
import { getLocale } from '../../../selectors'
import { useSelector } from 'react-redux'
import SafetyTable from './SafetyTable'
import { useSearch } from '../../../hooks/router'
import { SAFETY_PAGE_ITEMS } from './constants'

const DescriptionContainer = styled.div`
  white-space: pre-line;
  word-break: break-word;
`

const DropDown = styled.div`
  position: relative;
  display: inline-block;
  font-size: 14px;
  cursor: pointer;
  text-decoration: none;
  overflow: visible;
  @media (max-width: 1000px) {
    width: 111%;
  }
`

const DropDownImgWrapper = styled.div`
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 16px;
  
  background-color: #fff;
  min-width: 553px;
  width: max-content;
  height: 45px;
  box-sizing: border-box;
  border: solid 1px #979797;
  position: relative;
  cursor: pointer;
  color: #000;
  padding-left: 16px;
  padding-right: 50px;
  overflow: visible;
  &:after,
  &::after {
    position: absolute;
    width: 18px;
    height: 10px;
    top: 50%;
    margin-top: -5px;
    right: 20px;
    content: ' ';
    background-image: url(${triangle});
    background-repeat: no-repeat;
    background-position: ${props => (props.isDriverAppShow ? '-19px' : '0')} 0;
  }
  @media (max-width: 1000px) {
    width: 90%;
    height: 45px;
    min-height: 45px;
    line-height: 26px;
    min-width: unset;
    margin-top: 35px;
    padding: 12px 50px 12px 16px;
    
    font-size: 16px;
    font-weight: 400;
  }
  @media (max-width: 330px) {
    font-size: 16px;
  }
`

const DropDownContent = styled.div`
  max-height: ${props => (props.isMobile ? `calc(100vh - 410px)` : `800px`)};
  position: absolute;
  background-color: #ffffff;
  box-sizing: border-box;
  z-index: 1;
  width: 100%;
  overflow: scroll;
  @media (max-width: 1000px) {
    width: 90%;
  }
`

const DropDownContentItem = styled.button`
  display: block;
  border: none;
  outline: 0;
  padding-left: 16px;
  box-sizing: border-box;
  cursor: pointer;
  width: 100%;
  min-height: 45px;
  font-size: 16px;
  font-weight: 400;
  
  color: #000;
  background-color: #fff;
  border: solid 1px #979797;
  border-top: none;
  text-align: left;
  overflow: visible;
  &:hover {
    background-color: #eaba28;
    color: #fff;
  }
  @media (max-width: 1000px) {
    
    font-size: 16px;
  }
  @media (max-width: 330px) {
    font-size: 16px;
  }
`

export const SubCategoryWrapper = styled.div`
  margin-top: 15px;

  > div + div {
    margin-top: 10px;
  }

  > div > ul {
    margin-top: 10px;

    > li {
      margin: 6px 0;
    }
  }
`

function SafetyTabs({ item, topicList, currentSection, isTopicFetching }) {
  const history = useHistory()
  const search = useSearch()
  const match = useRouteMatch()
  const selectedLocale = useSelector(getLocale)

  const [selectedTopicIdx, setSelectedTopicIdx] = useState(null)
  const [isDropdownShow, setIsDropdownShow] = useState(false)

  const { title, section: tabSection, id } = item
  const currentSectionStr = SAFETY_PAGE_ITEMS.find(page => page.id === currentSection).section

  const handleSelectTopic = index => {
    history.replace({ search: qs.stringify({ ...search, topicId: topicList[index].id }) })
    setSelectedTopicIdx(index)
  }

  useEffect(() => {
    if (!isTopicFetching) {
      const index = search.topicId ? topicList.findIndex(topic => topic.id === +search.topicId) : 0
      setSelectedTopicIdx(index === -1 ? 0 : index)
    }
  }, [isTopicFetching, topicList])

  useEffect(() => {
    if (search.topicId !== undefined && topicList) {
      const index = topicList.findIndex(topic => topic.id === +search.topicId)
      index !== -1 && setSelectedTopicIdx(index)
    }
  }, [search.topicId, topicList])

  return (
    <div>
      <DropDown onClick={() => setIsDropdownShow(prev => !prev)}>
        <DropDownImgWrapper isTopicFetching={isTopicFetching} isDriverAppShow={isDropdownShow}>
          {isTopicFetching && <Spinner className="topic__loading_bar" name="circle" color="black" fadeIn="none" />}
          {getLocaleContent(topicList[selectedTopicIdx], selectedLocale).topicName}
        </DropDownImgWrapper>
        {isDropdownShow && (
          <DropDownContent isMobile={mobileCheck()}>
            {topicList.map((topic, index) => {
              return (
                <DropDownContentItem key={topic.id} onClick={() => handleSelectTopic(index)}>
                  {getLocaleContent(topic, selectedLocale).topicName}
                </DropDownContentItem>
              )
            })}
          </DropDownContent>
        )}
      </DropDown>
      {title.includes(match.params.section) &&
        topicList[selectedTopicIdx] &&
        Array.isArray(topicList[selectedTopicIdx].contents) &&
        topicList[selectedTopicIdx].contents[0].description && (
          <SubCategoryWrapper>
            {topicList[selectedTopicIdx].contents[0].dataType === 'HTML' ? (
              <div
                dangerouslySetInnerHTML={{
                  __html: decodeDescription(getLocaleContent(topicList[selectedTopicIdx], selectedLocale).description)
                }}
              />
            ) : (
              <DescriptionContainer>
                {getLocaleContent(topicList[selectedTopicIdx], selectedLocale).description}
              </DescriptionContainer>
            )}
          </SubCategoryWrapper>
        )}
      <SafetyTable topicData={topicList[selectedTopicIdx]} tabSection={tabSection} currentSection={currentSectionStr} />
    </div>
  )
}

export default SafetyTabs
