// !!! Do not edit this file directly.
// !!! 이 파일을 직접 수정하지 말고 sheet 수정 후 script 실행할 것
export default {
  translations: {
    sg_taxi: {
      'ComfortDelgro & Citycab': 'ComfortDelGro & CityCab',
      Premier: 'Premier',
      Prime: 'Prime',
      'SMRT Cab': 'SMRT',
      'Trans-cab': 'Trans-Cab',
      'Yellow-Top': 'Yellow-Top',
      HDT: 'HDT',
    },
    ph_taxi_plate: 'ป้ายทะเบียนรถแท็กซี่',
    desc_mdt_must: 'แสดงรูปถ่ายแท็กซี่ MDT (จำเป็น)',
    desc_mdt_name: 'ชื่อผู้ขับ',
    desc_mdt_plate: 'ป้ายทะเบียนรถ',
    desc_mdt_date: 'วันที่ล่าสุด (รูปที่ถ่ายเมื่อล่าสุด)',
    btn_save_next: 'บันทึกและไปต่อ',
    btn_later: 'ไว้ก่อน',
    repeat_password: 'ใส่รหัสผ่านอีกครั้ง',
    phone_number: 'หมายเลขโทรศัพท์',
    digit_pin_number: 'รหัส PIN 4 หลัก',
    refferal_code: 'รหัสแนะนำ',
    singapore: 'สิงคโปร์',
    country: 'ประเทศ',
    signup: {
      introduce1:
        'ยินดีต้อนรับสู่หน้าลงทะเบียนไดรเวอร์ TADA โปรดระบุข้อมูลต่อไปนี้เพื่อเป็นพันธมิตรขับเคลื่อนของ TADA กรุณาส่งเอกสารทั้งหมดเพื่อกรอกใบสมัคร หากคุณพบปัญหาใด ๆ ในการส่งโปรดติดต่อเราที่',
      email: 'hotro@tada.global',
      introduce2: 'และเราจะติดต่อกลับภายใน 1 วันทำการ สามารถอัพโหลดเอกสารได้ทั้งในรูปแบบรูปภาพหรือ .pdf',
      submit: 'ยืนยัน',
      login_here: 'ลงทะเบียน',
      refferal_code: 'รหัสอ้างอิง (ไม่บังคับ)',
      pin_number: 'หมายเลขพิน',
      mobile_number: 'เบอร์โทรศัพท์',
      agree: 'ยอมรับ',
      my_information: 'ข้อมูลของฉัน',
    },
    driver: {
      available_after_complete: 'สามารถใช้งานได้หลังจากลงทะเบียนสำเร็จ',
      filled_out_zero: 'คุณได้กรอกข้อมูล 0 จาก {{totalSteps}} ช่องแล้ว',
      my_page: 'หน้าของฉัน',
      application: 'ใบสมัครไดร์เวอร์',
      application_taxi: 'ใบสมัครคนขับรถแท็กซี่',
      application_tuktuk: 'แอพพลิเคชั่นขับรถตุ๊กตุ๊ก',
      application_bike: 'ใบสมัครคนขับจักรยานยนต์',
      application_not_complete: 'ใบสมัครของคุณยังไม่เสร็จสมบูรณ์',
      filled_out: 'คุณได้กรอกข้อมูล {{doneSteps}} จาก {{totalSteps}} ช่องแล้ว',
      being_reviewed: 'ใบสมัครของคุณอยู่ระหว่างการตรวจสอบ',
      information: 'ข้อมูลส่วนบุคคล',
      name: 'ชื่อ',
      mobile_number: 'เบอร์มือถือ',
      refferal_code: 'รหัสอ้างอิงของฉัน',
      logout: 'ออกจากระบบ',
      application_complete: 'ใบสมัครของคุณเสร็จสมบูรณ์แล้ว เราได้ส่งอีเมลยืนยันไปให้คุณแล้ว',
      application_not_succesful: 'การสมัครของคุณไม่สำเร็จ โปรดตรวจสอบอีเมลของคุณและส่งเอกสารที่ระบุอีกครั้ง',
    },
    judicial_record: 'บันทึกการพิจารณาคดี',
    vehicle_registration: 'ทะเบียนรถ',
    tuktuk_registration_card: 'บัตรลงทะเบียนรถตุ๊กตุ๊ก ',
    certificate_of_registry: 'หนังสือรับรองการจดทะเบียน',
    commercial_insurance_certificate: 'หนังสือรับรองการประกันภัย\n',
    contract_car_badge: 'สัญญาเช่ารถ',
    national_id: 'บัตรประจำตัวประชาชน',
    identity_card_number: 'เลขบัตรประจำตัวประชาชน',
    license_plate_number: 'หมายเลขป้ายทะเบียน',
    car_make: 'ยี่ห้อรถ',
    car_make_ex: 'ยี่ห้อรถยนต์ (เช่น BMW)',
    car_model_ex: 'รุ่นรถ (เช่น QM3)',
    bike_make: 'ยี่ห้อรถจัรยานยนต์',
    bike_model: 'รุ่นรถจักรยานยนต์',
    desc_sharp_clear_photo: 'จะต้องคมชัดและชัดเจน',
    valid_driving_license: 'ต้องอัปโหลดใบอนุญาตขับขี่ที่ถูกต้อง (เช่น ยังไม่หมดอายุ)',
    valid_document: 'ต้องอัปโหลดเอกสารที่ถูกต้อง (เช่น ยังไม่หมดอายุ)',
    valid_nric: 'ต้องอัปโหลด NRIC ที่ถูกต้อง (เช่น ยังไม่หมดอายุ)',
    valid_registration_card: 'ต้องอัปโหลดบัตรลงทะเบียนที่ถูกต้อง (เช่น ยังไม่หมดอายุ)',
    waring_acra_form: 'อัปโหลด 3 หน้าแรกของเอกสารที่แสดงว่าคุณประกอบอาชีพอิสระและมีบริษัทจดทะเบียนเพื่อวัตถุประสงค์ด้านภาษีเงินได้',
    waring_bike_form: 'ป้ายทะเบียนต้องตรงกับเอกสารทั้งหมด',
    acra_form_skip: 'ขั้นตอนนี้ไม่จำเป็นสำหรับผู้ที่วางแผนจะใช้บริการรถเช่า',
    upload_the_backside: 'อัปโหลดด้านหลังที่มีข้อมูลรถ',
    gps_tracking_device: 'ประเภทเชิงพาณิชย์พร้อมอุปกรณ์ติดตาม GPS',
    license_plate: 'หมายเลขป้ายทะเบียนในการประกันภัยเชิงพาณิชย์ของคุณควรตรงกับหมายเลขทะเบียนและทะเบียนของคุณ',
    national_id_uploaded: 'ต้องอัปโหลดบัตรประจำตัวประชาชนที่ถูกต้อง (เช่น ยังไม่หมดอายุ)',
    upload_driving_license_front: 'อัพโหลดใบขับขี่ (ด้านหน้า)',
    upload_driving_license_back: 'อัพโหลดใบขับขี่ (ด้านหลัง)',
    upload_judicial_record: 'อัพโหลดเอกสารประวัติอาชญากรรม (ด้านหน้า)',
    upload_vehicle_registration: 'อัพโหลดทะเบียนรถ (ด้านหลัง)',
    upload_certificate_of_registry: 'อัพโหลดหนังสือรับรองการจดทะเบียน (ด้านหลัง)',
    upload_insurance: 'อัพโหลดประกันภัย (ด้านหน้า)',
    upload_insurance_certificate: 'อัพโหลดใบรับรองการประกันภัย (ด้านหลัง)',
    upload_nric_front: 'อัปโหลด NRIC (ด้านหน้า)',
    upload_nric_back: 'อัปโหลด NRIC (ด้านหลัง)',
    upload_file: 'อัพโหลดไฟล์',
    upload_pdvl_front: 'อัปโหลด PDVL (ด้านหน้า)',
    upload_decal: 'สติ๊กเกอร์รถยนต์ส่วนบุคคล',
    upload_car_contract_badge: 'อัพโหลดตราสัญญารถยนต์ (ด้านหน้า)',
    upload_national_id: 'อัพโหลดบัตรประจำตัวประชาชน (ด้านหน้า)',
    upload_identity_card_number_front: 'อัพโหลดหมายเลขบัตรประจำตัวประชาชน (ด้านหน้า)',
    upload_identity_card_number_back: 'อัพโหลดหมายเลขบัตรประจำตัวประชาชน (ด้านหลัง)',
    upload_bike_picture_front: 'อัพโหลดภาพรถจักรยานยนต์โดยรวม',
    upload_bike_picture_back: 'อัปโหลดภาพรถจักรยานยนต์อีกภาพที่แสดงให้เห็นรถอย่างน้อยครึ่งหนึ่งพร้อมป้ายทะเบียน',
    uploaded_profile_picture: 'อัพโหลดรูปโปรไฟล์',
    upload_tuktuk_registration_card: 'อัพโหลดบัตรลงทะเบียนรถตุ๊กตุ๊ก (ด้านหน้า)',
    upload_profile_picture: 'หากคุณข้ามขั้นตอนนี้ คุณจะได้รับการเตือนให้อัปโหลดรูปโปรไฟล์อีกครั้งก่อนที่จะเริ่มยอมรับการเดินทาง',
    no_wearing_of_accessories: 'ห้ามสวมเครื่องประดับ (เช่น หมวก แว่นกันแดด ฯลฯ) ที่อาจปกปิดใบหน้าของคุณ',
    please_white_background: 'กรุณาใช้พื้นหลังสีขาว',
    please_upload_photo: 'กรุณาอัพโหลดภาพที่ชัดเจน',
    taxi_license_plate_num: 'หมายเลขป้ายทะเบียนรถแท็กซี่',
    section_taxi_details: 'รายละเอียดรถแท็กซี่',
    desc_upload_taxi_front: 'อัพโหลดภาพถ่ายด้านหน้าของรถแท็กซี่',
    desc_upload_mdt: 'อัพโหลดรูปภาพของ Taxi MDT',
    reset_password_success: 'รหัสผ่านบัญชีของคุณ ({{email}}) มีการเปลี่ยนแปลงอย่างปลอดภัย',
    change_password: 'การเปลี่ยนรหัสผ่าน',
    forgot_password: 'ค้นหารหัสผ่าน',
    reset_password: 'รีเซ็ตรหัสผ่าน',
    error_password_length: 'รหัสผ่านต้องมีความยาวระหว่าง 6~20 ตัวอักษร',
    faq: 'คำถามที่พบบ่อย',
    login: 'เข้าสู่ระบบ',
    login_required: 'ต้องเข้าสู่ระบบ',
    logout: 'ออกจากระบบ',
    sign_up: 'ลงชื่อ',
    taxi_sign_up: 'ลงทะเบียนคนขับรถแท็กซี่',
    my_page: 'หน้าของฉัน',
    email: 'อีเมล',
    email_placeholder: 'กรอกที่อยู่อีเมล์ของคุณ',
    email_used: 'อีเมล {{email}} มีอยู่แล้ว',
    password: 'รหัสผ่าน',
    password_placeholder: 'ใส่รหัสผ่านของคุณ',
    password_confirm: 'ยืนยันรหัสผ่าน',
    password_confirm_placeholder: 'ป้อนรหัสผ่านอีกครั้ง',
    new_password_placeholder: 'ใส่รหัสผ่านใหม่',
    verify_mail_title: 'ตรวจสอบอีเมลเพื่อทำการยืนยัน',
    verify_mail_desc: 'ตรวจสอบกล่องจดหมายของคุณเพื่อดูอีเมลยืนยัน ({{อีเมล}})',
    privacy_policy: 'นโยบายความเป็นส่วนตัว',
    terms_and_conditions: 'ข้อตกลงและเงื่อนไข',
    button_confirm: 'ยืนยัน',
    button_cancel: 'ยกเลิก',
    locale: {
      en: 'English',
      ko: '한국어',
      zh_hans: '简体中文',
      zh_hant: '繁體中文',
      vi: 'Tiếng Việt',
      km: 'ភាសាខ្មែរ',
      th: 'ภาษาไทย',
      'zh-CN': '简体中文',
      'zh-TW': '繁體中文',
      jp: '日本語',
    },
    btn_save: 'บันทึก',
    first_name: 'ชื่อจริง',
    last_name: 'นามสกุล',
    phone_placeholder: 'หมายเลขโทรศัพท์',
    wrong_number_request: '{{driverPhone}} หมายเลขโทรศัพท์ผิด โปรดใส่หมายเลขอื่น',
    photo_file_format: '.jpg, .png',
    delete_image: 'ลบรูปภาพ',
    upload_image: 'อัพโหลดรูปภาพ',
    agree_policy_please: 'โปรดยอมรับข้อกำหนดและเงื่อนไขทั้งหมด',
    button_resend_email_verify: 'ส่งอีเมล์ยืนยันอีกครั้ง',
    success_resend_email_verify: 'ส่งอีเมลยืนยันแล้ว',
    optional_brackets: '(ไม่จำเป็น)',
    error_time_out: 'การเชื่อมต่อหมดเวลา กรุณาลองใหม่อีกครั้ง',
    error_captcha_not_valid: 'ReCaptcha ไม่ถูกต้อง',
    car_model: 'รุ่นรถ',
    car_photo_warn: 'ภาพถ่ายด้านหน้ารถแสดงเลขทะเบียนรถอย่างชัดเจน',
    plate_number: 'ป้ายทะเบียน',
    banking_details: 'รายละเอียดเกี่ยวกับธนาคาร',
    bank_account_holder_name_failed: 'กรุณาลองอีกครั้ง อย่าใช้อักขระพิเศษและตัวเลข',
    bank_account_holder_name: 'ชื่อเจ้าของบัญชี',
    bank_select_name: 'ธนาคาร',
    bank_account_number: 'เลขบัญชี',
    bank_branch: 'สาขา',
    bank_skip_warn:
      'หากคุณข้ามขั้นตอนนี้ คุณจะได้รับการเตือนให้ระบุรายละเอียดธนาคารของคุณอีกครั้งเมื่อคุณเริ่มรับรถโดยสารและต้องการถอนรายได้ของคุณออก',
    warning_when_leaving_without_saving: 'ยืนยันการออก',
    upload_bank_picture: 'อัพโหลดรูปภาพรายการเดินบัญชีของธนาคาร',
    bank_photo_warn:
      'โปรดอัปโหลดรูปภาพข้อมูลบัญชีธนาคาร (จากใบเสร็จรับเงิน ATM หรือรายการเคลื่อนไหวของบัญชีธนาคาร) ที่แสดงชื่อบัญชีและหมายเลขบัญชีธนาคาร',
    profile_picture: 'รูปโปรไฟล์',
    nric: 'NRIC',
    driving_license: 'ใบขับขี่',
    acra_bizfile: 'ACRA Bizfile',
    insurance_certificate: 'ใบรับรองการประกันภัย',
    error_password_confirm: 'รหัสผ่านไม่ตรงกัน',
    phone_used: 'หมายเลขโทรศัพท์นี้ถูกใช้ไปแล้ว',
    send_pin: 'ส่งรหัส PIN',
    edit: 'แก้ไข',
    tada: {
      home: '<p>ขอต้อนรับสู่ยุคใหม่ของการเรียกรถ ยินดีต้อนรับสู่ TADA<br></p>\n<p>ก้าวเข้าสู่โลกใหม่กับ TADA - ที่การเรียกรถถูกกำหนดนิยามใหม่<br></p>\n<p>ที่คนขับสามารถเติบโตได้โดยไม่มีการหักค่าคอมมิชชั่น<br></p>\n<p>ที่ผู้โดยสารจะได้รับประโยชน์จากราคาที่โปร่งใส ยุติธรรม และเข้าถึงได้ พร้อมการเดินทางที่ปลอดภัย ทุกเวลาและทุกสถานที่<br></p>\n<p>ร่วมเดินทางในเส้นทางแห่งการเปลี่ยนแปลง - ที่มีความสะดวกสบายมาพร้อมกับความยุติธรรม ความน่าเชื่อถือ และความไว้วางใจในการสร้างนิยามใหม่ของประสบการณ์การเดินทางของคุณ</p>',
      driver: {
        app: {
          name_1: 'ได้คนขับแล้ว',
          name_2: 'มาถึงแล้ว',
          name_3: 'กำลังเดินทาง',
          name_4: 'ส่งและชำระเงิน',
          waiting: 'ไดรเวอร์ TADA เวอร์ชัน iOS อยู่ระหว่างการตรวจสอบ\nคุณจะสามารถดาวน์โหลดแอปได้อย่างง่ายดายในเร็ว ๆ นี้!',
        },
        info: {
          title_1: 'ที่แรกและที่เดียว ที่ค่าคอมมิชชั่น 0% สำหรับคนขับ',
          content_1:
            'TADA ไม่คิดค่าคอมมิชชั่นใดๆ ยกเว้นค่าธรรมเนียมแพลตฟอร์มและค่าธรรมเนียมการทำธุรกรรมสำหรับบัตรเดบิต/เครดิต คนขับจะได้รับค่าโดยสารเต็มจำนวน และเรายังคงยึดมั่นในคำสัญญานี้',
          title_2: 'การลงทะเบียนคนขับอย่างง่าย',
          content_2:
            'เราได้ทำให้ขั้นตอนการลงทะเบียนง่ายขึ้น  \nเพื่อความสะดวกสบายของคุณ\nนอกจากนี้เรายังมีขั้นตอนการลงทะเบียนที่ง่ายขึ้น\nสำหรับไดรเวอร์ประเภทอื่นอีกด้วย',
          title_3: 'ระบบการจับคู่แบบขั้นสูง',
          content_3:
            'TADA วิเคราะห์รูปแบบการให้บริการของผู้ขับขี่ \nอ้างอิงจากข้อมูลขนาดใหญ่และนำเสนอ  \nระบบการจับคู่ส่วนบุคคลเช่นนั้น  \nผู้โดยสารสามารถจับคู่กับผู้ขับขี่ได้มากขึ้น',
          title_4: 'บริการรับผู้โดยสารแบบไม่มีรอยต่อ',
          content_4:
            'คุณสามารถรับสายอื่นได้  \nขณะที่ผู้โดยสารกำลังเดินทาง\nเมื่อใกล้ถึงที่หมายแล้ว  \nคุณจะสามารถรับงานได้อย่างต่อเนื่องโดยไม่ชักช้า',
          title_5: 'ข้อมูลการดำเนินงานที่เข้าถึงได้ง่าย',
          content_5:
            'เรามีฟังก์ชั่นแดชบอร์ดที่ \nให้คุณตรวจสอบข้อมูลได้อย่างสะดวก  \nเช่น ประวัติงานของคุณแบบรายวันและรายสัปดาห์ รายได้ปัจจุบัน และอื่นๆ',
          title_6: 'รางวัล:  \nแรงจูงใจในการขับขี่อย่างปลอดภัย',
          content_6: 'หากคุณให้บริการที่น่าประทับใจแก่ผู้ขับขี่  \nเราจะให้คะแนน MVL ให้คุณนอกเหนือ  \nจากค่าบริการ',
        },
        driverappdownload: 'ดาวน์โหลดแอพไดร์เวอร์',
      },
      rider: {
        app: {
          name_1: 'คำขอผู้โดยสาร',
          name_2: 'ได้คนขับแล้ว',
          name_3: 'กำลังเดินทาง',
          name_4: 'การชำระเงินและข้อเสนอแนะ',
        },
        info: {
          title_1: 'เดินทางเร็วขึ้น\nสำหรับผู้โดยสาร',
          content_1:
            'เราจับคู่คุณกับคนขับอย่างรวดเร็ว ด้วยเทคโนโลยีการจับคู่ที่ยอดเยี่ยม\nและเรายังสามารถจับคู่คุณกับคนขับที่สามารถมาถึงได้เร็วที่สุด เพื่อการเดินทางที่ราบรื่นและปลอดภัย',
          title_2: 'ค่าโดยสารที่ดีกว่า\nและเข้าถึงได้',
          content_2: 'การเดินทางที่ประหยัดและเชื่อถือได้ \nด้วยนโยบายไม่คิดค่าคอมมิชชั่นของเรา',
          title_3: 'จับคู่\nในช่วงเวลาเร่งด่วน',
          content_3: 'เราจับคู่คุณกับคนขับ\nไม่ว่าจะเป็นช่วงเวลาเร่งด่วน ฝนตก หรือแดดออก',
          title_4: 'ครอบคลุมพื้นที่ให้บริการอย่างกว้างขวาง',
          content_4: 'ไม่ว่าคุณจะไปที่ไหน เราจะจับคู่คุณกับคนขับเพื่อพาคุณไปถึงจุดหมายอย่างปลอดภัย',
          title_5: 'การชำระเงินที่รวดเร็วและง่ายดาย',
          content_5: 'คุณสามารถเลือกชำระเงินด้วยเงินสด หรือบัตรเดบิต/เครดิต\nเลือกเชื่อมโยงบัตรเดบิต/เครดิตที่คุณชื่นชอบ\nเพื่อการชำระเงินที่ราบรื่น',
          title_6: 'การจัดการตรวจสอบอย่างรอบคอบ',
          content_6: 'คุณสามารถให้ข้อเสนอแนะแก่คนขับ\nเราจะจัดการคนขับตามข้อเสนอแนะแบบของคุณ\nเพื่อจับคู่คุณกับคนขับที่เชื่อถือได้',
        },
        riderappdownload: 'ดาวน์โหลดแอปผู้โดยสาร',
      },
      contact: {
        contact_us: 'ติดต่อเรา',
        name: {
          label: '◉ ชื่อ หรือชื่อเล่น (จำเป็น)',
          placeholder: '(30 ตัวอักษร)',
        },
        email: {
          label: '◉ ที่อยู่อีเมลของคุณ (จำเป็น)',
          example: '(เช่น mvler@mvlchain.io)',
          placeholder: {
            name: 'mvler',
            domain: 'mvlchain.io',
          },
        },
        title: {
          label: '◉ ชื่อเรื่อง (จำเป็น)',
          placeholder: '(30 ตัวอักษร)',
        },
        content: {
          label: '◉ คำอธิบาย (จำเป็น)',
          placeholder:
            "[ประกาศ] \\n\\n-สูงสุด 2,000 ตัวอักษร \\n-โปรดเขียนโดยละเอียด \\n-โปรดงดเว้นจากการใช้คำหยาบคาย \\n-โปรดงดเว้นจากการอัปโหลดข้อมูลส่วนบุคคลที่ละเอียดอ่อน (ID, รหัสผ่าน, ข้อมูลติดต่อ ฯลฯ) \\n -หากเราต้องการข้อมูลเพิ่มเติม เราจะติดต่อคุณ \\n-โดยการแตะที่ปุ่ม 'ส่ง' ด้านล่าง แสดงว่าคุณยอมรับและยินยอมให้เปิดเผยข้อมูลตำแหน่ง, IP, OS ฯลฯ  ",
        },
        submit: {
          request: {
            submit: 'ส่ง',
            title: 'คุณแน่ใจหรือไม่ว่าต้องการส่ง?',
            desc: '(ที่อยู่อีเมล:',
            review: 'ทบทวน',
          },
          confirm: {
            title: 'การลงทะเบียนเสร็จสมบูรณ์',
            desc: 'เราจะรีบตอบกลับคุณหลังจากการตรวจสอบ',
            confirm: 'ยืนยัน',
          },
        },
      },
    },
    title: {
      about: 'เกี่ยวกับ',
      driver: 'คนขับรถ',
      rider: 'ผู้โดยสาร',
      contact: 'ติดต่อ',
      faq: 'คำถามที่พบบ่อย',
      powerd_by_mvl: '<span>Powered by</span> MVL',
      terms: 'เงื่อนไข',
      privacy: 'ความเป็นส่วนตัว',
      code_of_contact: 'จรรยาบรรณของผู้ขับขี่',
    },
    faq_driver_app: 'แอพคนขับ',
    faq_rider_app: 'แอพผู้โดยสาร',
    driver_safety_and_accident0_question: 'ฉันประสบอุบัติเหตุ',
    driver_safety_and_accident0_answer: '\n',
    driver_safety_and_accident1_question: 'การทำร้ายร่างกายและวาจาจากผู้โดยสาร',
    driver_safety_and_accident2_question: 'การล่วงละเมิดทางเพศ',
    driver_safety_and_accident3_question: 'รถเสีย บำรุงรักษา ',
    driver_safety_and_accident4_question: 'มาตรการป้องกันโควิด-19',
    rider_safety_and_accident0_question: 'คนขับของฉันประสบอุบัติเหตุ',
    rider_safety_and_accident1_question: 'การล่วงละเมิดทางร่างกาย/วาจา',
    rider_safety_and_accident2_question: 'การล่วงละเมิดทางเพศ',
    rider_safety_and_accident3_question: 'ข้อปฏิบัติในการขับขี่ที่ไม่ปลอดภัย',
    rider_safety_and_accident4_question: 'มาตรการป้องกันโควิด-19',
    safety_and_accident_title: 'ความปลอดภัยและอุบัติเหตุ',
    driver_safety: 'ความปลอดภัยของผู้ขับขี่',
    rider_safety: 'ความปลอดภัยของผู้โดยสาร',
    safety: 'ความปลอดภัย',
    rider_cancellation_table_content:
      'คนขับและผู้โดยสารควรช่วยกันสร้างบรรยากาศที่ดีระหว่างการใช้งาน ดังนั้นจึงมีการนำนโยบายการยกเลิกงานไปใช้กับพนักงานขับรถของเราเช่นกัน เพื่อให้พวกเขามีความรับผิดชอบมากขึ้นในการปฏิบัติตามการจองรถ หลังจากที่พวกเขาได้กดรับงานแล้ว',
    th_driver_safety_and_accident0_question: 'ฉันเกิดอุบัติเหตุขณะโดยสาร',
    th_driver_safety_and_accident1_question: 'การทำร้ายทางคำพูดและทางกายของผู้โดยสาร',
    th_driver_safety_and_accident2_question: 'การล่วงละเมิดทางเพศ',
    th_driver_safety_and_accident3_question: 'รถเสีย, การดูแลรักษารถ',
    th_driver_safety_and_accident4_question: 'มาตรการป้องกันโควิด-19',
    th_rider_safety_and_accident0_question: 'คนขับของฉันเกิดอุบัติเหตุ',
    th_rider_safety_and_accident1_question: 'การทำร้ายร่างกาย / คำดูหมิ่นทางคำพูด',
    th_rider_safety_and_accident2_question: 'การล่วงละเมิดทางเพศ',
    th_rider_safety_and_accident3_question: 'การขับรถที่ไม่ปลอดภัย',
    th_rider_safety_and_accident4_question: 'มาตรการป้องกันโควิด-19',
    title_otp_request: 'เพื่อดำเนินการต่อ โปรดเข้าสู่ระบบบัญชีของคุณเพื่อทำการยืนยัน',
    label_mobile_number: 'เบอร์โทรศัพท์มือถือ',
    label_code_number: 'เลขรหัส',
    btn_send: 'ส่ง',
    btn_continue: 'ดำเนินการต่อ',
    error_verification_code: 'รหัสยืนยันไม่ตรงกัน',
    label_account_deletion: 'ลบบัญชีของคุณ',
    label_reason_delete_account: 'เหตุผลในการลบบัญชี',
    hint_min_character: 'อย่างน้อย {{count}} ตัวอักษร',
    btn_submit: 'ส่ง',
    dialog_account_deletion_desc: 'หลังจากนี้คุณจะไม่สามารถใช้บริการ TADA ได้',
    apply_account_deletion_title: 'เรากำลังดำเนินการลบบัญชีของคุณอยู่',
    apply_account_deletion_desc: 'การลบบัญชีอาจใช้เวลาหลายวันในการดำเนินการ',
    dialog_account_deletion_failure_title: 'ไม่สามารถลบบัญชีของคุณได้',
    content_delete_account_error_balance_remaining:
      'การลบบัญชี ยอดคงเหลือในเครดิตต้องเป็น 0 กรุณาลองอีกครั้งหลังจากถอนเงินที่เหลือหรือส่งอีเมลไปยังศูนย์บริการลูกค้าเพื่อขอความช่วยเหลือ',
    content_delete_account_error_exist_rider_penalty_fee:
      'เราไม่สามารถดำเนินการลบบัญชีของคุณได้เนื่องจากมีการลงโทษในบัญชีของคุณ กรุณาติดต่อทีมบริการลูกค้าของเราทางอีเมล และทีมของเราจะช่วยดำเนินการในกระบวนการลบบัญชีของคุณ',
    content_delete_account_error_exist_active_ride:
      'คุณมีการเดินทางที่กำลังดำเนินการอยู่ในขณะนี้ กระบวนการลบบัญชีของคุณจะสามารถดำเนินการได้เมื่อทำการเดินทางปัจจุบันเสร็จสิ้นหรือยกเลิก',
    label_cs_center: 'ศูนย์บริการลูกค้า',
    label_notice: 'แจ้งเตือน',
    label_get_rewarded: 'ได้รับรางวัล',
    label_for_me: 'สำหรับฉัน',
    label_for_my_friend: 'สำหรับเพื่อนฉัน',
    label_invite_rider: 'เชิญชวนผู้โดยสารและรับรางวัล',
    text_contact_description:
      'สำนักงานของเราให้บริการในเอเชีย-แปซิฟิก ยินดีให้บริการคุณทุกที่ ไม่ว่าจะที่สำนักงานสิงคโปร์ กัมพูชา ไทย เวียดนาม หรือฮ่องกง คุณสามารถติดต่อเราได้จากสถานที่เหล่านี้',
    text_contact_empty_description: 'โปรดคลิกที่จุดแสดงสำนักงานของเราบนแผนที่ เพื่อดูรายละเอียด',
    report: {
      label_report_issue: 'รายงานปัญหา',
      title_report_issue: 'ปัญหาที่คุณพบได้รับการแก้ไขแล้วหรือยัง?',
      content_report_issue: 'ต้องการความช่วยเหลือเพิ่มเติม กดที่ "รายงานปัญหา" เพื่อแจ้งข้อมูลเพิ่มเติม',
      label_report_an_issue: 'รายงานปัญหา',
      label_describe_issue: 'รายละเอียดปัญหา',
      label_minimum_character: 'ขั้นต่ำ {{minLength}}  ตัวอักษร',
      title_cancel_modal: 'ยกเลิกรายงาน',
      content_cancel_modal: "รายงานจะ 'ไม่ถูกบันทึก' ต้องการออกจากหน้านี้หรือไม่?",
      button_cancel_no: 'รายงานต่อ',
      title_submit_modal: 'ขอบคุณสำหรับฟีดแบ็กที่ส่งหาเรา',
      content_submit_modal: 'อาจมีเจ้าหน้าที่ติดต่อหาคุณ เพื่อขอหรือแจ้งข้อมูลเพิ่มเติม',
      button_ok: 'ยืนยัน',
    },
    car_type_economy: 'Economy',
    car_type_economy_large: 'Economy Large',
    car_type_premium: 'Premium',
    car_type_premium_large: 'Premium Large',
    car_type_taxi: 'Standard Taxi',
    car_type_taxi_large: 'Standard Large Taxi',
    car_type_premium_taxi: 'Premium Taxi',
    car_type_premium_taxi_large: 'Premium Large Taxi',
    car_type_tuktuk: 'Tuk Tuk',
    car_type_bike: 'Bike',
    car_type_remorque: 'Remorque',
    car_type_delivery_van: 'Delivery Van',
    car_type_onion_tuktuk: 'E-Tuk Tuk',
    car_type_delivery_bike: 'Delivery Bike',
    product_type_ph: 'Private Hire',
    product_type_taxi: 'taxi',
    product_type_anytada: 'AnyTADA',
    product_type_ev: 'iONTADA',
    product_seat_count: 'สูงสุด {{seat}} ที่นั่ง',
    economy_cm: 'Car',
    economy_large_cm: 'SUV',
    car_type_bus: 'Bus',
    car_type_mini_bus: 'Mini Bus',
    ride_option_pet_carrier: 'TADA Pets (กรง)',
    ride_option_pet_leashed: 'TADA Pets (สายจูง)',
    fare_type_app_metered_fare: 'ตามแอปฯ มิเตอร์',
    fare_type_car_metered_fare: 'ตามมิเตอร์ ',
    fare_type_fixed_fare: 'ราคาคงที่',
    car_type_win_bike: 'Win Bike',
    car_type_premium_bike: 'TADA Bike Plus',
    car_junior_type: 'TADA Junior',
    car_junior_large_type: 'TADA Junior Large',
    car_wheelchair_space_type: 'Wheelchair Space',
    car_type_tada_bike: 'TADA Bike',
    text_live_ride_expired_content: 'ลิงก์การแชร์ตำแหน่งที่อยู่ปัจจุบันหมดอายุ',
    text_live_ride_expired_sub_content: 'กรุณาตรวจสอบกับผู้โดยสารว่าลิงก์ถูกต้องหรือไม่',
    text_try_tada: 'ยังไม่ได้ลองใช้ TADA ใช่หรือไม่?',
    text_book_first_trip: 'เริ่มการเดินทางแรกของคุณที่นี่!',
    text_download_tada_ride: 'เดินทางกับ TADA, ดาวน์โหลดเดี๋ยวนี้!',
    label_install: 'ติดตั้ง',
    text_message_pickup_waiting: '{{riderName}} กำลังรอการมารับ',
    text_message_pickup_arrived: 'คนขับถึงที่จุดรับแล้ว',
    text_message_inuse: '{{riderName}} จะมาถึงใน ~{{time}} นาที',
    text_message_finished: '{{riderName}} ถึงจุดหมาย ณ. {{time}}!',
    text_message_cancelled: 'การเดินทางถูกยกเลิกแล้ว',
    text_message_na_inuse: '{{riderName}} กำลังอยู่ระหว่างการเดินทาง',
    text_message_na_finished: '{{riderName}} ได้ถึงแล้ว',
    label_na: 'ไม่มีข้อมูล',
    text_message_cod: '{{riderName}} ได้เปลี่ยนปลายทางและจะมาถึงใน ~{{time}} นาที',
  },
};
