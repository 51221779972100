import { createAction, createSlice } from '@reduxjs/toolkit';

const PREFIX = 'deleteAccount';

export const requestOTPSuccess = createAction(`${PREFIX}/requestOTPSuccess`);
export const requestOTPFailure = createAction(`${PREFIX}/requestOTPFailure`);

export const validateOTPRequest = createAction(`${PREFIX}/validateOTPRequest`);
export const validateOTPSuccess = createAction(`${PREFIX}/validateOTPSuccess`);
export const validateOTPFailure = createAction(`${PREFIX}/validateOTPFailure`);

export const checkExistsRequest = createAction(`${PREFIX}/checkExistsRequest`);
export const checkExistsSuccess = createAction(`${PREFIX}/checkExistsSuccess`);
export const checkExistsFailure = createAction(`${PREFIX}/checkExistsFailure`);

export const getUserInfoRequest = createAction(`${PREFIX}/getUserInfoRequest`);
export const getUserInfoFailure = createAction(`${PREFIX}/getUserInfoFailure`);

export const checkAvailableDeleteRequest = createAction(`${PREFIX}/checkAvailableDeleteRequest`);
export const checkAvailableDeleteSuccess = createAction(`${PREFIX}/checkAvailableDeleteSuccess`);

export const deleteAccountRequest = createAction(`${PREFIX}/deleteAccountRequest`);
export const deleteAccountSuccess = createAction(`${PREFIX}/deleteAccountSuccess`);
export const deleteAccountFailure = createAction(`${PREFIX}/deleteAccountFailure`);

export const deleteAccountInitialState = {
  phone: null,
  token: null,
  uuid: null,
  errorCode: null,
};

const deleteAccountSlice = createSlice({
  name: PREFIX,
  initialState: deleteAccountInitialState,
  reducers: {
    requestOTPRequest: {
      prepare: (payload) => ({ payload }),
      reducer: (state, { payload }) => {
        state.phone = payload.phone;
      },
    },
    getUserInfoSuccess: (state, { payload }) => {
      state.token = payload.token;
      state.uuid = payload.uuid;
    },
    checkAvailableDeleteFailure: (state, { payload }) => {
      state.errorCode = payload.error;
    },
  },
});

export const { requestOTPRequest, getUserInfoSuccess, checkAvailableDeleteFailure } = deleteAccountSlice.actions;

export default deleteAccountSlice.reducer;
