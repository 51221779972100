import React, { Component } from 'react'
import styled from 'styled-components'
import Button from '../../Common/Button'
import Slider from 'react-slick/lib/index'
import iPhoneX from '../../../assets/images/tada/i-phone-x.png'
import { compose } from 'recompose'

import revampDriverRideMatch from '../../../assets/images/tada/revamp/driver/web_tada_driver_ride_matching.png'
import revampDriverPickup from '../../../assets/images/tada/revamp/driver/web_tada_driver_pick_up.png'
import revampDriverDrive from '../../../assets/images/tada/revamp/driver/web_tada_driver_drive.png'
import revampDriverDropoffAndPay from '../../../assets/images/tada/revamp/driver/web_tada_driver_drop_off_and_pay.png'

import revampRiderRideRequest from '../../../assets/images/tada/revamp/rider/web_tada_rider_ride_request.png'
import revampRiderDriverMatch from '../../../assets/images/tada/revamp/rider/web_tada_rider_driver_matching.png'
import revampRiderRiding from '../../../assets/images/tada/revamp/rider/web_tada_rider_riding.png'
import revampRiderPayAndFeedback from '../../../assets/images/tada/revamp/rider/web_tada_rider_pay_and_feedback.png'

const appScreenshots = {
  driver: [revampDriverRideMatch, revampDriverPickup, revampDriverDrive, revampDriverDropoffAndPay],
  rider: [revampRiderRideRequest, revampRiderDriverMatch, revampRiderRiding, revampRiderPayAndFeedback]
}

export const AppCarouselSliderWrapper = styled.div`
  width: 437px;
  position: relative;

  &:after,
  &::after {
    z-index: 10;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 5px;
    right: 0;
    width: 100%;
    height: 100%;
    background-size: contain;
    background-image: url(${iPhoneX});
    background-repeat: no-repeat;
    background-position: center bottom;
    pointer-events: none;
    content: ' ';
  }

  @media (min-width: 1001px) {
    padding-top: 200px;
  }

  @media (max-width: 1000px) {
    width: 375px;
    height: 625px;
    align-self: center;

    &:after,
    &::after {
      background-size: 275px;
      background-position: center top;
    }
  }

  @media (max-width: 390px) {
    width: 320px;
    height: 525px;

    &:after,
    &::after {
      left: 5px;
      background-size: 240px;
    }
  }
`

export const AppCarouselSlider = styled(Slider)`
  width: 351px;

  @media (max-width: 1000px) {
    width: 223px;
  }

  @media (max-width: 390px) {
    width: 193px;
  }

  &.app-carousel .slick-list {
    margin: 0;
    left: 38px;
    bottom: 45px;

    @media (max-width: 1000px) {
      top: 20px;
      left: 75px;
      bottom: 0;
    }
    @media (max-width: 390px) {
      left: 63px;
    }
  }
`

export const AppCarouselControlBtn = styled(Button)`
  font-size: 14px;
  font-weight: 500;

  @media (max-width: 1000px) {
    font-size: 10px;
  }
`

export const AppCarouselController = styled.ul`
  width: 390px;
  justify-content: space-between;
  margin: 0 13px;
  padding: 0;
  display: flex;
  border-bottom: solid 1px #0a3142;
  position: absolute;

  @media (min-width: 1001px) {
    top: -200px;
  }

  @media (max-width: 1000px) {
    width: 339px;
    bottom: -100px;
  }

  & > li {
    display: inline-flex;

    & > ${AppCarouselControlBtn} {
      color: #fff;
      height: 60px;
      border-bottom: solid 5px transparent;
      // line-height: 60px;

      // @media (max-width: 1000px) {
      //   height: auto;
      // }
    }

    &.slick-active > ${AppCarouselControlBtn} {
      color: #0a3142;
      border-bottom: solid 5px #0a3142;
    }
  }

  @media (max-width: 390px) {
    width: 300px;
  }
`

export const AppCarouselContentWrapper = styled.div`
  box-sizing: border-box;
  width: 351px;
  height: 761px;
  content: ' ';

  @media (max-width: 1000px) {
    width: 223px;
    height: auto;
  }

  @media (max-width: 390px) {
    width: 193px;
  }
`

export const AppCarouselContent = styled.img`
  width: 351px;

  @media (max-width: 1000px) {
    margin: 0 auto;
    display: block;
    width: 223px;
  }

  @media (max-width: 390px) {
    width: 193px;
  }
`

export class AppCarouselCore extends Component {
  shouldComponentUpdate(nextProps) {
    return this.props.selectedLocale !== nextProps.selectedLocale
  }

  render() {
    const { t, type } = this.props
    const setting = {
      dots: true,
      arrows: false,
      infinite: true,
      autoplay: true,
      autoplaySpeed: 3000,
      slidesToShow: 1,
      slidesToScroll: 1,
      dotsClass: '',
      className: 'app-carousel',
      appendDots: dots => <AppCarouselController>{dots}</AppCarouselController>,
      customPaging: i => <AppCarouselControlBtn>{t(`tada.${type}.app.name_${i + 1}`)}</AppCarouselControlBtn>,
      beforeChange: (current, next) => this.props.setIndex(next)
    }

    return (
      <AppCarouselSliderWrapper>
        <AppCarouselSlider {...setting}>
          {[0, 1, 2, 3].map(index => (
            <AppCarouselContentWrapper key={index}>
              <AppCarouselContent src={appScreenshots[type][index]} />
            </AppCarouselContentWrapper>
          ))}
        </AppCarouselSlider>
      </AppCarouselSliderWrapper>
    )
  }
}

// const mapStateToProps = state => ({
//   selectedLocale: getLocale(state),
// })

const enhance = compose()
// connect(mapStateToProps),
// withTranslation(),

export default enhance(AppCarouselCore)
