// @ts-ignore
import firebase from 'firebase/app';
import 'firebase/analytics';
import 'firebase/messaging';
import 'firebase/database';
import 'firebase/auth';

export enum WEBTOOL_TYPE {
  TADA = 'TADA',
}

export enum HOSTNAME {
  TADA_DEV = 'tada-dev.com',
  TADA_STAGING = 'tada-staging.com',
  TADA_PROD = 'tada.global',
}

export const firebaseInitialize = (config: {
  apiKey: string;
  authDomain: string;
  databaseURL: string;
  projectId: string;
  storageBucket: string;
  messagingSenderId: string;
  appId: string;
  measurementId: string;
}) => {
  firebase.initializeApp(config);
};

export const firebaseConfig = async () => {
  const messaging = firebase.messaging();

  const registration: any = await navigator.serviceWorker.getRegistration('/firebase-cloud-messaging-push-scope');

  messaging.requestPermission().catch((err) => {
    console.error(`fcm err ${err}`);
  });

  messaging.onMessage((payload: any) => {
    const notificationTitle = payload.notification.title;
    const notificationOptions = {
      body: payload.notification.body,
      icon: payload.notification.icon,
      image: payload.notification.image,
      click_action: payload.notification.click_action,
      data: payload.notification.click_action,
    };

    registration.showNotification(notificationTitle, notificationOptions);
  });
};

export const setAnalyticsUserId = (id: string) => {
  if (firebase.apps.length) {
    const analytics = firebase.analytics();

    analytics.setUserId(id);
  }
};

export const getFirebaseId = async () => {
  if (firebase.apps.length) {
    const firebaseApp = firebase.app();

    const installationId = await firebaseApp.installations().getId();

    return installationId;
  }

  return null;
};

export const getDatabase = () => firebase.database();

export const getAuth = () => firebase.auth();

export const signInAnonymously = async () => {
  const auth = getAuth();

  await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);

  const credential = await auth.signInAnonymously();

  return credential;
};

export default firebaseConfig;
