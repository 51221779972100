import React, { Component, Fragment } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { withTranslation } from 'react-i18next'
import autoBind from 'react-autobind'
import styled from 'styled-components'
import { compose } from 'recompose'

import { media } from '../../Common/ByScreen'
import MvlBtn from '../../Common/MvlBtn'
import MvlLink from '../../Common/MvlLink'
import { connect } from 'react-redux'
import actions from '../../../actions'
import { getLocale } from '../../../selectors'
import {  mvlWordBreakSetting } from '../../../utils/MyUtils'

const MvlModalBox = styled(Modal)`
  word-break: ${props => mvlWordBreakSetting(props)};
`

const MvlModalHeader = styled(Modal.Header)`
  border-bottom: 0 !important;
  font-size: 35px;
  font-weight: 300;
  line-height: 1.97 !important;
  color: #082e3d;
  padding: 45px 45px 14px !important;
  ${media.lessThan('lg')`
    font-size: 20px;
  `};
`

const MvlModalBody = styled(Modal.Body)`
  padding: 14px 45px 45px !important;
  color: #082e3d;
`

const MvlModalBtn = styled(MvlBtn)`
  min-width: 91px;
`

const ResendButton = styled(MvlLink)`
  margin-bottom: 50px;
  background: transparent;
  border: 0;
  padding: 0;
  ${props =>
    props.loading
      ? `
    color: #d5d5d5;
    cursor: default;
    &:hover {
      text-decoration: none;
    }
  `
      : `
    color: #f8bc3d;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  `};
`


class EmailVerifyModal extends Component {
  constructor (props) {
    super(props)

    autoBind(this)
  }

  state = {
    resendLoading: false,
    isSuccessResend: false
  }

  requestResendVerifyEmail (e) {
    if (e) {
      e.preventDefault()
      e.stopPropagation()
    }
    const { resendLoading, isSuccessResend } = this.state
    if (resendLoading || isSuccessResend) return

    this.setState({ resendLoading: true })
    this.props
      .resendVerifyEmailNoAuthRequest(this.props.email)
      .then(() => {
        this.setState({
          resendLoading: false,
          isSuccessResend: true
        })
      })
      .catch(() => this.setState({ resendLoading: false }))
  }

  render () {
    const { t, confirmAction, isOpen, closeModal, email, selectedLocale } = this.props
    const { resendLoading, isSuccessResend } = this.state

    return (
      <Fragment>
        <MvlModalBox show={isOpen} onHide={closeModal} selectedLocale={selectedLocale}>
          <MvlModalHeader>
            <Modal.Title>{t('verify_mail_title')}</Modal.Title>
          </MvlModalHeader>
          <MvlModalBody>
            <div style={{ marginBottom: '20px' }}>{t('verify_mail_desc', { email: email || '' })}</div>
            <ResendButton as='div' onClick={this.requestResendVerifyEmail} loading={resendLoading || isSuccessResend}>
              {t(isSuccessResend ? 'success_resend_email_verify' : 'button_resend_email_verify')}
            </ResendButton>
            <div>
              <MvlModalBtn as={Button} inverse='true' onClick={e => (confirmAction ? confirmAction(e) : closeModal(e))}>
                {t('button_confirm')}
              </MvlModalBtn>
            </div>
          </MvlModalBody>
        </MvlModalBox>
      </Fragment>
    )
  }
}

const mapStateToProps = (state) => ({
  selectedLocale: getLocale(state)
})

const mapDispatchToProps = actions

const enhance = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  withTranslation()
)

export default enhance(EmailVerifyModal)
